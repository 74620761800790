import { Injectable } from '@angular/core';
import {
  pluck, take,
} from 'rxjs/operators';
import { EXTERNAL_APIS } from '../constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import { HTTP } from '@ionic-native/http/ngx';
import { NetworkService } from './network.service';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { Platform } from '@ionic/angular';
import { getJwtToken } from '../store/userPreferences';


@Injectable({
  providedIn: 'root'
})
export class CryptoTransferService {

  private url = EXTERNAL_APIS.MEMBERS_PORTAL;
  private getPriceQuoteUri: string = 'token/lode/getpricequote';
  private confirmQuoteUri: string = 'token/confirmquote';
  private confirmPaymentUri: string = 'token/confirmPayment';
  private submitTransactionIdUri: string = 'paymentTxId';

  constructor(private http: HttpClient,
              private storage: StorageService,
              public httpPlugin: HTTP,
              private platform: Platform,
              private store: Store<AppState>,
              private networkService: NetworkService) { }


  public async getPriceQuoteTransaction(amount: string, cryptocurrency: string, promoCode: string = null, readOnly: string = '1', fiatcurrency: string = 'usd') {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('currency', cryptocurrency);
    httpParams = httpParams.append('readonly', readOnly);
    if(promoCode)
    {
      httpParams = httpParams.append('promocode', promoCode);
    }
    httpParams = httpParams.append('paymentType', 'crypto');
    httpParams = httpParams.append('qty', amount);
    httpParams = httpParams.append('skip_auto_order', '1');
    httpParams = httpParams.append('fiatCurrency', fiatcurrency);

    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const options = { params: httpParams, headers: headers };
    return this.http.get(`${this.url}/${this.getPriceQuoteUri}`, options ).toPromise();
  }

  public async confirmTransaction(quoteId: string) {
    let formData = new FormData();
    formData.append('quote_id', quoteId);
    formData.append('paymentType', 'crypto');
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
    });
    return this.http.post(`${this.url}/${this.confirmQuoteUri}`, formData,  {headers}).toPromise();
  }
  public async confirmPayment(orderId: string) {
    let formData = new FormData();
    formData.append('order_id', orderId);
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
    });
    return this.http.post(`${this.url}/${this.confirmPaymentUri}`, formData,  {headers}).toPromise();
  }

  public async submitTransactionId(quoteId: string, transactionId: string) {
    let formData = new FormData();
    formData.append('quoteId', quoteId);
    formData.append('txId', transactionId);
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    return this.http.post(`${this.url}/${this.submitTransactionIdUri}`, formData,  {headers}).toPromise();
  }
}
