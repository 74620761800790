import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { USER_PREFERENCES_STORAGE_KEY } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class NetworkService {
  constructor(private storage: StorageService) {
  }

  static get defaultNetworks() {
    return {
      sys: {
        network: environment.production ? 'sys-main' : 'sys-test'
      },
      eth: {
        network: environment.production ? 'eth-main' : 'eth-rinkeby'
      },
      btc: {
        network: environment.production ? 'btc-main' : 'btc-test'
      },
      avax: {
        network: environment.production ? 'avax-main' : 'avax-test'
      },
      sol: {
        network: environment.production ? 'sol-main' : 'sol-test'
      },
    }
  }

  getNetworks() {
    return environment.EXTERNAL_APIS.EXPLORERS;
  }

  async getNetworkIdByChain(chain: 'sys' | 'eth' | 'btc' | 'avax' | 'sol') {
    await this.checkNetworkStorageFormat();
    const defaultNetworks = NetworkService.defaultNetworks;
    
    try {
      const networks = environment.features.networkSwitcher ? JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY)) : defaultNetworks;
      return networks ? networks[chain].network : defaultNetworks[chain].network;
      
    } catch {
      return defaultNetworks[chain].network;
    }
  }

  async getNetworkById(id: string) {
    return this.getNetworks().find(network => network.id === id);
  }

  async getActiveSysNetwork() {
    await this.checkNetworkStorageFormat();
    const networkId: string = await this.getNetworkIdByChain('sys');

    return this.getNetworks().find(network => network.id === networkId);
  }
  
  async getActiveSolNetwork() {
    await this.checkNetworkStorageFormat();
    const networkId: string = await this.getNetworkIdByChain('sol');
    return this.getNetworks().find(network => network.id === networkId);
  }

  async getActiveEthNetwork() {
    await this.checkNetworkStorageFormat();
    const networkId: string = await this.getNetworkIdByChain('eth');

    return this.getNetworks().find(network => network.id === networkId);
  }
  
  async getActiveAvaxNetwork() {
    await this.checkNetworkStorageFormat();
    const networkId: string = await this.getNetworkIdByChain('avax');

    return this.getNetworks().find(network => network.id === networkId);
  }

  async getActiveBtcNetwork() {
    await this.checkNetworkStorageFormat();
    const networkId: string = await this.getNetworkIdByChain('btc');

    return this.getNetworks().find(network => network.id === networkId);
  }

  async checkNetworkStorageFormat() {
    const prefs = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY) || '{}');

    if (prefs.sys && typeof prefs.sys.network === 'number') {
      prefs.sys = NetworkService.defaultNetworks.sys;
      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }

    if (prefs.eth && typeof prefs.eth.network === 'number') {
      prefs.eth = NetworkService.defaultNetworks.eth;
      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }
    
    if (prefs.avax && typeof prefs.avax.network === 'number') {
      prefs.avax = NetworkService.defaultNetworks.avax;
      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }
    
    if (prefs.sol && typeof prefs.sol.network === 'number') {
      prefs.sol = NetworkService.defaultNetworks.sol;
      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }
  }

}
