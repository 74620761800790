export const environment = {
  production: true,
  ethNetwork: 'eth-main',
  sysNetwork: 'sys-main',
  btcNetwork: 'btc-main',
  avaxNetwork: 'avax-main',
  solNetwork: 'sol-main',
  ga: 'G-SE9B203FC6',
  sentryDsn: 'https://f62216607294489bafd60a33869de002@o458443.ingest.sentry.io/5834720',
  features: {
    agx: true,
    aux: true,
    epin: true,
    bauth: true,
    linkLode: true,
    eth: true,
    avax: true,
    sol: true,
    hl: true,
    gasStation: true,
    debitCard: false,
    dust: true,
    multicoin: false,
    kycCheck: false,
    versionCheck: true,
    networkSwitcher: false,
    componentSheet: false,
    btc: true,
    bankAccounts: true,
    aggregateTokens: true,
    customGasTextBox: false,
    sentry: true
  },
  version_releases: {
    playstore_link: 'https://play.google.com/store/apps/details?id=one.lode.lodepay&hl=en_US',
    appstore_link: 'https://apps.apple.com/ca/app/agxpay-mobile/id1483976654'
  },
  USER_PREFERENCES_STORAGE_KEY: 'wallet_userprefs',
  AGX_GUID: '367794646',
  AGX_KEYRING_ID: 'SYS-367794646',
  AUX_GUID: '1358717298',
  AUX_KEYRING_ID: 'SYS-1358717298',
  EMAIL_CAPTURE_ATTEMPTS: 5,
  SOLANA_HISTORY_LIMIT: 7,
  PLAID_SERVICE_REDIRECT_IOS: 'ionic://localhost/wallet/prime-trust/USD',
  PLAID_SERVICE_REDIRECT_ANDROID: 'http://localhost/wallet/prime-trust/USD',
  PLAID_SERVICE_REDIRECT_BROWSER: 'https://wallet.lodepay.one/wallet/prime-trust/USD',
  EXTERNAL_APIS: {
    PREVIOUS_SYSNET_BB_URL: 'https://sys-41.lode.one/address/',
    MEMBERS_PORTAL: 'https://members.lode.one/api',
    MEMBERS_PORTAL_BANK_API: 'https://api.lode.one/exchange',
    COMPLIANCE: 'https://api.lode.one/compliance',
    GAS_STATION: 'https://gas-station.lode.one',
    VERSION_URL: 'https://s3-us-west-2.amazonaws.com/dl.lode.one/version/agxpay_version.json',
    VIRTUAL_DEBIT_CARD: 'https://virtual-cards.lode.one',
    MEMBER_PORTAL_HOME: 'https://members.lode.one',
    STATUS_CHECK_API: 'https://status.lode.one',
    EXPLORERS: [
      {
        id: 'sys-main',
        chain: 'SYS',
        network: 'mainnet',
        name: 'Syscoin mainnet',
        URL: 'https://sys.lode.one',
        TOKEN_DEFINITIONS: 'https://raw.githubusercontent.com/blockchainfoundryinc/spt-descriptions/master/spt.json',
        SOCKET_URL: 'https://sys.lode.one',
        SPT_SOCKET_URL: 'https://sys-ws.lode.one'
      }, {
        id: 'eth-main',
        chain: 'ETH',
        chainId: 1,
        network: 'mainnet',
        name: 'Ethereum mainnet',
        URL: 'https://ethereum.lode.one',
        SOCKET_URL: 'https://ethereum.lode.one',
        GAS_PRICE: 'https://www.etherchain.org/api/gasPriceOracle'
      }, {
        id: 'btc-main',
        chain: 'BTC',
        network: 'mainnet',
        name: 'Bitcoin mainnet',
        URL: 'https://btc1.bcfn.ca',
        SOCKET_URL: 'https://btc1.bcfn.ca'
      },
      {
        id: 'btc-test',
        chain: 'BTC',
        network: 'testnet',
        name: 'Bitcoin testnet',
        URL: 'https://btc1-testnet.bcfn.ca',
        SOCKET_URL: 'https://btc1-testnet.bcfn.ca'
      },
      {
        id: 'avax-main',
        chain: 'AVAX',
        chainId: 43114,
        network: 'mainnet',
        name: 'Avalanche mainnet',
        URL: 'https://api.avax.network',
        API_URL: 'https://api.snowtrace.io',
        API_EXPLORER: 'https://snowtrace.io',
        SOCKET_URL: 'https://api.lode.one/web3-socket',
        GAS_PRICE: 'https://api.debank.com/chain/gas_price_dict_v2?chain=avax',
        GSD_ORACLE_ADDRESS: '0x282e5703B4F5B1112CE672DAc11FF2EF0aBd235d'
      }, {
        id: 'avax-test',
        chain: 'AVAX',
        chainId: 43113,
        network: 'testnet',
        shortName: 'Fuji',
        name: 'AVAX Fuji testnet',
        URL: 'https://api.avax-test.network',
        API_URL: 'https://api-testnet.snowtrace.io',
        API_EXPLORER: 'https://testnet.snowtrace.io',
        SOCKET_URL: 'https://api.lode.tech/web3-socket',
        // SOCKET_URL: 'http://localhost:3000/',
        TOKEN_MAP: {
          '0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8': '0x648860804E3f2778b28B5760ED1D5d1afdA2C2aC',
          '0x70616a337433a9207fb5c43067c6785aba671c0b': '0x37F52e650ac21354Aca33A21366986CDf03a3670',
          '0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1': '0x9cCed5a8994DaeFc4b65429Fa92f84eDB50Ecc5c',
          '0xe0285fc6a60daa223219fb01d78b3baee133017c': '0xcc871dbbc556ec9c3794152ae30178085b25fa26'
        },
        GAS_PRICE: 'https://api.debank.com/chain/gas_price_dict_v2?chain=avax',
        GSD_ORACLE_ADDRESS: '0x282e5703B4F5B1112CE672DAc11FF2EF0aBd235d'
      },
      {
        id: 'sol-main',
        chain: 'SOL',
        network: 'mainnet',
        name: 'Solana mainnet',
        URL: 'https://api.mainnet-beta.solana.com',
        SOCKET_URL: 'https://solana-socket.lode.one',
        API_EXPLORER: 'https://explorer.solana.com'
      },
      {
        id: 'sol-test',
        chain: 'SOL',
        network: 'testnet',
        name: 'Solana testnet',
        URL: 'https://api.testnet.solana.com',
        SOCKET_URL: 'https://solana-socket.lode.tech',
        API_EXPLORER: 'https://explorer.solana.com',
        TOKEN_MAP: {
          'CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v': 'Czh5EHJXZzHMiUeQwY1ZcJUpaf1yBpetdKswMx6NVAhT',
          'Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L': '3QjcGDn3nJbnt1KZvC3RRNCN3v6gQ14aykk8eCzoLc6X',
          'H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR': 'BKzgKX1xskUMj99rR24z2h775dgF9Eju7H9YmSsFrkDn'
        },
      },
    ]
  },
  ETHERSCAN_API_KEY: 'JBYQNI453EF3W9ARDFCMZUF6ARFB8CWI3I',
  SNOWTRACE_API_KEY: 'UUJH6T79FVT4MPGF2MSP7ZUTDRX3PK1SC8'
};
