import '@angular/compiler'
import { enableProdMode } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.features.sentry) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "https://wallet.lode.tech", "https://wallet.lode.one"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
