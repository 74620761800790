import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { LoadUserPreferencesAction, SetUserPreferencesAction, UserPreferencesActionTypes, SwitchNetworkAction } from '../actions/userPreferences.actions';
import { USER_PREFERENCES_STORAGE_KEY } from '../constants';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GetTokenFiatValuesRequestAction } from '../actions/wallet.actions';
import HdKeyringController from '../lib/pangolin/keyring/hd.keyring.controller';
import { WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants'
import { initWalletWithMnemonic } from '../lib/pangolin/keyring/keychainUtils';
import { hdPaths, initWalletWithMnemonicOpts } from '../lib/pangolin/keyring/index';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';

@Injectable()
export class UserPreferencesEffects {

   setBaseCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(UserPreferencesActionTypes.SET_BASE_GWEI,
      UserPreferencesActionTypes.SET_BASE_GAS,
      UserPreferencesActionTypes.SET_BASE_CURRENCY,
      UserPreferencesActionTypes.SET_USER_DATA,
      UserPreferencesActionTypes.UPDATE_JWT_TOKEN,
      UserPreferencesActionTypes.SWITCH_NETWORK,
      UserPreferencesActionTypes.SET_USER_PREFERENCES),
    withLatestFrom(this.store),
    switchMap(async ([action, storeState]) => {
      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(storeState.userPreferences));

      // only dispatch follow up action if we have an initialized keychain
      if (storeState.wallet.keychain) {
        this.store.dispatch(
          new GetTokenFiatValuesRequestAction({
            symbols: Object.keys(storeState.wallet.keychain)
          }));
      }
    })
  ), { dispatch: false });

   loadUserPreferences$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserPreferencesAction>(UserPreferencesActionTypes.LOAD_USER_PREFERENCES),
    switchMap(action => from(this.storage.get(USER_PREFERENCES_STORAGE_KEY))),
    switchMap(preferences => {
      const prefs = JSON.parse(preferences);

      return of(new SetUserPreferencesAction({preferences: prefs}))
    })
  ));

   updateHdPath$ = createEffect(() => this.actions$.pipe(
    ofType<SwitchNetworkAction>(UserPreferencesActionTypes.SWITCH_NETWORK),
    switchMap(async (action) => {
      const pin = action.payload.pin;
      const vault = await this.storage.get(WALLET_STORAGE_KEY);
      const decryptedVault: any = HdKeyringController.prototype.decryptVault(pin, vault);
      const decryptedNodes = decryptedVault[0].nodes;
      let sysNode;
      let ethNode;
      let btcNode;
      let avaxNode;
      //TODO:  Check
      // let solNode;

      decryptedNodes.forEach(node => {
        if (node.hdPath === hdPaths.syscoinMainnet || node.hdPath === hdPaths.syscoinTestnet) {
          sysNode = node;
        }
        if (node.hdPath === hdPaths.ethereumMainnet || node.hdPath === hdPaths.ethereumTestnetRopsten) {
          ethNode = node;
        }
        if (node.hdPath === hdPaths.bitcoinMainnet || node.hdPath === hdPaths.bitcoinTestnet) {
          btcNode = node;
        }
        if (node.hdPath === hdPaths.ethereumMainnet || node.hdPath === hdPaths.ethereumTestnetRopsten) {
          avaxNode = node;
        }
        // if (node.hdPath === hdPaths.solanaMainnet || node.hdPath === hdPaths.solanaTestnet) {
        //   solNode = node;
        // }
      })

      const isTestnet: initWalletWithMnemonicOpts = {
        sys: action.payload.chain === 'sys' ?
          action.payload.network === 'testnet' : sysNode.hdPath === hdPaths.syscoinTestnet
      }

      if (environment.features.eth && ethNode) {
        isTestnet.eth = action.payload.chain === 'eth' ?
        action.payload.network === 'testnet' : ethNode.hdPath === hdPaths.ethereumTestnetRopsten
      }

      if (environment.features.btc && btcNode) {
        isTestnet.btc = action.payload.chain === 'btc' ?
        action.payload.network === 'testnet' : btcNode.hdPath === hdPaths.bitcoinTestnet
      }
      
      if (environment.features.avax && avaxNode) {
        isTestnet.avax = action.payload.chain === 'avax' ?
        action.payload.network === 'testnet' : avaxNode.hdPath === hdPaths.ethereumTestnetRopsten
      }
      
      // if (environment.features.sol && solNode) {
      //   isTestnet.sol = action.payload.chain === 'sol' ?
      //   action.payload.network === 'testnet' : solNode.hdPath === hdPaths.solanaTestnet
      // }

      const newKeychain = await initWalletWithMnemonic(pin, decryptedVault[0].mnemonic, isTestnet);
      await this.storage.set(WALLET_STORAGE_KEY, newKeychain.vault);

      window.location.reload();
    })
    ), { dispatch: false });


  constructor(private actions$: Actions,
              private storage: StorageService,
              private store: Store<AppState>,
              private nav: NavController
  ) {
  }

}
