import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import snsWebSdk from '@sumsub/websdk';
import { TranslateService } from '@ngx-translate/core';
import { AppModeService } from '../../angular-wallet-base/services/app-mode.service';
import { ComplianceService } from '../../angular-wallet-base/services/compliance.service';

@Component({
  selector: 'kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss'],
})

export class KycModalComponent implements OnInit {
  public urlAPI;
  public flowName;
  public token;
  public isDesktopMode;

  constructor(private modal: ModalController,
              private translate: TranslateService,
              private appMode: AppModeService,
              private complianceService: ComplianceService
            ) {
              this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  ngOnInit() {
    this.startKYCSDK();
  }

  startKYCSDK() {

    let snsWebSdkInstance = snsWebSdk.init(this.token,
          () => this.getNewAccessToken()
        )
        .withConf({
            lang: this.translate.currentLang,
            // email: applicantEmail,
            // phone: applicantPhone,
            // i18n: customI18nMessages,
            uiConf: {
                // customCss: "https://url.com/styles.css"
                // URL to css file in case you need change it dynamically from the code
                // the similar setting at Applicant flow will rewrite customCss
                // you may also use to pass string with plain styles `customCssStr:`
            }
        })
        .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')

  }
  async getNewAccessToken() {
    const kycToken: string = await this.complianceService.createAccessToken();
    return kycToken;
  }

  async cancelKYC() {
    this.modal.dismiss();
  }
}
