export const arrayContains = (master, sub): boolean => {
  return sub.every((i => v => i = master.indexOf(v, i) + 1)(0));
};

export const arrayStartsWith = (master, find): boolean => {
  let result = true;
  for (let j = 0; j < find.length; j++) {
    if (master[j] !== find[j]) {
      result = false;
      break;
    }
  }
  return result;
};

export const arrayReplace = (master, find, repl) => {
  let lastScanIndex = -1;
  return master.reduce((total, currentValue, currentIndex) => {
    if (currentIndex >= master.length - find.length) {
      total.push(currentValue);
      return total;
    }
    total = Array.isArray(total) ? total : [total];
    if (arrayStartsWith(master.slice(currentIndex), find)) {
      lastScanIndex = currentIndex + find.length;
      total = total.concat(repl);
      return total;
    } else {
      if (currentIndex > lastScanIndex) {
        total.push(currentValue);
      }
      return total;
    }
  }, []);
};

export const arraySplit = (master, find) => {
  let lastScanIndex = -1;
  return master.reduce((total, currentValue, currentIndex) => {
    if (currentIndex >= master.length - find.length) {
      total[total.length - 1].push(currentValue);
      return total;
    }
    total = Array.isArray(total) ? total : [total];
    if (arrayStartsWith(master.slice(currentIndex), find)) {
      lastScanIndex = currentIndex + find.length;
      total.push([]);
      return total;
    } else {
      if (currentIndex >= lastScanIndex) {
        total[total.length - 1].push(currentValue);
      }
      return total;
    }
  }, [[]]);
};

export const arrayConcat = (resultConstructor, ...arrays) => {
  let totalLength = 0;
  for (let arr of arrays) {
    totalLength += arr.length;
  }
  let result = new resultConstructor(totalLength);
  let offset = 0;
  for (let arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
};

export const arrayToString = (array) => {
  let out, i, len, c;
  let char2, char3;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }
  return out;
};