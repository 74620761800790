import { Action } from '@ngrx/store';


export enum DevicesListActionTypes {
  SET_USER_DEVICE_DATA = '[deviceslist] Set user device data',
  SET_USER_DEVICES_DATA = '[deviceslist] Set user devices data',
  REMOVE_USER_DEVICE_DATA = '[deviceslist] Remove user device data',
  GET_USER_DEVICES_REQUEST = '[deviceslist] Get user devices request',
  GET_USER_DEVICES_SUCCESS = '[deviceslist] Get user devices success',
  GET_USER_DEVICES_FAILURE = '[deviceslist] Get user devices failure',
}

export class SetUserDeviceAction implements Action {
  readonly type = DevicesListActionTypes.SET_USER_DEVICE_DATA;
  constructor(readonly payload: { ip: string, agent: number, most_recent_device_date: string }) {}
}

export class SetUserDevicesAction implements Action {
  readonly type = DevicesListActionTypes.SET_USER_DEVICES_DATA;
  constructor(readonly payload: { devices: any[] }) {}
}


export class RemoveUserDeviceAction implements Action {
  readonly type = DevicesListActionTypes.REMOVE_USER_DEVICE_DATA;
  constructor(readonly payload: { device: any }) {}
}

export class GetUserDevicesRequestAction implements Action {
  readonly type = DevicesListActionTypes.GET_USER_DEVICES_REQUEST;
  constructor() {}
}

export class GetUserDevicesSuccessAction implements Action {
  readonly type = DevicesListActionTypes.GET_USER_DEVICES_SUCCESS;
  constructor(readonly payload: {  devices: any[] }) {}
}

export class GetUserDevicesFailureAction implements Action {
  readonly type = DevicesListActionTypes.GET_USER_DEVICES_FAILURE;
  constructor() {}
}
