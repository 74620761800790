import { Action } from '@ngrx/store';

export enum AppSettingsActionTypes {
  CHANGE_TITLE = '[appState] Change page title',
  CHANGE_SECONDARY_TITLE = '[appState] Change page secondary title',
  USE_I18N_ON_TITLE = '[appState] Use i18n on title',
  FORCE_UPGRADE = '[appState] Force Upgrade'
}

export class ChangeTitleAction implements Action {
  readonly type = AppSettingsActionTypes.CHANGE_TITLE;
  constructor(readonly payload: string) {}
}

export class ChangeSecondatyTitleAction implements Action {
  readonly type = AppSettingsActionTypes.CHANGE_SECONDARY_TITLE;
  constructor(readonly payload: string) {}
}

export class UseI18nOnTitleAction implements Action {
  readonly type = AppSettingsActionTypes.USE_I18N_ON_TITLE;
  constructor(readonly payload: boolean) {}
}

export class ForceUpgradeAction implements Action {
  readonly type = AppSettingsActionTypes.FORCE_UPGRADE;
  constructor(readonly payload: boolean) {}
}
