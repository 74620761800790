import { Action } from '@ngrx/store';

export enum GasStationActionTypes {
  SET_GAS_STATION_SEND_DATA = '[gasstation] Set gas station data',
  SEND_GAS_STATION_DATA = '[gasstation] Send gas station data'
}

export class SetSendTokenDataAction implements Action {
  readonly type = GasStationActionTypes.SET_GAS_STATION_SEND_DATA;
  constructor(readonly payload: { address: string, amount: number, assetGuid: number }) {}
}

export class SendGasStationDataAction implements Action {
  readonly type = GasStationActionTypes.SEND_GAS_STATION_DATA;
  constructor(readonly payload: { pin: string }) {}
}
