import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WalletGuardService } from './angular-wallet-base/services/walletguard.service';
import { SettingsGuardService } from './angular-wallet-base/services/settingsguard.service';

const routes: Routes = [
  {
    path: 'dev/component-sheet',
    loadChildren: () => import('./views/component-sheet/component-sheet.module').then(m => m.ComponentSheetPageModule),
  },
  {
    path: 'setup/setup-home',
    loadChildren: () => import('./views/setup/setup-home/setup-home.module').then(m => m.SetupHomeModule)
  },
  {
    path: 'setup/tos',
    loadChildren: () => import('./views/setup/tos/tos.module').then(m => m.TosModule)
  },
  {
    path: 'setup/tos/:type',
    loadChildren: () => import('./views/setup/tos/tos.module').then(m => m.TosModule)
  },
  {
    path: 'setup/setup-pin',
    loadChildren: () => import('./views/setup/setup-pin/setup-pin.module').then(m => m.SetupPinModule)
  },
  {
    path: 'setup/setup-pin/:type',
    loadChildren: () => import('./views/setup/setup-pin/setup-pin.module').then(m => m.SetupPinModule)
  },
  {
    path: 'setup/confirm-pin',
    loadChildren: () => import('./views/setup/confirm-pin/confirm-pin.module').then(m => m.ConfirmPinModule)
  },
  {
    path: 'setup/confirm-pin/:type',
    loadChildren: () => import('./views/setup/confirm-pin/confirm-pin.module').then(m => m.ConfirmPinModule)
  },
  {
    path: 'setup/setup-recovery-phrase',
    loadChildren: () => import('./views/setup/setup-recovery-phrase/setup-recovery-phrase.module').then(m => m.SetupRecoveryPhraseModule)
  },
  {
    path: 'setup/setup-recovery-phrase/:type',
    loadChildren: () => import('./views/setup/setup-recovery-phrase/setup-recovery-phrase.module').then(m => m.SetupRecoveryPhraseModule)
  },
  {
    path: 'setup/enter-recovery-phrase',
    loadChildren: () => import('./views/setup/enter-recovery-phrase/enter-recovery-phrase.module').then(m => m.EnterRecoveryPhraseModule)
  },
  {
    path: 'setup/enter-recovery-phrase/:type',
    loadChildren: () => import('./views/setup/enter-recovery-phrase/enter-recovery-phrase.module').then(m => m.EnterRecoveryPhraseModule)
  },
  {
    path: 'setup/confirm-recovery-phrase',
    loadChildren: () => import('./views/setup/confirm-recovery-phrase/confirm-recovery-phrase.module').then(m => m.ConfirmRecoveryPhraseModule)
  },
  {
    path: 'setup/confirm-recovery-phrase/:type',
    loadChildren: () => import('./views/setup/confirm-recovery-phrase/confirm-recovery-phrase.module').then(m => m.ConfirmRecoveryPhraseModule)
  },
  {
    path: 'setup/setup-capture-email',
    loadChildren: () => import('./views/setup/setup-capture-email/setup-capture-email.module').then(m => m.SetupCaptureEmailModule)
  },
  {
    path: 'setup/setup-capture-email/:type',
    loadChildren: () => import('./views/setup/setup-capture-email/setup-capture-email.module').then(m => m.SetupCaptureEmailModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/authenticate/authenticate.module').then(m => m.AuthenticateModule),
  },
  {
    path: 'auth/:reason',
    loadChildren: () => import('./views/authenticate/authenticate.module').then(m => m.AuthenticateModule),
  },
  {
    path: 'wallet/assets',
    loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/assets/:baseToken',
    loadChildren: () => import('./views/token/token.module').then(m => m.TokenPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/ach_agreement',
    loadChildren: () => import('./views/ach-agreement-page/ach-agreement.module').then(m => m.AchAgreementModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/assets/:baseToken/:guid',
    loadChildren: () => import('./views/token/token.module').then(m => m.TokenPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/prime-trust/:currency',
    loadChildren: () => import('./views/bank-account/bank-account.module').then(m => m.BankAccountModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'pathParamsChange'
  },
  {
    path: 'wallet/transaction-detail',
    loadChildren: () => import('./views/transaction-detail/transaction-detail.module').then(m => m.TransactionDetailPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/card',
    loadChildren: () => import('./views/debit-card/list-cards/debit-card.module').then(m => m.DebitCardPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/card/signup',
    loadChildren: () => import('./views/debit-card/signup-vdc/signup-vdc.module').then(m => m.SignupVdcPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/card/signup-complete',
    loadChildren: () => import('./views/debit-card/submitted-vdc/submitted-vdc.module').then(m => m.SubmittedVdcPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/show-recovery-phrase',
    loadChildren: () => import('./views/show-recovery-phrase/show-recovery-phrase.module').then(m => m.ShowRecoveryPhraseModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/update-password',
    loadChildren: () => import('./views/update-password/update-password.module').then(m => m.UpdatePasswordModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/home',
    loadChildren: () => import('./views/buy-tokens/home/home.module').then(m => m.HomeModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/step-one',
    loadChildren: () => import('./views/buy-tokens/buy-or-redeem/buy-or-redeem.module').then(m => m.BuyOrRedeemModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/step-two',
    loadChildren: () => import('./views/buy-tokens/billing-form/billing-form.module').then(m => m.BillingFormModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/twofa-devices/list',
    loadChildren: () => import('./views/twofa-devices/list-devices/list-devices.module').then(m => m.ListDevicesModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/twofa-devices/delete',
    loadChildren: () => import('./views/twofa-devices/delete-device/delete-device.module').then(m => m.DeleteDeviceModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/step-three/:epin',
    loadChildren: () => import('./views/buy-tokens/claim-epin/claim-epin.module').then(m => m.ClaimEpinModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/step-three',
    loadChildren: () => import('./views/buy-tokens/claim-epin/claim-epin.module').then(m => m.ClaimEpinModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/silver-bullion',
    loadChildren: () => import('./views/buy-tokens/silver-bullion/silver-bullion.module').then(m => m.SilverBullionPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/wire-transfer',
    loadChildren: () => import('./views/buy-tokens/wire-transfer/wire-transfer.module').then(m => m.WireTransferPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/buy-tokens/crypto-payment',
    loadChildren: () => import('./views/buy-tokens/crypto-payment/crypto-payment.module').then(m => m.CryptoPaymentPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'create-account',
    loadChildren: () => import('./views/create-account/create-account.module').then(m => m.CreateAccountPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'link-lode',
    loadChildren: () => import('./views/link-lode/link-lode.module').then(m => m.LinkLodePageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'gas-station',
    loadChildren: () => import('./views/refill-tx/refill-tx.module').then(m => m.RefillTxPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'lode-acc',
    loadChildren: () => import('./views/lode-acc/lode-acc.module').then(m => m.LodeAccPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'wallet/aggregated-token',
    loadChildren: () => import('./views/aggregated-token/aggregated-token.module').then(m => m.AggregatedTokenPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./views/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule,
    TranslateModule]
})
export class AppRoutingModule {
}
