import { TokenDefinition } from '../../../global';
import { AGX_GUID, AGX_KEYRING_ID, AUX_GUID, AUX_KEYRING_ID } from '../../constants';

export const supportedAssetList: { [key: string]: TokenDefinition } = {
  // Key needs to be asset guid of asset
  // Adding the guid of a sys asset will allow the asset page to have a specified image/page title
  // Assets symbol and guid are set in wallet.service.ts... like '{guid}',
  SYS: {
    name: 'Syscoin',
    url: 'http://syscoin.org',
    logo: '../../assets/images/coins/syscoin.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.sys',
    coingeckoId: 'syscoin',
    colorPalette: {
      assetGradientColors: ['#3475BC', '#073B74']
    }
  },
  [AGX_KEYRING_ID]: {
    asset_guid: AGX_GUID,
    asset_publicvalue: 'AGX Coin',
    isOwner: false,
    owner_address: 'sys1qxpx624wwlaqkdx5xdf9u9fskv7jhhh07czzjfx',
    auxFeeAddress: ['tsys1qpvnwxsp7anxu79mt3szpa6v6hu37vahzqvjp05'],
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.agx',
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  [AUX_KEYRING_ID]: {
    asset_guid: AUX_GUID,
    asset_publicvalue: 'AUX Coin',
    isOwner: false,
    owner_address: 'sys1q8dnw8wd9tp3yqzkts2xnp8pg6t5hrve5p86hm5',
    auxFeeAddress: ['tsys1ql3h0e259wvclt74wjzeul8e0gd9pw0gasu8g7m'],
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.aux',
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    }
  },
  // lode is only going to be on HL
  // so it does NOT need guid
  'HL-LODE': {
    owner_address: 'sys1qxpx624wwlaqkdx5xdf9u9fskv7jhhh07czzjfx',
    symbol: 'LODE',
    name: 'Lode Token',
    url: 'lode',
    logo: '../../assets/images/coins/lode_token.png',
    baseChainSymbol: 'HL',
    coinInfo: 'coin_info.lode',
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    }
  },
  // hl-agx is on HL
  // so it does NOT need guid
  'HL-AGX': {
    owner_address: 'sys1qxpx624wwlaqkdx5xdf9u9fskv7jhhh07czzjfx',
    symbol: 'AGX',
    name: 'AGX HL Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    baseChainSymbol: 'HL',
    coinInfo: 'coin_info.agx_hl',
    disabled: true,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  'PT-AGX': {
    owner_address: '',
    symbol: 'AGX',
    name: 'PT AGX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    baseChainSymbol: 'PT',
    coinInfo: 'coin_info.agx_pt',
    disabled: false,
    keyringId: 'PT-AGX',
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  'PT-AUX': {
    owner_address: '',
    symbol: 'AUX',
    name: 'PT AUX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    baseChainSymbol: 'PT',
    coinInfo: 'coin_info.aux_pt',
    disabled: false,
    keyringId: 'PT-AUX',
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    }
  },
  'PT-LODE': {
    owner_address: '',
    symbol: 'LODE',
    name: 'PT LODE Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    baseChainSymbol: 'PT',
    coinInfo: 'coin_info.lode_pt',
    disabled: false,
    keyringId: 'PT-LODE',
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    }
  },
  ETH: {
    symbol: 'ETH',
    name: 'Ethereum',
    url: 'http://ethereum.org',
    logo: '../../assets/images/coins/ethereum.png',
    coinInfo: 'coin_info.eth',
    baseChainSymbol: 'ETH',
    coingeckoId: 'ethereum',
    colorPalette: {
      assetGradientColors: ['#7E8083', '#484B4E']
    }
  },
  'ETH-0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
    asset_guid: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    // testnet_asset_guid: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
    asset_publicvalue: 'USDC Coin',
    isOwner: false,
    owner_address: '',
    symbol: 'USDC',
    name: 'USDC Coin',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin',
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    }
  },
  AVAX: {
    symbol: 'AVAX',
    name: 'Avalanche',
    url: 'https://www.avax.network',
    logo: '../../assets/images/coins/avax_token.png',
    coinInfo: 'coin_info.avax',
    baseChainSymbol: 'AVAX',
    coingeckoId: 'avalanche-2',
    colorPalette: {
      assetGradientColors: ['#E44140', '#C22D37']
    }
  },
  'AVAX-0x70616a337433a9207fb5c43067c6785aba671c0b': {
    asset_guid: '0x70616a337433a9207fb5c43067c6785aba671c0b',
    owner_address: '',
    symbol: 'AUX',
    name: 'AVAX AUX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.aux_avax',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    }
  },
  'AVAX-0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8': {
    asset_guid: '0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8',
    owner_address: '',
    symbol: 'AGX',
    name: 'AVAX AGX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.agx_avax',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  'AVAX-0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1': {
    asset_guid: '0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1',
    owner_address: '',
    symbol: 'LODE',
    name: 'AVAX LODE Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    }
  },
  'AVAX-0xe0285fc6a60daa223219fb01d78b3baee133017c': {
    asset_guid: '0xe0285fc6a60daa223219fb01d78b3baee133017c',
    owner_address: '',
    symbol: 'GSD',
    name: 'Gold Standard DAO Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/gsd_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.gsd_avax',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#6D6D6F', '#c9bb9e']
    },
    precision: 9
  },
  BTC: {
    symbol: 'BTC',
    name: 'Bitcoin',
    url: 'http://ethereum.org',
    logo: '../../assets/images/coins/btc_token.png',
    coinInfo: 'coin_info.btc',
    baseChainSymbol: 'BTC',
    coingeckoId: 'bitcoin',
    colorPalette: {
      assetGradientColors: ['#7E8083', '#484B4E']
    }
  },
  SOL: {
    symbol: 'SOL',
    name: 'Solana',
    url: 'https://solana.com',
    logo: '../../assets/images/coins/sol_token.png',
    coinInfo: 'coin_info.sol',
    baseChainSymbol: 'SOL',
    coingeckoId: 'solana',
    colorPalette: {
      assetGradientColors: ['#628fc4', '#2CC597']
    }
  },
  'SOL-CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v': {
    asset_guid: 'CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v',
    owner_address: '',
    symbol: 'AGX',
    name: 'SOL AGX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    baseChainSymbol: 'SOL',
    coinInfo: 'coin_info.agx_sol',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  'SOL-Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L': {
    asset_guid: 'Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L',
    owner_address: '',
    symbol: 'AUX',
    name: 'SOL AUX Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    baseChainSymbol: 'SOL',
    coinInfo: 'coin_info.aux_sol',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
  'SOL-H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR': {
    asset_guid: 'H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR',
    owner_address: '',
    symbol: 'LODE',
    name: 'SOL LODE Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    baseChainSymbol: 'SOL',
    coinInfo: 'coin_info.lode_sol',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    }
  },
};
