import { Injectable, Provider } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NetworkService } from './network.service';
import { RpcProvider } from './rpc-provider';
import { environment } from 'src/environments/environment';
import { TokenKeyring } from 'src/app/global';
import { BlockbookGetAddressResponse, BlockbookGetAddressTxsResponse, BlockbookTokenInfo } from './blockbook';
import { calculateUnconfBalance, createTokenKeyring } from '../utils';
import BigNumber from 'bignumber.js';
import { createKeyringId } from '../keyringUtils';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { TokenManagerService } from './token-manager.service';
import * as solanaWeb3 from '@solana/web3.js';

export class JsonRpcBody {
  id:string;   
  method: string; 
  params: any[]
}

@Injectable({ providedIn : 'root' })
export class SolanaService {

  constructor(
    protected http: HttpClient,
    private networkService: NetworkService,
    private rpcProvider: RpcProvider,
    private store: Store<AppState>,
    private tokenManager: TokenManagerService
  )  {}
  
  async getSolRpcUrl() {
    const network = await this.networkService.getActiveSolNetwork();
    return `${network.URL}`;
  }
  
  async isSolTestnet() {
    const network = await this.networkService.getActiveSolNetwork();
    return `${network.network === 'testnet'}`;
  }
  
  async getSolTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const url = await this.getSolRpcUrl();
      const data = await this.rpcProvider.rpc(url, 'getBalance', [address]).toPromise();

      const response: BlockbookGetAddressResponse = {};
      response.balance = data['result'].value;
      response.address = address;
      const solKeyring = createTokenKeyring('SOL', address, response['balance'], 'SOL');

      // Add SPL Tokens manually
      response.tokens = [];
      const supportedAssetList = await this.tokenManager.getTokenList();
      const splContractsList =  Object.keys(supportedAssetList).filter((keyringId: string) => keyringId.indexOf('SOL-') !== -1);
      const splBalances = await this.rpcProvider.rpc(url, 'getTokenAccountsByOwner', [address, {"programId":"TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"},{"encoding":"jsonParsed","commitment":"single"}]).toPromise();
      for (const splContract of splContractsList) {
        let contract = splContract.replace('SOL-', '');
        let splObj = {
          balance: 0,
          contract,
          name:  supportedAssetList[splContract].name,
          symbol: supportedAssetList[splContract].symbol,
          type: 'SPL'
        }
        if(splBalances['result']) {
          const arrayOfSpl = splBalances['result']['value'];
          const splToken = arrayOfSpl.filter( (splToken) => splToken['account']['data']['parsed']['info']['mint'] === contract);
          splObj['balance'] = splToken[0]?.account?.data?.parsed?.info?.tokenAmount?.amount || 0;
        }
        response.tokens.push(splObj);
      }
      const keyrings = this.deriveTokenKeyrings(response, 'SOL');
      this.setServiceStatusInStore('solRpc', true);
      return  [ ...keyrings, solKeyring ];
    } catch(err) {
      this.setServiceStatusInStore('solRpc', false);
      return [{}];
    }
  }
  
  async getSolTokenHistory(address, page, itemsPerPage, ethFilterIndex, tokenContract) {

    let response: BlockbookGetAddressTxsResponse = {};
    let txs;
    const url = await this.getSolRpcUrl();
    let transSignatures;
    tokenContract = tokenContract === undefined ? null : tokenContract;
    let options = { limit: itemsPerPage };
    // Only because we need send the latest signature
    if (isNaN(page)) {
      options['before'] = page;
    }
    if (tokenContract !== null) {
      const tokenSPL = await this.rpcProvider.rpc(
        url, 
        'getTokenAccountsByOwner', 
        [address, { mint: `${tokenContract}` },{ encoding: "jsonParsed",}]
      ).toPromise()
      const accountAddress = tokenSPL['result']['value'][0]['pubkey'];
      console.log('account: ', accountAddress)
      transSignatures = await this.rpcProvider.rpc(url, 'getSignaturesForAddress', [accountAddress, options ]).toPromise();
    } else {
      transSignatures = await this.rpcProvider.rpc(url, 'getSignaturesForAddress', [address, options]).toPromise();
    }
    txs = transSignatures['result'];
    const transactions = [];
    for (let i = 0; i < txs.length; i++) {
      const signature = txs[i].signature;
      const confirmedTransaction = await this.rpcProvider.rpc(url, 'getTransaction', [signature, "jsonParsed"]).toPromise();
      if (confirmedTransaction['result'] !== null && tokenContract === null || tokenContract === undefined) {
        let trx = {};
        trx['txid'] = confirmedTransaction['result'].transaction.signatures[0];
        trx['from'] = confirmedTransaction['result'].transaction.message.instructions[0].parsed.info.source;
        trx['confirmations'] = txs[i].confirmationStatus;
        trx['to'] =  confirmedTransaction['result'].transaction.message.instructions[0].parsed.info.destination;
        trx['value'] = confirmedTransaction['result'].transaction.message.instructions[0].parsed.info.lamports;
        trx['fee'] = confirmedTransaction['result'].meta.fee;
        trx['blockTime'] = confirmedTransaction['result'].blockTime;
        transactions.push(trx);
      } else {
        let txTransfer = confirmedTransaction['result'].transaction.message.instructions.filter((tx) => tx.program === 'spl-token');
        if (txTransfer.length > 0) {
          let trx = {};
          trx['txid'] = confirmedTransaction['result'].transaction.signatures[0];
          trx['from'] = txTransfer[0].parsed.info.authority;
          trx['confirmations'] = txs[i].confirmationStatus;
          let toAddress = confirmedTransaction['result'].meta.postTokenBalances.filter((token) => token.owner !== txTransfer[0].parsed.info.authority)
          trx['to'] =  toAddress[0].owner;
          trx['value'] = txTransfer[0].parsed.info.tokenAmount ? txTransfer[0].parsed.info.tokenAmount.amount : txTransfer[0].parsed.info.amount;
          trx['fee'] = confirmedTransaction['result'].meta.fee;
          trx['blockTime'] = confirmedTransaction['result'].blockTime;
          transactions.push(trx);
        }
      }
    }

    response.transactions = transactions;
    response.page = page;
    return { keyringId: createKeyringId('SOL', tokenContract), ...response };
  }
  
  async estimateSolTx(tx) {
    let solFee = 0;
    const url = await this.getSolRpcUrl();
    const resp = await this.rpcProvider.rpc(url, 'getFeeForMessage', [tx]).toPromise()

    if(resp['result']) {
      solFee = resp['result']['value']['feeCalculator']['lamportsPerSignature'];
    }
    return solFee;
  }
  
  async calculateSplTransaction(amount, fromAddress, toAddress,) {
    // Connect to cluster
    const rpcUrl = await this.networkService.getActiveSolNetwork();
    const connection = new solanaWeb3.Connection(rpcUrl.URL, 'confirmed');
    console.log('Sending across cluster:', rpcUrl.URL);
    const toPublicKey = new solanaWeb3.PublicKey(toAddress);
    const fromPublicKey = new solanaWeb3.PublicKey(fromAddress);
    const recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
    // Creating transaction
    const transaction = new solanaWeb3.Transaction().add(
      solanaWeb3.SystemProgram.transfer({
        fromPubkey: fromPublicKey,
        toPubkey: toPublicKey,
        lamports: solanaWeb3.LAMPORTS_PER_SOL * amount,
      }),
    );
    transaction.recentBlockhash = recentBlockhash;
    transaction.feePayer = fromPublicKey;
    let feeCost = await connection.getFeeForMessage(transaction.compileMessage());
    return feeCost.value;
  }
  
  async checkRpcSolanaService() {
    try {
      const url = await this.getSolRpcUrl();
      const resp = await this.rpcProvider.rpc(url, 'getHealth', []).toPromise()
      return resp['error'] ? false : true;
    } catch (err: any) {
      return false;
    }
  }
  
  deriveTokenKeyrings(response, baseChain: 'SYS' | 'ETH' | 'BTC' | 'AVAX' | 'SOL'): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach(token => {
      if (token === null) {
        return;
      }
      let balance;
      balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);
      const keyring = createTokenKeyring(token.symbol, response.address, balance, baseChain, token.contract || token.assetGuid, undefined, token.decimals);
      keyrings.push(keyring);
    });
    return keyrings;
  }
  
  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }

}
