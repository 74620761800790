import { WalletState } from './wallet';
import { SetupState } from './setup';
import { UserPreferencesState } from './userPreferences';
import { SendTokenState } from './sendToken';
import { ConnectionState } from './connection';
import { GasStationState } from './gasStation';
import { PriceOracleState } from './priceOracle';
import { VirtualDebitCardState } from './virtualDebitCard';
import { AppSettingsState } from './appSettings';
import { DevicesListState } from './deviceslist';
import { BankAccountState } from './bankAccounts';

export interface AppState {
  readonly setup?: SetupState;
  readonly wallet?: WalletState;
  readonly userPreferences?: UserPreferencesState;
  readonly sendToken?: SendTokenState;
  readonly connection?: ConnectionState;
  readonly gasStation?: GasStationState;
  readonly priceOracle?: PriceOracleState;
  readonly virtualDebitCard?: VirtualDebitCardState;
  readonly appSettings?: AppSettingsState;
  readonly devicesList?: DevicesListState;
  readonly bankAccount?: BankAccountState;
}

export const getSetupState = (state: AppState) => state.setup;
export const getWalletState = (state: AppState) => state.wallet;
export const getUserPreferencesState = (state: AppState) => state.userPreferences;
export const getSendTokenState = (state: AppState) => state.sendToken;
export const getConnectionState = (state: AppState) => state.connection;
export const getGasStationState = (state: AppState) => state.gasStation;
export const getPriceOracleState = (state: AppState) => state.priceOracle;
export const getVirtualDebitCardState = (state: AppState) => state.virtualDebitCard;
export const getAppSettingsState = (state: AppState) => state.appSettings;
export const getDevicesListState = (state: AppState) => state.devicesList;
export const getBankAccountState = (state: AppState) => state.bankAccount;
