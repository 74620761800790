import { Subject } from 'rxjs';

interface NotifierConstructorProps {
  address: string,
  notifier: any,
  type: 'spt' | 'eth' | 'sys' | 'btc',
  parser?: Function,
  getConnectedMethod?: string,
  getTxMethod?: string
};

export class Notifier {
  private address;
  private notifier;
  private type;
  private parser;
  private getTxMethod;
  public txSubject$ = new Subject();
  public connectedSubject$;

  constructor(props: NotifierConstructorProps) {
    this.address = props.address;
    this.notifier = props.notifier;
    this.type = props.type;
    this.parser = props.parser;
    this.getTxMethod = props.getTxMethod;

    this.subscribeAndPrepareTx();

    // Passing Blockbook notifier as base class, not service, so sometimes this wont be a function
    const getConnected = this.notifier[props.getConnectedMethod || 'connectedSubject$'];

    if (typeof getConnected === 'function') {
      this.connectedSubject$ = getConnected.bind(this.notifier)();
    } else {
      this.connectedSubject$ = getConnected;
    }
  }

  public getNotifier() {
    return this.notifier;
  }

  public getAddress() {
    return this.address;
  }

  public getType() {
    return this.type;
  }

  private getTxType(event) {
    if (this.type === 'spt') {
      if (event.message.tx && event.message.tx.systx) {
        return 'spt';
      }
      if (
        Array.isArray(event.message.receivers) &&
        event.message.receivers[0] &&
        event.message.receivers[0].asset_guid
      ) {
          return 'spt';
        }

      return 'sys';
    }

    if (this.type === 'sys') {
      if (event.tx.tokenTransfers) {
        return 'spt';
      }

      return 'sys';
    }

    if (this.type === 'eth') {
      if(event.tx.tokenTransfers) {
        return 'erc20';
      }

      return 'eth';
    }
    if (this.type.toLowerCase() === 'btc') {
      return 'btc'
    }

    return 'unknown';
  }

  private subscribeAndPrepareTx() {
    // Passing Blockbook notifier as base class, not service, so sometimes this wont be a function
    const getTx = this.notifier[this.getTxMethod || 'txSubject$'];
    const subscribeFunction = (tx) => {
      let parsedTx;

      try {
        parsedTx = JSON.parse(tx);
      } catch(err) {
        parsedTx = tx;
      }

      if (this.parser) {
        parsedTx = this.parser(parsedTx);
      }

      const newTx = {
        ...parsedTx,
        notifierType: this.type,
        address: this.address,
        txType: this.getTxType(parsedTx)
      };

      this.txSubject$.next(newTx);
    };

    if (typeof getTx === 'function') {
      getTx.bind(this.notifier)().subscribe(subscribeFunction);
    } else {
      getTx.subscribe(subscribeFunction);
    }
  }

  public disconnect() {
    if (this.notifier.syscoinWebsocket) {
      return this.notifier.syscoinWebsocket.socket.disconnect()
    }
    
    this.notifier.socket.disconnect();
  }
}
