import { Injectable } from "@angular/core";
import { StorageService } from "../services/storage.service";
import { AppState } from "../store/appState";
import { Store } from "@ngrx/store";
import { NavController } from "@ionic/angular";
import HdKeyringController from "../lib/pangolin/keyring/hd.keyring.controller";
import { Logger } from "./logger.service";
import { CompleteSetupAction } from "../actions/setup.actions";
import { CreateKeychainSuccessAction } from "../actions/wallet.actions";
import { SetUserDataAction, LoadUserPreferencesAction } from "../actions/userPreferences.actions";
import { createTokenKeyring, decryptData, getToastPosition } from "../utils";
import { getSetupMnemonic, getSetupPin } from "../store/setup";
import "rxjs/add/operator/first";
import { Credentials } from "../../global";
import { USER_PREFERENCES_STORAGE_KEY } from "../constants";
import { getKeychain, initWalletWithMnemonic } from "../lib/pangolin/keyring/keychainUtils";
import { hdPaths } from "../lib/pangolin/keyring/index";
import { NetworkService } from "./network.service";
import { environment } from "src/environments/environment";
import { take } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import * as CryptoJS from "crypto-js";
import { ToastController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { WALLET_STORAGE_KEY, BACKUP_WALLET_STORAGE_KEY, EMAIL_STORAGE_KEY, WALLET_KEY } from "src/app/angular-wallet-base/constants";
import { getWalletRedirectUri } from "../store/wallet";

class InfoEmail {
  counter = 0;
  email = "";
  never = false;
}

@Injectable({
  providedIn: "root",
})
export class WalletService {
  public walletRedirectUri = null;

  constructor(
    private storage: StorageService,
    private store: Store<AppState>,
    private nav: NavController,
    private network: NetworkService,
    private toastController: ToastController,
    private platform: Platform,
    private translate: TranslateService
  ) {
    this.store.select(getWalletRedirectUri).subscribe((walletRedirectUri) => {
      this.walletRedirectUri = walletRedirectUri;
    });
  }

  async loadWalletFromStorage() {
    let vault;
    let oldVault;
    const oldStorage = await new Storage({}).create();
    try {
      vault = await this.storage.get(WALLET_STORAGE_KEY);
      oldVault = await oldStorage.get(WALLET_STORAGE_KEY);

      if (oldVault && !vault) {
        Logger.info("Starting vault migration");
        const userPreferences = await oldStorage.get(USER_PREFERENCES_STORAGE_KEY);

        // Save old vault and user preferences on new DB.
        await this.storage.set(WALLET_STORAGE_KEY, oldVault);
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, userPreferences);

        // Reload vault
        vault = await this.storage.get(WALLET_STORAGE_KEY);
        Logger.info("Vault migration finished");
      }

      if (!vault) {
        // trigger vault setup
        await this.nav.navigateRoot("setup/setup-home");
      } 
      else {
        // ask for pin
        Logger.info(`loadWalletFromStorage() Loading wallet, requesting pin, navigating back to /wallet/assets, walletRedirectUri: ${this.walletRedirectUri}` );
        //this is for app initialization when visiting the root route
        if (this.walletRedirectUri) {
          await this.nav.navigateRoot(this.walletRedirectUri);
        } else {
          await this.nav.navigateRoot("/wallet/assets");
        }
      }
    } catch (e) {
      console.log("error", e);
      Logger.info("No vault!");
    }
  }

  async incrementLoginAttempts() {
    let vault;
    try {
      vault = await this.storage.get(EMAIL_STORAGE_KEY);
      if (!vault) {
        vault = new InfoEmail();
      }
      vault.counter++;
      await this.storage.set(EMAIL_STORAGE_KEY, vault);
      return await this.storage.get(EMAIL_STORAGE_KEY);
    } catch (e) {
      Logger.info("Error!");
    }
  }

  async getStorageEmail() {
    try {
      return await this.storage.get(EMAIL_STORAGE_KEY);
    } catch (e) {
      Logger.info("Error!");
    }
  }

  async setStorageEmail(obj) {
    try {
      return await this.storage.set(EMAIL_STORAGE_KEY, obj);
    } catch (e) {
      Logger.info("Error!");
    }
  }

  async completeSetup() {
    const mnemonic = await this.store.select(getSetupMnemonic).pipe(take(1)).toPromise();
    const pin = await this.store.select(getSetupPin).pipe(take(1)).toPromise();

    const keychain = await initWalletWithMnemonic(pin, mnemonic);

    // write the wallet to local store
    await this.storage.set(WALLET_STORAGE_KEY, keychain.vault);

    // For new wallet we are encrypted the phrase
    this.setEncryptedPhrase(mnemonic);

    this.store.dispatch(new CompleteSetupAction());
    await this.dispatchKeychainCreated(keychain);
  }

  async unlockWallet(password): Promise<boolean> {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    let keychain = new HdKeyringController();
    keychain.vault = vault;
    const unlocked = await keychain.unlock(password);

    const keyrings = keychain.keyrings;

    if (keyrings[0]) {
      if (
        !keyrings[0].nodes.length ||
        !keyrings[0].nodes.find((node) => node.network.bech32 === "tb" && node.network.bech32 === "bc") // BTC not founds in nodes
      ) {
        // If nodes length is 0, then coming from old agxpay wallet
        // Get mnemonic and initialize a new one.
        Logger.info("Starting keychain migration");

        keychain = await initWalletWithMnemonic(password, keyrings[0].mnemonic);
        await this.storage.set(WALLET_STORAGE_KEY, keychain.vault);

        Logger.info("Migration complete");
      }
    }

    if (unlocked) {
      await this.dispatchKeychainCreated(keychain);
    }
    return unlocked;
  }

  async dispatchKeychainCreated(keychain) {
    const tokenKeyrings = [];
    await keychain.keyrings[0].nodes.forEachAsync(async (node) => {
      if (node.isSys()) {
        const sysKeyring = createTokenKeyring("SYS", node.getAllAccountAddresses()[0], 0, "SYS");
        console.log(sysKeyring);
        tokenKeyrings.push(sysKeyring);
      } else if (node.isEth()) {
        if (environment.features.eth) {
          tokenKeyrings.push(createTokenKeyring("ETH", node.getAllAccountAddresses()[0], 0, "ETH", null, 0));
        }
        if (environment.features.avax) {
          tokenKeyrings.push(createTokenKeyring("AVAX", node.getAllAccountAddresses()[0], 0, "AVAX", null, 0));
        }
      } else if (node.isBtc()) {
        if (environment.features.btc) {
          tokenKeyrings.push(createTokenKeyring("BTC", node.getAllAccountAddresses()[0], 0, "BTC", null, 0));
        }
      } else if (node.isSol()) {
        if (environment.features.sol) {
          tokenKeyrings.push(createTokenKeyring("SOL", node.getAllAccountAddresses()[0], 0, "SOL", null, 0));
        }
      }
    });

    Logger.info("dispatchKeychainCreated()", tokenKeyrings);
    
    this.store.dispatch(
      new CreateKeychainSuccessAction({
        keyrings: tokenKeyrings,
      })
    );
  }

  async changeWalletPassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const userPreferences = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    let keychain = new HdKeyringController();
    keychain.vault = vault;

    // Decrypting wallet vault with old pwd
    let unlocked = await keychain.unlock(oldPassword);
    if (!unlocked) {
      return false;
    }

    // Creating new vault with new pwd
    const newEncryptedVault = keychain.encryptVault(newPassword);
    await this.storage.set(WALLET_STORAGE_KEY, newEncryptedVault);
    const newVault = await this.storage.get(WALLET_STORAGE_KEY);
    keychain = new HdKeyringController();
    keychain.vault = newVault;

    // Make sure the new vault is working fine with the new pwd
    unlocked = await keychain.unlock(newPassword);

    if (userPreferences && userPreferences.credentials && userPreferences.jwtToken) {
      // User credentials are stored and encrypted in order to update Jwt on login, so need to update them too.
      const decryptedUserPrefs: Credentials = await decryptData(oldPassword, userPreferences.credentials);

      // Effect is going to take care of saving to storage.
      await this.store.dispatch(
        new SetUserDataAction({
          pin: newPassword,
          credentials: decryptedUserPrefs,
          jwtToken: userPreferences.jwtToken,
          lodeid: userPreferences.lodeid,
        })
      );
    }

    return unlocked;
  }

  async migrateFrom201to202(pin) {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const userPrefs = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));
    const decryptedVault: any = HdKeyringController.prototype.decryptVault(pin, vault);
    const decryptedNodes = decryptedVault[0].nodes;
    const activeSysNetwork = await this.network.getActiveSysNetwork();
    const activeEthNetwork = await this.network.getActiveEthNetwork();
    const activeAvaxNetwork = await this.network.getActiveAvaxNetwork();
    const activeSolNetwork = await this.network.getActiveSolNetwork();

    decryptedNodes.forEach(async (node) => {
      if (node.hdPath === hdPaths.syscoinMainnet && activeSysNetwork.network === "testnet" && userPrefs.sys) {
        userPrefs.sys.network = "sys-main";
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }
      if (node.hdPath === hdPaths.ethereumMainnet && activeEthNetwork.network === "testnet" && userPrefs.eth) {
        userPrefs.eth.network = "eth-main";
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (node.hdPath === hdPaths.ethereumMainnet && activeAvaxNetwork.network === "testnet" && userPrefs.avax) {
        userPrefs.avax.network = "avax-main";
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (node.hdPath === hdPaths.solanaMainnet && activeSolNetwork.network === "testnet" && userPrefs.sol) {
        userPrefs.sol.network = "sol-main";
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (!userPrefs.btc) {
        userPrefs.btc = {
          network: environment.production ? "btc-main" : "btc-test",
        };
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (!userPrefs.sol) {
        userPrefs.sol = {
          network: environment.production ? "sol-main" : "sol-test",
        };
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }
    });
  }

  async migrationEncryptedPhrase(pin) {
    const oldHash = await this.storage.get(WALLET_KEY);
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getKeychain(vault, pin);
    if (!oldHash) {
      const mnemonic = keychain.keyrings[0].mnemonic;
      this.setEncryptedPhrase(mnemonic);
    }
  }

  async setEncryptedPhrase(mnemonic) {
    let hash = CryptoJS.SHA256(mnemonic);
    hash = hash.toString(CryptoJS.enc.Hex);
    await this.storage.set(WALLET_KEY, hash);
  }

  async compareEncryptedPhrase(mnemonic) {
    const oldHash = await this.storage.get(WALLET_KEY);
    if (oldHash) {
      let hash = CryptoJS.SHA256(mnemonic);
      hash = hash.toString(CryptoJS.enc.Hex);
      return hash === oldHash;
    }
    return true;
  }

  public async getBackupWalletVault() {
    return this.storage.get(BACKUP_WALLET_STORAGE_KEY);
  }

  public deleteBackupWallet() {
    return this.storage.remove(BACKUP_WALLET_STORAGE_KEY);
  }

  public async checkForMultipleWallets(shouldShowToast = false) {
    const oldVault = await this.getBackupWalletVault();
    const currentVault = await this.storage.get(WALLET_STORAGE_KEY);

    const multipleWalletExist = oldVault && oldVault !== currentVault;

    if (shouldShowToast && multipleWalletExist) {
      await this.showMultipleWalletDetectedToastIfNeeded();
    }

    return multipleWalletExist;
  }

  private async showMultipleWalletDetectedToastIfNeeded() {
    const prefs = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));

    if (!prefs.duplicatedWalletToastShown) {
      const toast = await this.toastController.create({
        message: await this.translate.get("settings.multiple_wallet.toast_message").toPromise(),
        position: getToastPosition(this.platform),
        buttons: [
          {
            text: await this.translate.get("settings.multiple_wallet.toast_button_go_settings").toPromise(),
            handler: () => {
              toast.dismiss();
              this.nav.navigateForward(["wallet", "settings"], {
                queryParams: {
                  tab: "wallet",
                },
              });
            },
          },
          {
            text: await this.translate.get("settings.multiple_wallet.toast_button_hide").toPromise(),
            role: "cancel",
          },
        ],
      });
      await toast.present();

      prefs.duplicatedWalletToastShown = true;

      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }
  }
}
