import { Injectable } from '@angular/core';
import { supportedAssetList } from '../lib/supported-assets/supported-assets';
import { NetworkService } from './network.service';
import { createKeyringId } from '../keyringUtils';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  public keyrings: any = supportedAssetList;

  constructor(private network: NetworkService) {
  }

  private removeBaseChainSymbolFromKeyringId(id) {
    return id.replace('SYS-', '').replace('ETH-', '').replace('AVAX-', '').replace('SOL-', '');
  }

  private getEthKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('ETH') !== -1);
  }

  private getSysKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('SYS') !== -1);
  }
  
  private getAvaxKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('AVAX') !== -1);
  }
  
  private getSolKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('SOL') !== -1);
  }

  private async modifyKeyringsIfTestnet(keychain) {
    const modifiedKeychain = { ...keychain };
    const ethNetwork: any = await this.network.getActiveEthNetwork();
    const sysNetwork: any = await this.network.getActiveSysNetwork();
    const avaxNetwork: any = await this.network.getActiveAvaxNetwork();
    const solNetwork: any = await this.network.getActiveSolNetwork();
    const chainsToModify = ['SYS', 'ETH', 'AVAX', 'SOL'];

    chainsToModify.forEach(chain => {
      let keyringIds;
      let network;

      switch(chain) {
        case 'SYS':
          keyringIds = this.getSysKeyringIds();
          network = sysNetwork;
          break;
        case 'ETH':
          keyringIds = this.getEthKeyringIds();
          network = ethNetwork;
          break;
        case 'AVAX':
          keyringIds = this.getAvaxKeyringIds();
          network = avaxNetwork;
          break;
        case 'SOL':
          keyringIds = this.getSolKeyringIds();
          network = solNetwork;
          break;
        default:
          keyringIds = [];
      }

      if (network.network === 'testnet') {
        keyringIds.forEach(keyringId => {
          const tokenMap = network.TOKEN_MAP;
          const tokenId = this.removeBaseChainSymbolFromKeyringId(keyringId);
  
          if (tokenMap && tokenMap[tokenId]) {
            const newKeyringId = createKeyringId(chain, tokenMap[tokenId])
            modifiedKeychain[newKeyringId] = {
              ...keychain[keyringId],
              asset_guid: tokenMap[tokenId]
            }
            delete modifiedKeychain[keyringId];
          }
        });
      }
    });

    return modifiedKeychain;
  }

  public async getTokenList() {
    return this.modifyKeyringsIfTestnet(this.keyrings);
  }

}
