import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SendGasStationDataAction, GasStationActionTypes } from '../actions/gasStation.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GasStationService } from '../services/gas-station.service';
import { TransactionSenderService } from '../services/transaction-sender.service';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

const bitcoin = (window as any).bitcoinjsLib;

@Injectable()
export class GasStationEffects {
   authSendTokens$ = createEffect(() => this.actions$.pipe(
    ofType<SendGasStationDataAction>(GasStationActionTypes.SEND_GAS_STATION_DATA),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('effect');
      Logger.info('Starting gas station transaction');

      const gasStationData = store.gasStation;

      try {
        const addressRes: any = await this.gasStation.getAddress();
        const txid: any = await this.transactionSender.sendAsset(
          Number(gasStationData.amount),
          gasStationData.address,
          addressRes.address,
          action.payload.pin,
          gasStationData.assetGuid,
          'Gas station'
        );
        setTimeout(() => {
          // Avoid "Not an SPT transaction error"
          this.gasStation.fulfillOrder(txid);
        }, 2000)

      } catch (e) {
        Logger.error('Something went wrong error', e);

        if (e.error.message && e.error.message.indexOf('txVersion') !== -1) {
          const alert = await this.alertController.create({
            header: 'Error',
            // tslint:disable-next-line
            message: await this.translate.get('tokens.send.errors.txversion').toPromise(),
            buttons: ['OK']
          });
          await alert.present();
        } else if (e.error.message) {
          const alert = await this.alertController.create({
            header: await this.translate.get('gas_station.refill_confirmation.error_title').toPromise(),
            message: e.error.message,
          });
  
          await alert.present();
        } else {
          const alert = await this.alertController.create({
            header: await this.translate.get('gas_station.refill_confirmation.error_title').toPromise(),
            message: await this.translate.get('gas_station.refill_confirmation.error_description').toPromise(),
          });
  
          await alert.present();
        }
      }

      
      await this.nav.navigateForward(['/wallet/assets']);
    })
  ), { dispatch: false });


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private transactionSender: TransactionSenderService,
              private gasStation: GasStationService,
              private nav: NavController,
              private alertController: AlertController,
              private translate: TranslateService) {
  }

}
