import { Action } from '@ngrx/store';

export enum bankAccountActionTypes {
  BANK_ACCOUNT_GET_LIST_REQUEST = '[bankAccount] Get bank accounts request',
  BANK_ACCOUNT_GET_LIST_SUCCESS = '[bankAccount] Get bank accounts success',
  BANK_ACCOUNT_GET_LIST_ERROR = '[bankAccount] Get bank accounts error',
  BANK_ACCOUNT_GET_BALANCE_REQUEST = '[bankAccount] Get bank account balance request',
  BANK_ACCOUNT_GET_BALANCE_ERROR = '[bankAccount] Get bank account balance error',
  BANK_ACCOUNT_GET_BALANCE_SUCCESS = '[bankAccount] Get bank account balance success',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST = '[bankAccount] Get bank account history request',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_SUCCESS = '[bankAccount] Get bank account history success',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_ERROR = '[bankAccount] Get bank account history error',
  PT_TOKEN_SET_SEND_DATA = '[bankAccount] PT Token set send data',
  PLAID_SET_LINK_TOKEN = '[bankAccount] Plaid link token'
}

export class SetPlaidLinkToken implements Action {
  readonly type = bankAccountActionTypes.PLAID_SET_LINK_TOKEN;
  constructor(readonly payload: string | boolean) {}
}
export class GetBankAccountRequestAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_REQUEST;
  constructor() {}
}

export class GetBankAccountSuccessAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_SUCCESS;
  constructor(readonly payload: Array<any>) {}
}

export class GetBankAccountErrorAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_ERROR;
  constructor() {}
}

export class GetBankAccountBalanceRequest implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_REQUEST;
  constructor() {}
}

export class GetBankAccountBalanceError implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_ERROR;
  constructor() {}
}

export class GetBankAccountBalanceSuccess implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_SUCCESS;
  constructor(readonly payload: number) {}
}

export class GetBankAccountTransactionHistoryRequest implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST;
  constructor() {}
}

export class GetBankAccountTransactionHistorySuccess implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_SUCCESS;
  constructor(readonly payload: Array<any>) {}
}

export class GetBankAccountTransactionHistoryError implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_ERROR;
  constructor() {}
}

export class SetPTTokenSendDataAction implements Action {
  readonly type = bankAccountActionTypes.PT_TOKEN_SET_SEND_DATA;
  constructor(readonly payload: { amount: string, address: string, token: string }) {}
}
