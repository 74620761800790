import { Injectable } from '@angular/core';
import { Storage as IonicStorage } from '@ionic/storage'
import { Drivers } from '@ionic/storage';
import { Storage } from '@ionic/storage-angular';
import { DB_NAME } from '../../../db_name';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { WALLET_STORAGE_KEY, BACKUP_WALLET_STORAGE_KEY, EMAIL_STORAGE_KEY, WALLET_KEY} from 'src/app/angular-wallet-base/constants'
import { USER_PREFERENCES_STORAGE_KEY } from '../constants';
import { File } from '@ionic-native/file/ngx';
import { AppModeService } from './app-mode.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;
  private isDesktopMode;
  private platformStatus: boolean;
  
  private STORAGE_MINIMUM_SPACE = 20000; // 20MB

  constructor(private storage: Storage,
              private file: File,
              private appMode: AppModeService,
              private platform: Platform) {

    this.appMode.isDesktopMode.subscribe(desktopMode => {
      this.isDesktopMode = desktopMode;
    })
    this.platform.is('mobileweb') || this.platform.is('desktop') ? this.platformStatus = true : this.platformStatus = false;
  }

  async init() {
    await this.storage.defineDriver(CordovaSQLiteDriver)
    const storage = await this.storage.create();
    this._storage = storage;

    await this.migrateOnDbDriverChangeIfNeeded();
  }

  async migrateOnDbDriverChangeIfNeeded() {
    // Wallet is now using SQLite as main storage driver starting on 2.1.2
    // Migrate old vault saved using IndexedDB to the new one using SQLite
    const oldStorage = await this.getIndexedDbWalletStorage();
    const oldVault = await oldStorage.get(WALLET_STORAGE_KEY);
    const currentVault = await this.storage.get(WALLET_STORAGE_KEY);

    if (oldVault && this.storage.driver !== 'asyncStorage') {
      await this.storage.set(WALLET_STORAGE_KEY, oldVault);

      // Gotta migrate other preferences as well
      const oldPreferences = await oldStorage.get(USER_PREFERENCES_STORAGE_KEY);
      const oldEmailStorage = await oldStorage.get(EMAIL_STORAGE_KEY);
      const oldWalletHash = await oldStorage.get(WALLET_KEY);

      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, oldPreferences);
      await this.storage.set(EMAIL_STORAGE_KEY, oldEmailStorage);
      await this.storage.set(WALLET_KEY, oldWalletHash);
      await this.storage.set(BACKUP_WALLET_STORAGE_KEY, currentVault);

      await oldStorage.clear();
    }
  }

  async deviceHasEnoughSpace() {
    const availableSpaceInKbytes = await this.file.getFreeDiskSpace();

    // SQLite storage is permanent, so only check for available space if it's not available
    return this.platformStatus || this._storage.driver === 'cordovaSQLiteDriver' || availableSpaceInKbytes >= this.STORAGE_MINIMUM_SPACE;
  }

  public async getIndexedDbWalletStorage() {
    const ionicStorageInstance = new IonicStorage({
      name: DB_NAME,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    const oldStorage = await ionicStorageInstance.create();

    return oldStorage;
  }

  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public remove(key: string) {
    return this._storage?.remove(key);
  }

  public clear() {
    return this._storage?.clear();
  }

  public keys() {
    return this._storage?.keys();
  }

  public length() {
    return this._storage?.length();
  }

  public forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any) {
    return this._storage?.forEach(iteratorCallback);
  }
}
