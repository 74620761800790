import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import {
  AuthenticateAction,
  GetFeePerAssetTxFailedAction,
  GetFeePerAssetTxRequestAction,
  GetFeePerAssetTxSuccessAction,
  GetTokenBalancesFailedAction,
  GetTokenBalancesRequestAction,
  GetTokenBalancesSuccessAction,
  GetTokenFiatValuesFailedAction,
  GetTokenFiatValuesRequestAction,
  GetTokenFiatValuesSuccessAction,
  GetTokenHistoryFailedAction,
  GetTokenHistoryRequestAction,
  GetTokenHistorySuccessAction,
  RemoveUnconfirmedTransactionsAction,
  ResetWalletAction,
  WalletActionTypes,
} from "../actions/wallet.actions";
import { GetBankAccountBalanceSuccess, GetBankAccountSuccessAction } from "../actions/bankAccount.actions";
import { StorageService } from "../services/storage.service";
import { NavController } from "@ionic/angular";
import { LoadUserPreferencesAction } from "../actions/userPreferences.actions";
import { Logger } from "../services/logger.service";
import { CoinGeckoService } from "../services/coingecko.service";
import "rxjs/add/operator/first";
import { TokenFiatValueMap, TokenKeyring } from "../../global";
import { WebSocketAddressNotifierService } from "../services/WebSocketAddressNotifier.service";
import { AGX_KEYRING_ID, AUX_KEYRING_ID, SYS_PER_ASSET_TX, USER_PREFERENCES_STORAGE_KEY } from "../constants";
import { from, of } from "rxjs";
import { BlockBookService } from "../services/blockbook.service";
import { getVirtualCardsAndBalances, hashCode, lookupTokenKeyring, getBankAccountsAndBalances, getPTBalances } from "../utils";
import { GasStationService } from "../services/gas-station.service";
import { BankAccountService } from "../services/bank-account.service";
import { getWalletRedirectUri, WalletState } from "../store/wallet";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { MembersPortalService } from "..//services/members-portal.service";
import { PriceOracleService } from "../services/price-oracle.service";
import BigNumber from "bignumber.js";
import { BlockbookGetAddressTxsResponseWithWalletData } from "../services/blockbook";
import promiseAllProperties from "promise-all-properties";
import { GetVirtualDebitCardsSuccessAction } from "../actions/virtualDebitCard.actions";
import { VirtualDebitCardService } from "../services/virtual-debit-card.service";
import { TokenManagerService } from "../services/token-manager.service";
import { createKeyringId } from "../keyringUtils";
import { ServiceCheckerService } from "../services/service-check.service";
import { WalletService } from "../services/wallet.service";
import { AvalancheService } from "../services/avalanche.service";
import { SolanaService } from "../services/solana.service";
import { logger } from "ethers";

@Injectable()
export class WalletEffects {
  private isJwtValid;
  private walletRedirectUri: string;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private nav: NavController,
    private storage: StorageService,
    private wsAddressNotifier: WebSocketAddressNotifierService,
    private coinGeckoService: CoinGeckoService,
    private gasStationService: GasStationService,
    private blockbookService: BlockBookService,
    private translate: TranslateService,
    private members: MembersPortalService,
    private oraclePriceService: PriceOracleService,
    private virtualCardService: VirtualDebitCardService,
    private checkerServices: ServiceCheckerService,
    private tokenManager: TokenManagerService,
    private avalancheService: AvalancheService,
    private solanaService: SolanaService,
    private bankAccountService: BankAccountService,
    private wallet: WalletService
  ) {
    this.store.select(getWalletRedirectUri).subscribe((walletRedirectUri) => {
      this.walletRedirectUri = walletRedirectUri;
    });
  }

  createKeychainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.CREATE_KEYCHAIN_SUCCESS),
      map((action: { payload: { keyrings: Array<TokenKeyring> } }) => {
        const chains = [];
        chains.push({ symbol: "SYS", address: lookupTokenKeyring("SYS", action.payload.keyrings).address });

        if (environment.features.eth) {
          chains.push({ symbol: "ETH", address: lookupTokenKeyring("ETH", action.payload.keyrings).address });
        }

        if (environment.features.hl) {
          chains.push({ symbol: "HL", address: "" });
        }

        if (environment.features.debitCard) {
          chains.push({ symbol: "debit-card", address: "" });
        }

        if (environment.features.btc) {
          chains.push({ symbol: "BTC", address: lookupTokenKeyring("BTC", action.payload.keyrings).address });
        }

        if (environment.features.bankAccounts) {
          chains.push({ symbol: "bank-account" });
          chains.push({ symbol: "PT" }); // Prime Trust tokens
        }

        if (environment.features.avax) {
          chains.push({ symbol: "AVAX", address: lookupTokenKeyring("AVAX", action.payload.keyrings).address });
        }

        if (environment.features.sol) {
          chains.push({ symbol: "SOL", address: lookupTokenKeyring("SOL", action.payload.keyrings).address });
        }

        return new GetTokenBalancesRequestAction({
          chains,
        });
      })
    )
  );

  getAllTokenBalances$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WalletActionTypes.GET_ALL_TOKEN_BALANCES),
        withLatestFrom(this.store),
        map(async ([action, store]) => {
          const keychain = (store as AppState).wallet.keychain;
          const sysAddress = keychain.SYS.address;
          const chains = [];

          this.isJwtValid = await this.members.isJwtValid();

          chains.push({ symbol: "SYS", address: sysAddress });

          if (environment.features.eth) {
            const ethAddress = keychain.ETH.address;
            chains.push({ symbol: "ETH", address: ethAddress });
          }

          if (environment.features.btc) {
            chains.push({ symbol: "BTC", address: keychain.BTC.address });
          }

          if (environment.features.hl) {
            chains.push({ symbol: "HL", address: "" });
          }

          if (environment.features.debitCard) {
            chains.push({ symbol: "debit-card" });
          }

          if (environment.features.bankAccounts) {
            chains.push({ symbol: "bank-account" });
            chains.push({ symbol: "PT" }); // Prime Trust tokens
          }

          if (environment.features.avax) {
            const ethAddress = keychain.ETH.address;
            chains.push({ symbol: "AVAX", address: ethAddress });
          }

          if (environment.features.sol) {
            const solAddress = keychain.SOL.address;
            chains.push({ symbol: "SOL", address: solAddress });
          }

          return this.store.dispatch(
            new GetTokenBalancesRequestAction({
              chains,
            })
          );
        })
      ),
    { dispatch: false }
  );

  tokenBalancesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_BALANCES_REQUEST),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        let actionRef: GetTokenBalancesRequestAction = action["payload"];
        const requests: {
          SYS?: any;
          ETH?: any;
          HL?: any;
          DEBIT_CARD?: any;
          BANK_ACCOUNT?: any;
          chains?: any;
          tokens?: any;
          BTC?: any;
          PT?: any;
          AVAX?: any;
          SOL?: any;
        } = {};
        Logger.info("GetTokenBalancesRequestAction actionRef", actionRef);
        actionRef["chains"].forEach((chain) => {
          if (chain.symbol === "ETH") {
            requests.ETH = this.blockbookService.getEthTokenBalances(chain.address);
          } else if (chain.symbol === "SYS") {
            requests.SYS = this.blockbookService.getSysTokenBalances(chain.address);
          } else if (chain.symbol === "BTC") {
            requests.BTC = this.blockbookService.getBtcBalance(chain.address);
          } else if (chain.symbol === "HL") {
            requests.HL = this.members.getHlTokensBalance();
          } else if (chain.symbol === "debit-card") {
            requests.DEBIT_CARD = getVirtualCardsAndBalances(this.members, this.virtualCardService);
          } else if (chain.symbol === "bank-account") {
            requests.BANK_ACCOUNT = getBankAccountsAndBalances(this.bankAccountService);
          } else if (chain.symbol === "PT") {
            requests.PT = getPTBalances(this.bankAccountService);
          } else if (chain.symbol === "AVAX") {
            requests.AVAX = this.avalancheService.getAvaxTokenBalances(chain.address);
          } else if (chain.symbol === "SOL") {
            requests.SOL = this.solanaService.getSolTokenBalances(chain.address);
          }
        });

        requests.chains = actionRef["chains"].map((chain) => chain.symbol);
        requests.tokens = this.tokenManager.getTokenList();

        Logger.info("GetTokenBalancesRequestAction requests chains and tokens", requests);

        return from(promiseAllProperties(requests));
      }),
      withLatestFrom(this.store),
      map(([keyrings, store]) => {
        Logger.info("Wallet Effectg - keyrings", keyrings);
        Logger.info("Wallet Effectg - store", store);
        // first go thru the eth tokens and assign them indexes for filtering later on
        if (keyrings.ETH) {
          keyrings.ETH = keyrings.ETH.map((keyring, index) => {
            if (keyring.symbol !== "ETH") {
              keyring.ethFilterIndex = index + 1;
            }

            return keyring;
          });
        }

        // see if we have a debit card keyring, process it and remove it from further processing
        if (keyrings.DEBIT_CARD) {
          this.store.dispatch(new GetVirtualDebitCardsSuccessAction({ cards: keyrings.DEBIT_CARD }));
          delete keyrings.DEBIT_CARD;
        }

        if (keyrings.BANK_ACCOUNT) {
          this.store.dispatch(new GetBankAccountSuccessAction(keyrings.BANK_ACCOUNT.list));
          this.store.dispatch(new GetBankAccountBalanceSuccess(keyrings.BANK_ACCOUNT.balance));
          delete keyrings.BANK_ACCOUNT;
        }

        const flatKeyrings = [].concat(...Object.values(keyrings));
        const result: any = {};
        flatKeyrings.forEach(
          (keyring) =>
            (result[
              createKeyringId(
                keyring.baseChainSymbol,
                keyring.baseChainSymbol === "HL" || keyring.baseChainSymbol === "PT" ? keyring.symbol : keyring.guid
              )
            ] = keyring)
        );

        const supportedAssetList = keyrings.tokens;

        // Filtering out unsupported coins
        const supportedList = Object.keys(supportedAssetList);
        const tokensToInclude = [];

        if (keyrings.BTC) {
          result["BTC"] = Object.assign({}, keyrings.BTC[0], keyrings.tokens.BTC);
        }

        if (keyrings.PT) {
          result["PT-AGX"] = Object.assign({}, result.undefined["PT-AGX"], keyrings.PT.agxBalance);
          result["PT-AUX"] = Object.assign({}, result.undefined["PT-AUX"], keyrings.PT.auxBalance);
          result["PT-LODE"] = Object.assign({}, result.undefined["PT-LODE"], keyrings.PT.lodeBalance);
        }

        for (let keyringId in result) {
          if (environment.features.multicoin || supportedList.includes(keyringId)) {
            tokensToInclude[keyringId] = result[keyringId];
          }
        }

        const serviceStatuses = store.connection.services;

        supportedList.forEach((supportedKeyringId) => {
          if (!tokensToInclude[supportedKeyringId]) {
            if (supportedKeyringId === AGX_KEYRING_ID && !environment.features.agx) {
              return;
            }

            if (supportedKeyringId === AUX_KEYRING_ID && !environment.features.aux) {
              return;
            }

            if (supportedAssetList[supportedKeyringId].baseChainSymbol === "ETH" && !environment.features.eth) {
              return;
            }

            // Added to tolerate services down
            if (
              (supportedKeyringId === "ETH" && !store.connection.services.ethBB) ||
              (supportedKeyringId === "BTC" && !store.connection.services.btcBB) ||
              (supportedKeyringId === "SYS" && !store.connection.services.sysBB) ||
              (supportedAssetList[supportedKeyringId].baseChainSymbol === "ETH" && !store.connection.services.ethBB) ||
              (supportedAssetList[supportedKeyringId].baseChainSymbol === "AVAX" && !store.connection.services.avaxSnowTrace) ||
              (supportedAssetList[supportedKeyringId].baseChainSymbol === "AVAX" && !store.connection.services.avaxRpc) ||
              (supportedAssetList[supportedKeyringId].baseChainSymbol === "SOL" && !store.connection.services.solRpc)
            ) {
              return;
            }

            const tokenInfo = supportedAssetList[supportedKeyringId];

            if (tokenInfo.baseChainSymbol === "SYS" && !serviceStatuses.sysBB) {
              return;
            }

            if (keyrings.chains.indexOf(tokenInfo.baseChainSymbol) === -1) {
              return;
            }

            tokensToInclude[supportedKeyringId] = {
              ...tokenInfo,
              balance: 0,
              keyringId: supportedKeyringId,
              guid: tokenInfo.asset_guid,
              address: tokensToInclude[tokenInfo.baseChainSymbol] ? tokensToInclude[tokenInfo.baseChainSymbol].address : "",
            };

            if (tokenInfo.baseChainSymbol === "ETH" && tokenInfo.symbol !== tokenInfo.baseChainSymbol) {
              // Need this or ERC20 tx history will contain normal ETH txs.
              // this is a generic number, but should be something big or might find another token.
              tokensToInclude[supportedKeyringId].ethFilterIndex = 999999999;
            }
          }
        });
        Logger.info('WalletEffects - getKeyringBalances - tokensToInclude', tokensToInclude);
        return new GetTokenBalancesSuccessAction({ keyrings: tokensToInclude });
      }),
      catchError((error) => {
        Logger.error(error);
        return of(new GetTokenBalancesFailedAction({ error }));
      })
    )
  );

  tokenBalancesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_BALANCES_SUCCESS),
      withLatestFrom(this.store),
      tap(async ([action, store]) => {
        if (environment.features.dust) {
          const sysKeyring = store.wallet.keychain.SYS;
          const sysBalance = Number(sysKeyring.balance);

          if (sysBalance < (sysKeyring.feePerAssetTx || SYS_PER_ASSET_TX)) {
            Logger.info("Claiming dust");
            try {
              const dust = await this.gasStationService.claimDust();

              if (dust instanceof Error) {
                throw dust;
              }
            } catch (err) {
              Logger.error(err);
            }
          }
        }
      }),
      switchMap(([action, store]) => {
        const { queryCount, keychain } = store.wallet as WalletState;

        if (queryCount === 1) {
          // start address notifier
          Object.values(keychain).forEachAsync(async (keyring: TokenKeyring) => {
            let type;
            if (keyring.baseChainSymbol === "SYS") {
              if (keyring.symbol === "SYS") {
                type = "sys";
              } else {
                type = "spt";
              }
            }

            if (keyring.baseChainSymbol === "ETH") {
              type = "eth";
            }

            if (keyring.baseChainSymbol === "BTC") {
              type = "btc";
            }

            if (keyring.baseChainSymbol === "AVAX" && keyring.symbol === "AVAX") {
              type = "avax";
            }

            if (keyring.baseChainSymbol === "SOL" && keyring.symbol === "SOL") {
              type = "sol";
            }

            if (type && !this.wsAddressNotifier.hasNotifierOfType(type)) {
              await this.wsAddressNotifier.newNotifier(keyring.address, type);
            }
          });

          this.wsAddressNotifier.logNotifiers();

          this.wallet.checkForMultipleWallets(true);

          Logger.info("Wallet balances loaded, navigating to /wallet/assets");
          if (this.walletRedirectUri) {
            this.nav.navigateRoot(this.walletRedirectUri);
          } else {
            this.nav.navigateRoot("/wallet/assets");
          }
        }

        const actions = [];
        // update token fiat values every time we fetch balances
        actions.push(
          new GetTokenFiatValuesRequestAction({
            symbols: Object.keys(store.wallet.keychain),
          })
        );

        // update syscoin fee per asset tx only if we haven't already
        if (environment.features.hl && keychain.SYS.feePerAssetTx === undefined) {
          actions.push(new GetFeePerAssetTxRequestAction());
        }

        return actions;
      })
    )
  );

  feePerAssetTxRequest = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_FEE_PER_ASSET_TX_REQUEST),
      switchMap((action) => from(this.oraclePriceService.getSysFee())),
      map((fee: number) => new GetFeePerAssetTxSuccessAction({ fee })),
      catchError((error) => {
        Logger.error(error);
        return of(new GetFeePerAssetTxFailedAction({ error }));
      })
    )
  );

  tokenFiatValuesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_FIAT_VALUES_REQUEST),
      map((action: GetTokenFiatValuesRequestAction) => action.payload),
      withLatestFrom(this.store),
      switchMap(async ([payload, store]) => {
        return Promise.all([
          this.coinGeckoService.getValueInBaseCurrency(payload.symbols, [store.userPreferences.baseCurrency]),
          this.oraclePriceService.getValueInBaseCurrency(payload.symbols, [store.userPreferences.baseCurrency]),
        ]);
      }),
      withLatestFrom(this.store),
      map(([fiatResults, store]) => {
        const publicTokens = fiatResults[0];
        const privateTokens = fiatResults[1];
        const privateTokenValueData = store.priceOracle;
        const fiatValues: TokenFiatValueMap = {};

        // convert public tokens to the correct data struct
        Object.keys(publicTokens.fiatValues).forEach((keychainId) => {
          fiatValues[keychainId] = { fiatValue: publicTokens.fiatValues[keychainId] };

          // OUTLIER: AGX private endpoint provides sysTxFee info so attach it.
          if (privateTokens.tx_fee) {
            fiatValues[keychainId].tx_fee = privateTokens.tx_fee;
          }
        });

        Object.keys(privateTokens.fiatValues).forEach((keyringId) => {
          switch (keyringId) {
            case AGX_KEYRING_ID:
            case "HL-AGX":
            case "PT-AGX":
            case "AVAX-0x648860804E3f2778b28B5760ED1D5d1afdA2C2aC":
            case "AVAX-0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8":
            case "SOL-Czh5EHJXZzHMiUeQwY1ZcJUpaf1yBpetdKswMx6NVAhT":
            case "SOL-CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v":
              fiatValues[keyringId] = {
                fiatValue: privateTokens.fiatValues[keyringId],
                markup: privateTokenValueData.agxMarkup,
              };
              break;

            case AUX_KEYRING_ID:
            case "PT-AUX":
            case "AVAX-0x37F52e650ac21354Aca33A21366986CDf03a3670":
            case "AVAX-0x70616a337433a9207fb5c43067c6785aba671c0b":
            case "SOL-3QjcGDn3nJbnt1KZvC3RRNCN3v6gQ14aykk8eCzoLc6X":
            case "SOL-Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L":
              fiatValues[keyringId] = {
                fiatValue: privateTokens.fiatValues[keyringId],
                markup: privateTokenValueData.auxMarkup,
              };
              break;

            case "HL-LODE":
            case "PT-LODE":
            case "AVAX-0x9cCed5a8994DaeFc4b65429Fa92f84eDB50Ecc5c":
            case "AVAX-0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1":
            case "SOL-BKzgKX1xskUMj99rR24z2h775dgF9Eju7H9YmSsFrkDn":
            case "SOL-H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR":
              fiatValues[keyringId] = {
                fiatValue: privateTokens.fiatValues[keyringId],
                markup: 1,
              };
              break;
            case "AVAX-0xe0285fc6a60daa223219fb01d78b3baee133017c":
            case "AVAX-0xcc871dbbc556ec9c3794152ae30178085b25fa26":
              fiatValues[keyringId] = {
                fiatValue: privateTokens.fiatValues[keyringId],
                markup: 1,
              };
              break;
            default:
              break;
          }
          // NOTE: for AGX and AUX the fiatValue (spot price) isn't actually used anywhere in the UI. The value
          //        displayed should be retailFiatValue. Because the UI is already referencing fiatValue in many places
          //        and spot price is not needed we populate AGX/AUX fiatValue with its retailFiatValue.
          fiatValues[keyringId].retailFiatValue = new BigNumber(fiatValues[keyringId].fiatValue)
            .multipliedBy(fiatValues[keyringId].markup)
            .toNumber();
          fiatValues[keyringId].fiatValue = fiatValues[keyringId].retailFiatValue;
        });

        return new GetTokenFiatValuesSuccessAction({
          baseCurrency: publicTokens.baseCurrency,
          fiatValues,
        });
      }),
      catchError((error) => {
        Logger.error(error);
        return of(new GetTokenFiatValuesFailedAction({ error }));
      })
    )
  );

  // @Effect() tokenMarkup$ = this.actions$.pipe(
  //   ofType(WalletActionTypes.GET_TOKEN_FIAT_VALUES_SUCCESS),
  //   switchMap(async () =>  await this.oraclePriceService.getMarkup('all')),
  //   withLatestFrom(this.store),
  //   map(([markup, store]) => {
  //     return new SetPriceOracleDataAction({
  //       data: {
  //         agxMarkup: (markup as any).agx,
  //         auxMarkup: (markup as any).aux,
  //         ...store.priceOracle
  //       }
  //     });
  //   })
  // );

  // @Effect() tokenRetailFiatValue = this.actions$.pipe(
  //   ofType(PriceOracleActionTypes.SET_PRICE_ORACLE_DATA),
  //   withLatestFrom(this.store),
  //   map(([action, store]) => {
  //     const payload = {};
  //     Object.keys(store.wallet.keychain).forEach(keyringId => {
  //       if (store.wallet.keychain[keyringId].markup) {
  //         payload[keyringId] = new BigNumber(store.wallet.keychain[keyringId].fiatValue).multipliedBy(new BigNumber(store.wallet.keychain[keyringId].markup)).toNumber();
  //       } else {
  //         payload[keyringId] = store.wallet.keychain[keyringId].fiatValue;
  //       }
  //     });
  //     return new SetTokenRetailFiatValuesAction(payload);
  //   })
  // );

  tokenHistoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_HISTORY_REQUEST),
      map((action: GetTokenHistoryRequestAction) => action.payload),
      switchMap((payload) => {
        const { baseChainSymbol, sysGuid, ethFilterIndex, address, pageNum, itemsPerPage } = payload;
        switch (baseChainSymbol) {
          case "SYS":
            return from(this.blockbookService.getSysTokenHistory(address, pageNum, itemsPerPage, sysGuid));
          case "ETH":
            return from(this.blockbookService.getEthTokenHistory(address, pageNum, itemsPerPage, ethFilterIndex, sysGuid));
          case "BTC":
            return from(this.blockbookService.getBtcHistory(address, pageNum, itemsPerPage));
          case "PT":
            const token = createKeyringId(baseChainSymbol, sysGuid);
            return from(this.bankAccountService.getPTTokenTransactions(pageNum, itemsPerPage, token, sysGuid));
          case "AVAX":
            return from(this.avalancheService.getAvaxTokenHistory(address, pageNum, itemsPerPage, ethFilterIndex, sysGuid));
          case "SOL":
            const itemPerPage = environment.SOLANA_HISTORY_LIMIT;
            return from(this.solanaService.getSolTokenHistory(address, pageNum, itemPerPage, ethFilterIndex, sysGuid));
        }
      }),
      map((result: BlockbookGetAddressTxsResponseWithWalletData) => {
        if (result.page === 1) {
          result.hash = hashCode(JSON.stringify(result.transactions || []));
        } else {
          result.lastHash = hashCode(JSON.stringify(result.transactions || []));
        }
        return new GetTokenHistorySuccessAction({ result });
      }),
      catchError((error) => {
        Logger.error(error);
        return of(new GetTokenHistoryFailedAction({ error }));
      })
    )
  );

  getTokenHistorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetTokenHistorySuccessAction>(WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]) => {
          // after we fetch balance data push user to assets view. Only do this the first fetch.
          if (store.wallet.queryCount === 1) {
            Logger.info("getTokenHistorySuccess$: pushing user to assets view /wallet/assets");
            await this.nav.navigateRoot("/wallet/assets");
          }

          // get a list of current unconfirmed txs and return if there aren't any
          const unconfirmedTxs = store.wallet.unconfirmedTxs;
          if (!unconfirmedTxs.length) {
            return;
          }
          // get an array of all confirmed txids we have available. any unconfirmed txs will end up in list once confirmed
          // filter confirmed txs before mapping
          const confirmedTxIds = [].concat.apply(
            [],
            action.payload.result.transactions
              .filter((tx) => tx.confirmations)
              .map((tx) => {
                return tx.txid || tx["hash"];
              })
          );

          // filter the unconfirmed list to only include transactions which are in the confirmed list. These are the txs to remove
          const newlyConfirmedTxIds = unconfirmedTxs
            .filter((tx) => confirmedTxIds.find((txid) => txid === tx.txid))
            .map((tx) => tx.txid || tx["hash"]);

          // fire off an action to remove those transactions from the unconfirmedTxs state since they are now confirmed
          if (newlyConfirmedTxIds.length) {
            Logger.info("removing newly-confirmed transactions: " + newlyConfirmedTxIds);
            this.store.dispatch(new RemoveUnconfirmedTransactionsAction({ transactionIds: newlyConfirmedTxIds }));
          }
        })
      ),
    { dispatch: false }
  );

  resetWallet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ResetWalletAction>(WalletActionTypes.RESET_WALLET),
        tap(async (data) => {
          this.members.clearRefreshInterval();
          // delete the vault
          await this.storage.clear();
          Logger.info("Vault and User Preferences deleted");
          await this.nav.navigateForward("/setup/setup-home", { queryParams: { skip: true } });

          this.wsAddressNotifier.clearNotifiers();

          return data;
        })
      ),
    { dispatch: false }
  );

  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticateAction>(WalletActionTypes.AUTHENTICATE),
      map(() => new LoadUserPreferencesAction())
    )
  );
}
