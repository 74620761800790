import { createSelector } from '@ngrx/store';
import { getAppSettingsState } from './appState';

export interface AppSettingsState {
  readonly pageTitle: string,
  readonly pageSecondaryTitle: string,
  readonly useI18nOnTitle: boolean,
  readonly forceUpgrade: boolean
}

export const getPageTitle = createSelector(
  getAppSettingsState,
  (state) => state.pageTitle
);

export const getPageSecondaryTitle = createSelector(
  getAppSettingsState,
  (state) => state.pageSecondaryTitle
);

export const getShouldUseI18n = createSelector(
  getAppSettingsState,
  (state) => state.useI18nOnTitle
);

export const getForceUpgrade = createSelector(
  getAppSettingsState,
  (state) => state.forceUpgrade
);
