import { Injectable } from '@angular/core';
import { BlockbookTxNotifier } from 'blockbook-tx-notifier';
import { SyscoinWebsocketConfigService, SyscoinWebsocketService } from 'syscoin-websocket';
import { Logger } from './logger.service';
import { ToastController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  satoshiToNum,
  weiToNum,
  setNumberToPrecision,
  getToastPosition,
  getTransactionAmountsByAddress,
  getSendTypeValueFromtxAmount,
  getReceiveTypeValueFromtxAmount,
  getTransactionAmountsByAddressAvax,
  getTransactionAmountsByAddressSol
} from '../utils';
import { Notifier } from '../Notifier';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { getKeychain } from '../store/wallet';
import { ZdagTransactionAction, WalletKeychain } from '../../global';
import {
  AddUnconfirmedTransactionAction,
  GetTokenBalancesRequestAction,
  ClearTxHistory,
  GetTokenHistoryRequestAction,
  RemoveUnconfirmedTransactionsAction,
  ZdagConfirmedTransactionAction,
  ZdagErrorTransactionAction
} from '../actions/wallet.actions';
import { BlockBookService } from './blockbook.service';
import { ConnectSuccessAction, DisconnectAction, SetServiceStatusAction } from '../actions/connection.actions';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NetworkService } from './network.service';
import { TokenManagerService } from './token-manager.service';
import { createKeyringId } from '../keyringUtils';
import { MembersPortalService } from './members-portal.service';
import { SmallToCryptoPipe } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
const sjs = require('syscoinjs-lib');
import { SocketService } from './custom-socket.service';
import { BigNumber } from 'ethers';
import { environment } from 'src/environments/environment';

@Injectable()
export class WebSocketAddressNotifierService {
  private notifiers = [];
  private addresses: string[] = [];
  private keychain: WalletKeychain;
  private events$ = new Subject();
  public isConnected = false;
  public socketServiceSol;
  public socketServiceAvax;

  constructor(private toast: ToastController,
              private translate: TranslateService,
              private store: Store<AppState>,
              private blockbook: BlockBookService,
              private syscoinWs: SyscoinWebsocketService,
              private syscoinWsConfig: SyscoinWebsocketConfigService,
              private router: Router,
              private nav: NavController,
              private networkService: NetworkService,
              private tokenManager: TokenManagerService,
              private membersPortalService: MembersPortalService,
              private platform: Platform,
              private smallToCryptoPipe: SmallToCryptoPipe) {
    this.store.select(getKeychain).subscribe(keychain => {
      this.keychain = keychain;
    });
  }

  async getEthApi() {
    const network = await this.networkService.getActiveEthNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL
    };
  }
  
  async getAvaxApi() {
    const network = await this.networkService.getActiveAvaxNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL
    };
  }
  
  async getSolApi() {
    const network = await this.networkService.getActiveSolNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL
    };
  }

  async getSysApi() {
    const network = await this.networkService.getActiveSysNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL,
      spt: network.SPT_SOCKET_URL
    };
  }
  async getBtcApi() {
    const network = await this.networkService.getActiveBtcNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL
    };
  }

  protected getBBTxToastAmount = (amount, tokenInfo) => {
    if (tokenInfo.baseChainSymbol === 'ETH') {
      if (tokenInfo.baseChainSymbol !== tokenInfo.symbol) {
        return setNumberToPrecision(amount, tokenInfo.precision || 8);
      }

      return weiToNum(amount);
    }

    return satoshiToNum(amount);
  }

  getCoinApiBasedOnType(type: string) {
    switch (type) {
      case 'sys':
        return this.getSysApi();
      case 'eth':
        return this.getEthApi();
      case 'btc':
        return this.getBtcApi();
      case 'avax':
        return this.getAvaxApi();
      case 'sol':
        return this.getSolApi();
    }
  }

  public async newNotifier(address: string, type: string) {
    type = type.toLowerCase();
    let notifier;
    const sysApi = await this.getSysApi();

    Logger.info(`Setting up ${type.toUpperCase()} notifier for address ${address}`);

    if (type === 'spt') {
      this.addresses.push(address);

      Logger.warn(`ZDAG Disabled, SPT notifier WONT be configured. Fallback to BB Notifier`);
      this.syscoinWsConfig.configure(sysApi.spt, address);

      notifier = new Notifier({
          address,
          notifier: this.syscoinWs,
          type,
          getConnectedMethod: 'connectedSubject',
          getTxMethod: 'txSubject',
          parser: event => event
        });

      notifier.connectedSubject$.subscribe(connected => this.handleConnectOrDisconnect(connected, 'SPT'));
      notifier.txSubject$.subscribe(this.handleMessage.bind(this));
    } else if (type === 'sys' || type === 'eth' || type === 'btc') {
      const coinApi = await this.getCoinApiBasedOnType(type);
      let bbNotifier;

      try {
        bbNotifier = new BlockbookTxNotifier({
          address,
          url: coinApi.websocket,
          restUrl: coinApi.blockExplorer
        });
        notifier = new Notifier({
          address,
          type,
          notifier: bbNotifier
        });
      } catch (err) {
        return Logger.info(`Failed to create ${type} notifier. Skipping...`);
      }

      notifier.txSubject$.subscribe(this.handleMessage.bind(this));
      notifier.connectedSubject$.subscribe(status => this.handleConnectOrDisconnect(status, type.toUpperCase()));
    } else if (type === 'avax' || type === 'sol') {

        const coinApi = await this.getCoinApiBasedOnType(type);
        if (type === 'avax') {
          this.socketServiceAvax = new SocketService(address, coinApi.websocket);
          // Handle transactions events for custom socket - Avalanche
          this.handleMessagesForCustomSocket(this.socketServiceAvax);
        } else if (type === 'sol') {
          this.socketServiceSol = new SocketService(address, coinApi.websocket);
           // Handle transactions events for custom socket - Solana
          this.handleMessagesForCustomSocket(this.socketServiceSol);
        }
    }

    if (!notifier) {
      return;
    }

    this.notifiers.push(notifier);
  }
  
  handleMessagesForCustomSocket(socketService) {
    socketService.getNewMessage().subscribe((message: any) => { 
      if (message !== ''){
        this.handleMessage(message);
      }     
    });
  }

  private handleMessage(event) {
    if (this.isZdagEvent(event)) {
      return this.handleZdagEvent(event);
    }
    
    if (this.isCustomWebSocket(event)) {
      if (event.confirmed) {
        return this.handleConfirmedWebSocketEvent(event);
      } else {
        return this.handleWebSocketEvent(event);
      }
    }

    if (event.confirmed) {
      this.handleConfirmedMessage(event);
    } else if (event.topic === 'rejected_txs') {
      this.handleRejectedTxsMessage(event);
    } else {
      this.privateUnconfirmedMessage(event);
    }
  }
  
  async handleWebSocketEvent(event) {
    const data = event.tx;
   
    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for toast/store
    const txid = data.txid;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const bbTx = data;
    const walletAddress = event?.address;
    const tokenList = await this.tokenManager.getTokenList();
    let tokenAndTransferInfo = [];
    let txAmounts;
    switch (event.txType) {
      case 'sol':
      case 'spl-sol':
        txAmounts = getTransactionAmountsByAddressSol(bbTx, walletAddress, baseChainSymbol === 'SOL');
        tokenAndTransferInfo = bbTx.tokenTransfers ?
        bbTx.tokenTransfers
          .map(transfer => this.createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
          .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
          .filter(tt => tt.amount) // only keep transfers that actually have value
        : [];
        break;
      case 'avax':
      case 'erc20-avax':
        txAmounts = getTransactionAmountsByAddressAvax(bbTx, walletAddress, baseChainSymbol === 'AVAX');
        tokenAndTransferInfo = bbTx.tokenTransfers ?
        bbTx.tokenTransfers
          .map(transfer => this.createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
          .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
          .filter(tt => tt.amount) // only keep transfers that actually have value
        : [];
        break;
      default:
        Logger.error(`Unhandled type: ${event.txType}`);
        break;
    }

    if (txAmounts !== null) {
      // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
      tokenAndTransferInfo.push({
        precision: this.keychain[baseChainSymbol].precision,
        guid: baseChainSymbol,
        keyringId: createKeyringId(baseChainSymbol),
        amount: txAmounts.totalValue,
        symbol: baseChainSymbol
      });

      if (txAmounts.type === 'receive') {
        // Fire notifications
        this.fireToastBasedOnTokenTransfers(tokenAndTransferInfo, baseChainSymbol);
      }
    }
    const unconfirmedTx: any = {
      txid,
      time: Date.now(),
      tx: bbTx,
      // This is not 100% accurate, but will work in most cases
      // Bigger refactor is required in order to work with new SYS core standards
      tokenInfo: tokenAndTransferInfo[0]
    };

    // Update balances
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    // Add utx to store
    this.store.dispatch(new AddUnconfirmedTransactionAction({ transaction: unconfirmedTx }));
  }
  
  async handleConfirmedWebSocketEvent(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }
    
    const txid = data.txid;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const bbTx = data;
    const walletAddress = event?.address;
    let txAmounts = {};
    const tokenList = await this.tokenManager.getTokenList();
    let assetGuid;
    let tokenAndTransferInfo = [];
    let itemPerPage = 25;
    switch (event.txType) {
      case 'sol':
      case 'spl-sol':
        txAmounts = getTransactionAmountsByAddressSol(bbTx, walletAddress, baseChainSymbol === 'SOL');
        tokenAndTransferInfo = bbTx.tokenTransfers ?
        bbTx.tokenTransfers
          .map(transfer => this.createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
          .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
          .filter(tt => tt.amount) // only keep transfers that actually have value
        : [];
        itemPerPage = environment.SOLANA_HISTORY_LIMIT;
        break;
      case 'avax':
      case 'erc20-avax':
        txAmounts = getTransactionAmountsByAddressAvax(bbTx, walletAddress, baseChainSymbol === 'AVAX');
        tokenAndTransferInfo = bbTx.tokenTransfers ?
        bbTx.tokenTransfers
          .map(transfer => this.createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
          .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
          .filter(tt => tt.amount) // only keep transfers that actually have value
        : [];
        break;
      default:
        console.error('Error type coming from websocket');
        break;
    }

    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol)
    });

    if (tokenAndTransferInfo.length > 1) {
      // tokenAndTransferInfo holds more than just the baseChain transfer
      assetGuid = tokenAndTransferInfo[0].guid;
    }

    // Update balances affected
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    const ethFilterIndex = this.keychain[tokenAndTransferInfo[0].keyringId].ethFilterIndex;

    // Update affected tx history
    this.store.dispatch(new GetTokenHistoryRequestAction({
      sysGuid: assetGuid, // undefined if not erc20
      address: event.address,
      pageNum: 1,
      itemsPerPage: itemPerPage,
      baseChainSymbol,
      ethFilterIndex
    }));
  }

  private async privateUnconfirmedMessage(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for toast/store
    const network = await this.getNetworkBasedOnTxType(event.txType);
    const txid = data.txid;
    const bbTx = await sjs.utils.fetchBackendRawTx(network.URL, txid);
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const walletAddress = this.getNotifierOfType(baseChainSymbol)?.address;
    const txAmounts = getTransactionAmountsByAddress(bbTx, walletAddress, baseChainSymbol === 'ETH');
    const tokenList = await this.tokenManager.getTokenList();
    const tokenAndTransferInfo = bbTx.tokenTransfers ?
      bbTx.tokenTransfers
        .map(transfer => this.createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
        .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
        .filter(tt => tt.amount) // only keep transfers that actually have value
      : [];

    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      precision: this.keychain[baseChainSymbol].precision,
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol),
      amount: txAmounts.type === 'send' ?
        getSendTypeValueFromtxAmount(txAmounts, walletAddress, true)
        : getReceiveTypeValueFromtxAmount(txAmounts, walletAddress, true),
      symbol: baseChainSymbol
    });

    
    if (txAmounts.type === 'receive') {
      // Fire notifications
      this.fireToastBasedOnTokenTransfers(tokenAndTransferInfo, baseChainSymbol);
    }

    const unconfirmedTx: any = {
      txid,
      time: Date.now(),
      tx: bbTx,
      // This is not 100% accurate, but will work in most cases
      // Bigger refactor is required in order to work with new SYS core standards
      tokenInfo: tokenAndTransferInfo[0]
    };

    // Update balances
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    // Add utx to store
    this.store.dispatch(new AddUnconfirmedTransactionAction({ transaction: unconfirmedTx }));
  }
  
  async handleConfirmedMessage(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for store
    const network = await this.getNetworkBasedOnTxType(event.txType);
    const txid = data.txid;
    const bbTx = await sjs.utils.fetchBackendRawTx(network.URL, txid);
    const notifierType = event.notifierType;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(notifierType);
    const walletAddress = this.getNotifierOfType(baseChainSymbol)?.address;
    const txAmounts = getTransactionAmountsByAddress(bbTx, walletAddress, baseChainSymbol === 'ETH');

    const tokenList = await this.tokenManager.getTokenList();
    let assetGuid;

    const tokenAndTransferInfo = bbTx.tokenTransfers ?
      bbTx.tokenTransfers
        .map(transfer => this.createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
        .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
        .filter(tt => tt.amount) // only keep transfers that actually have value
      : [];


    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol)
    });

    if (tokenAndTransferInfo.length > 1) {
      // tokenAndTransferInfo holds more than just the baseChain transfer
      assetGuid = tokenAndTransferInfo[0].guid;
    }

    // Update balances affected
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    const ethFilterIndex = this.keychain[tokenAndTransferInfo[0].keyringId].ethFilterIndex;

    // Update affected tx history
    this.store.dispatch(new GetTokenHistoryRequestAction({
      sysGuid: assetGuid, // undefined if not erc20
      address: event.address,
      pageNum: 1,
      itemsPerPage: 20,
      baseChainSymbol,
      ethFilterIndex
    }));
  }

  getBaseChainSymbolBasedOnTxType(txType) {
    switch (txType.toLowerCase()) {
      case 'erc20':
      case 'eth':
        return 'ETH';
      case 'sys':
      case 'spt':
        return 'SYS';
      case 'btc':
        return 'BTC';
      case 'avax':
      case 'erc20-avax':
        return 'AVAX';
      case 'sol':
      case 'spl-sol':
        return 'SOL';
    }
  }

  isZdagEvent(event) {
    const data = event.message;

    return data?.isZdag || event.zdagTx;
  }
  
  isCustomWebSocket(event) {
    if (!event) return false;
    const data = event.txType.toUpperCase();
    return data === 'AVAX' || data === 'ERC20-AVAX' || data === 'SOL' || data === 'SPL-SOL';
  }

  handleZdagEvent(event) {
    const data = event.message;
    const actionPayload: ZdagTransactionAction = {
      txid: data.tx.txid,
      zdag_confirmed: null,
      zdag_error: null
    };

    setTimeout(() => {
      // Artificially add 1s timeout to this
      // When refetching unconf tx, most of the time this message will come before the one from BB (that adds the utx to the store).
      if (data.status === 0) {
        actionPayload.zdag_confirmed = true;
        this.store.dispatch(new ZdagConfirmedTransactionAction(actionPayload));
      } else if (Number.isInteger(data.status) && data.status !== 0) {
        actionPayload.zdag_confirmed = false;
        actionPayload.zdag_error = true;
        this.store.dispatch(new ZdagErrorTransactionAction(actionPayload));
      }
    }, 10000);
  }

  fireToastBasedOnTokenTransfers(tokenTransfers, baseChainSymbol) {
    let timeToFire = 0;
    tokenTransfers.forEach(tokenTransfer => {
      const toastDuration = 4000;
      setTimeout(async () => {
        const toastMsg = await this.toast.create({
          header: await this.translate.get('new_tx_toast.title').toPromise(),
          message: await this.translate.get('new_tx_toast.message', {
            amount: this.smallToCryptoPipe.transform(tokenTransfer.amount, { type: baseChainSymbol, precision: tokenTransfer.precision }),
            symbol: tokenTransfer.symbol
          }).toPromise(),
          position: getToastPosition(this.platform),
          duration: toastDuration,
          buttons: [{
            text: 'Refresh',
            handler: () => {
              this.events$.next('go_history_top');
              this.store.dispatch(new ClearTxHistory({ keyringId: tokenTransfer.keyringId }));
              this.nav.navigateForward(['/wallet/assets', baseChainSymbol, tokenTransfer.guid]);
            }
          }]
        });
    
        toastMsg.present();
      }, timeToFire);
      timeToFire += toastDuration;
    });
  }

  getNetworkBasedOnTxType(txType) {
    switch (txType) {
      case 'sys':
      case 'spt':
        return this.networkService.getActiveSysNetwork();
      case 'eth':
      case 'erc20':
        return this.networkService.getActiveEthNetwork();
      case 'btc':
        return this.networkService.getActiveBtcNetwork();
      case 'avax':
      case 'erc20-avax':
        return this.networkService.getActiveAvaxNetwork();
      case 'sol':
      case 'spl-sol':
        return this.networkService.getActiveSolNetwork();
    }
  }

  createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {
    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: txAmounts.type === 'send' ?
        getSendTypeValueFromtxAmount(txAmounts, walletAddress, false, transfer.token)
        : getReceiveTypeValueFromtxAmount(txAmounts, walletAddress, false, transfer.token)
    };

    try {
      info.symbol = atob(this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol);
    } catch (err) {
      info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    }
    return info;
  }
  
  createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {

    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: transfer.value
    };

    info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    return info;
  }
  
  createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {

    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: transfer.value
    };

    info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    return info;
  }

  private async handleRejectedTxsMessage(data) {
    Logger.info('handle rejected txs');
    const transactionIds = data.message;
    this.store.dispatch(new RemoveUnconfirmedTransactionsAction({ transactionIds }));
  }

  private handleConnectOrDisconnect(connected, type) {
    Logger.info(`${type} socket status: `, connected);
    this.setServiceStatusInStore(type, connected);

    if (connected === null) {
      return; // notifier is just initing
    }

    let notifiersRunning = 0;
    let waitForNotifiers = false; // wait for all notifiers to report some non-null status
    this.notifiers.forEach(notifier => {
      if (notifier.connectedSubject$.value === null) {
        // all notifiers are not ready yet
        waitForNotifiers = true;
        return;
      }

      if (notifier.connectedSubject$.value === true) {
        notifiersRunning++;
      }
    });

    if (!waitForNotifiers) {
      if (notifiersRunning === this.notifiers.length) {
        this.store.dispatch(new ConnectSuccessAction());
      } else {
        this.store.dispatch(new DisconnectAction());
      }
    }
  }

  public hasNotifierOfType(type: string) {
    type = type.toLowerCase();
    return !!this.notifiers.find(notifier => notifier.type === type);
  }

  public getNotifierOfType(type: string) {
    const lowercaseType = type.toLowerCase();
    return this.notifiers.find(notifier => notifier.type === lowercaseType);
  }

  public getEvents() {
    return this.events$;
  }

  public logNotifiers() {
    Logger.info('Current notifiers: ', this.notifiers);
  }

  clearNotifiers() {
    Logger.info('Cleared notifiers');
    this.notifiers.forEach(notifier => notifier.disconnect());
    this.notifiers = [];
  }
  
  public getWebSocketStatus(type: string) {
    let notifier: Notifier = this.notifiers.find(notifier => notifier.type === type);
    if (notifier){
      notifier.connectedSubject$.subscribe(connected => this.isConnected = connected);
    }
    
    if (type === 'avax') {
      this.isConnected = this.socketServiceStatus( type, this.socketServiceAvax);
    } else if (type === 'sol') {
      this.isConnected = this.socketServiceStatus( type, this.socketServiceSol);
    }

    return this.isConnected;
  }
  
  socketServiceStatus(type, socketService) {
    console.log(`${ type.toUpperCase()} socket status: ${socketService?.socket?.connected}`);
    this.isConnected = socketService?.socket?.connected;
    return this.isConnected;
  }
  
  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services['websocket' + type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
  
}
