import { Action } from '@ngrx/store';
import { ConnectionActionTypes, SetServiceStatusAction } from '../actions/connection.actions';
import { ConnectionState } from '../store/connection';


export const initialConnectionState = {
  connected: false,
  services: {
    sysBB: false,
    ethBB: false,
    btcBB: false,
    gasStation: false,
    vdcServer: false,
    memberPortal: false,
    websocketETH: false,
    websocketSYS: false,
    websocketSPT: false,
    websocketAVAX: false,
    websocketSOL: false,
    bankAccount: false,
    avaxSnowTrace: false,
    avaxRpc: false,
    solRpc: false
  }
};

export function connectionReducer(state: ConnectionState = initialConnectionState, action: Action): ConnectionState {
  let payload;
  switch (action.type) {
    case ConnectionActionTypes.CONNECT_SUCCESS:
      return {  ...state, connected: true };

    case ConnectionActionTypes.DISCONNECT:
      return {  ...state, connected: false };

    case ConnectionActionTypes.SET_SERVICE_STATUS:
      payload = (action as SetServiceStatusAction).payload;
      return { ...state, services: Object.assign({}, state.services, payload)};
    default:
      return state;
  }
};
