import { Action } from '@ngrx/store';

export enum SetupActionTypes {
  SET_PIN = '[setup] Setup PIN',
  CLEAR_PIN = '[setup] Clear PIN',
  COMPLETE_SETUP = '[setup] Complete setup',
  SET_SETUP_MNEMONIC = '[setup] Set setup mnemonic',
  CLEAR_SETUP_MNEMONIC = '[setup] Clear setup mnemonic',
  SET_OLD_PIN = '[setup] Set old pin'
}

export class SetPinAction implements Action {
  readonly type = SetupActionTypes.SET_PIN;
  constructor(readonly payload: { pin: string }) {}
}

export class ClearPinAction implements Action {
  readonly type = SetupActionTypes.CLEAR_PIN;
  constructor() {}
}

export class SetSetupMnemonicAction implements Action {
  readonly type = SetupActionTypes.SET_SETUP_MNEMONIC;
  constructor(readonly payload: { mnemonic: string }) {}
}

export class ClearSetupMnemonicAction implements Action {
  readonly type = SetupActionTypes.CLEAR_SETUP_MNEMONIC;
  constructor() {}
}

export class CompleteSetupAction implements Action {
  readonly type = SetupActionTypes.COMPLETE_SETUP;
  constructor() {}
}

export class SetOldPinAction implements Action {
  readonly type = SetupActionTypes.SET_OLD_PIN;
  constructor(readonly payload: { pin: string }) {}
}
