export const hdPaths = {
  syscoinTestnet: `m/84'/1'/0'/0`,
  syscoinMainnet: `m/84'/57'/0'/0`,
  ethereumMainnet: `m/44'/60'/0'/0`,
  ethereumTestnetRopsten: `m/44'/1'/0'/0`,
  bitcoinTestnet: `m/84'/1'/0'/0'`,
  bitcoinMainnet: `m/84'/0'/0'/0'`,
  solanaTestnet: `m/44'/501'/0'/0'`,
  solanaMainnet: `m/44'/501'/0'/0'`
};

export interface HdKeyringOptions {
  mnemonic?: string;
  nodes?: HdNodeOptions[];
}

export interface HdNodeOptions {
  hdPath?: string;
  numberOfAccounts?: number;
  network?: NetworkDefinition;
}

export interface NetworkDefinition {
  messagePrefix: string;
  bech32: string;
  bip32: NetworkKey;
  pubKeyHash: number;
  scriptHash: number;
  wif: number;
}

export interface NetworkKey {
  public: number;
  private: number;
}

export interface TransactionOutputs {
  [ key: string ]: TransactionOutput;
}

export interface TransactionOutput {
  ValueSat: number;
  value: number;
  n: number;
  scriptPubKey: ScriptPubKey;
}

export interface ScriptPubKey {
  asm?: string;
  hex?: string;
  reqSigs?: number;
  type?: string;
  addresses?: string[];
}

export interface initWalletWithMnemonicOpts {
  sys: boolean;
  eth?: boolean;
  btc?: boolean;
  avax?: boolean;
  sol?: boolean;
}

