import { Injectable } from '@angular/core';
import { MOBILE_MODE_MAX_WIDTH } from '../constants';
import { Logger } from './logger.service';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppModeService {

  public isDesktopMode: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private platform: Platform) {
    this.platform.ready().then(async () => {
      Logger.info('WINDOW', window);
      if (!this.platform.is("cordova") && (window.innerWidth > MOBILE_MODE_MAX_WIDTH)) {
        Logger.info('DESKTOP MODE');
        this.isDesktopMode.next(true);
      } else {
        Logger.info('MOBILE MODE');
        this.isDesktopMode.next(false);
      }
    });
  }

  public handleWindowResize(event) {
    Logger.info('RESIZE:', event.target.innerWidth);
    if (event.target.innerWidth > MOBILE_MODE_MAX_WIDTH && !this.isDesktopMode.value) {
      this.isDesktopMode.next(true);
      Logger.info('DESKTOP MODE');
    } else if (event.target.innerWidth <= MOBILE_MODE_MAX_WIDTH && this.isDesktopMode.value) {
      this.isDesktopMode.next(false);
      Logger.info('MOBILE MODE');
    }
  }
}
