import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getKeychain } from '../lib/pangolin/keyring/keychainUtils';
import { StorageService } from '../services/storage.service';
import { WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants'
import { Logger } from './logger.service';
import { hdPaths, TransactionOutput, TransactionOutputs } from '../lib/pangolin/keyring';
import { setTransactionMemo } from '../utils';
import { HTTP } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';
import { GAS_LIMIT_SEND, GAS_LIMIT_ERC20_SEND, MIN_BTC_FEE } from '../constants';
import { CoinGeckoService } from '../services/coingecko.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { BigNumber } from 'bignumber.js';
import { NetworkService } from './network.service';
import BtcEstimator from 'btc-tx-size-fee-estimator';
import { environment } from 'src/environments/environment';

const sjs = require('syscoinjs-lib')
const bitcoin = (window as any).bitcoinjsLib;
const btcEstimator = new BtcEstimator();

import * as ed25519 from 'ed25519-hd-key';
const bip39 = (window as any).bip39;
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from "@solana/spl-token"

@Injectable()
export class TransactionSenderService {
  private btcFeeAddress = 'https://bitcoiner.live/api/fees/estimates/latest'
  private sendFromUri = 'api/v2/sendfrom';
  private assetAllocationSendUri = 'api/v2/assetallocationsend';
  private sendRawTransactionUri = 'api/v2/sendtx';
  private getRawTransactionUri: string = 'api/getrawtransaction';
  private getAddressUri: string = 'api/v2/address';
  private getFeeEstimateUri: string = 'api/v2/estimatefee'
  private getUtxoUri: string = 'api/v2/utxo'

  constructor(private http: HttpClient,
              private storage: StorageService,
              public httpPlugin: HTTP,
              private coinGecko: CoinGeckoService,
              private platform: Platform,
              private store: Store<AppState>,
              private networkService: NetworkService) {
  }

  protected async getRawTransaction(txid: string) {
    const network = await this.networkService.getActiveSysNetwork();
    let params = new HttpParams();
    params = params.append('txid', txid);
    params = params.append('verbose', '1');

    return this.http.get(`${network.URL}/${this.getRawTransactionUri}`, { params }).toPromise();
  }

  public async appendTxFeeToRawTx(rawtx) {
    const newTx: any = bitcoin.Transaction.fromHex(rawtx.tx.hex);
    const prevOuts: TransactionOutput[] = rawtx.prevVouts;

    const prevOutsValue = prevOuts[0].ValueSat;
    const outputsValue = newTx.outs.reduce((prev, next) => ({ value: prev.value + next.value }), { value: 0 }).value;

    Logger.info('Previous outputs: ', prevOuts);
    Logger.info('New tx outputs: ', outputsValue);

    rawtx.fee = prevOutsValue - outputsValue;
  }

  public async calcTxFeeFetchingPrevOuts(tx: any) {
    // For usage outside send component
    const currentTxid: string = tx.txid;
    const currentTx: any = await this.getRawTransaction(currentTxid);
    const currentVins: any[] = currentTx.vin;

    let prevOutsValue: any = await Promise.all(
      currentVins.map(async (input: any) => {
        const prevTx: any = await this.getRawTransaction(input.txid);
        return prevTx.vout[input.vout].value;
      })
    );
    prevOutsValue = prevOutsValue.reduce((prev, next) => prev + next, 0);
    const currentOutsValue: number = currentTx.vout.map((out: any) => out.value).reduce((prev, next) => prev + next, 0);

    // SYS fee = inputs values - outputs value.
    return prevOutsValue - currentOutsValue;
  }

  async getUnsignedTransactionHex(fromAddress, toAddress, amount, guid?) {
    let response;
    let params = new HttpParams();
    const network = await this.networkService.getActiveSysNetwork()


    if (guid) { // asset allocation send
      params = params.append('from', fromAddress);
      params = params.append('to', toAddress);
      params = params.append('amount', amount);

      response = await this.http.get(`${network.URL}/${this.assetAllocationSendUri}/${guid}`, { params }).toPromise();

      try {
        await this.appendTxFeeToRawTx(response);
      } catch (err) {
        Logger.info(err);
      }
    } else { // regular sys send
      params = params.append('to', toAddress);
      params = params.append('amount', amount);
      response = await this.http.get(`${network.URL}/${this.sendFromUri}/${fromAddress}`, { params }).toPromise();
    }

    return response;

  }

  async getFeeEstimate() {
    const network = await this.networkService.getActiveBtcNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.getFeeEstimateUri}/6`).toPromise();
    return response.result;
  }

  async sendRawTransaction(hexStr) {
    const network = await this.networkService.getActiveBtcNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.sendRawTransactionUri}/${hexStr}`).toPromise();
    return response;
  }

  async signRawTransaction(fromAddress, hexStr, password, outputs, memo?): Promise<string> {
    const tx = bitcoin.Transaction.fromHex(hexStr);

    setTransactionMemo(tx, memo);

    Logger.info('tx', tx);
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getKeychain(vault, password);
    const signedTx = keychain.signTransaction(fromAddress, tx, (outputs as TransactionOutputs));
    const signTxHex = signedTx.toHex();

    Logger.info('signed hex:', signTxHex);

    return signTxHex;
  }

  // Avax methods
  async getAvaxNonce(address) {
    let nextNonce = 0;
    const network = await this.networkService.getActiveAvaxNetwork();
    try {
      const txCount: any = await this.http.get(`${network.API_URL}/api?module=proxy&action=eth_getTransactionCount&address=${address}&tag=latest&apikey=${environment.SNOWTRACE_API_KEY}`).toPromise();
      nextNonce = txCount['result']++;
    } catch (err) {
      Logger.info(err);
    }
    return nextNonce;
  }
  
  async getGasPrice() {
    const network = await this.networkService.getActiveAvaxNetwork();
    const response: any = await this.http.get(`${network.API_URL}/api?module=proxy&action=eth_gasPrice&apikey=${environment.SNOWTRACE_API_KEY}`).toPromise();
    return response['result'];
  }
  
  async sendAvaxRawTransaction(hexStr) {
    const network = await this.networkService.getActiveAvaxNetwork();
    const response: any = await this.http.get(`${network.API_URL}/api?module=proxy&action=eth_sendRawTransaction&hex=${hexStr}&apikey=${environment.SNOWTRACE_API_KEY}`).toPromise();
    if (response && response['error']) {
      throw response;
    }
    return response;
  }
  async signAvaxRawTransaction(fromAddress, tx, password): Promise<string> {    
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getKeychain(vault, password);
    Logger.info('pre-signing hex:', tx);
    const signedTx = keychain.signTransaction(fromAddress, tx, null);

    Logger.info('signedTx:', signedTx);

    return signedTx;
  }
  
  async getAvaxGasPrice() {
    const networkData = await this.networkService.getActiveAvaxNetwork();
    let data: any;
    if (typeof networkData.GAS_PRICE === 'object') {
      data = networkData.GAS_PRICE;
    } else {
      data = await this.http.get(networkData.GAS_PRICE).toPromise();
    }

    const gasPrices: any = {
      slowBase:0,
      standardBase: 0,
      fastBase:0
    };

    // Adding second call to snow trace gas price in case of failure
    if(data.error_msg) {
        var resp = await this.getGasPrice();
        gasPrices.slowBase = Number(resp);
        gasPrices.standardBase=  Number(resp);
        gasPrices.fastBase=  Number(resp);
    } else {
        gasPrices.slowBase = Number(data.data.slow.price);
        gasPrices.standardBase=  Number(data.data.normal.price);
        gasPrices.fastBase=  Number(data.data.fast.price);
    }
   
    // AVAX
    gasPrices.slowGweiPrice = new BigNumber(gasPrices.slowBase);
     gasPrices.standardGweiPrice = new BigNumber(gasPrices.standardBase);
     gasPrices.fastGweiPrice = new BigNumber(gasPrices.fastBase);
     gasPrices.slowAvaxPrice = new BigNumber(gasPrices.slowBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
     gasPrices.standardAvaxPrice =new BigNumber(gasPrices.standardBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
     gasPrices.fastAvaxPrice = new BigNumber(gasPrices.fastBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
     gasPrices.slowWeiBase = new BigNumber(gasPrices.slowBase);
     gasPrices.standardWeiBase = new BigNumber(gasPrices.standardBase);
     gasPrices.fastWeiBase = new BigNumber(gasPrices.fastBase);

    // ERC20
    gasPrices.slowGweiPriceErc20 = new BigNumber(gasPrices.slowBase);
    gasPrices.standardGweiPriceErc20 = new BigNumber(gasPrices.standardBase);
    gasPrices.fastGweiPriceErc20 = new BigNumber(gasPrices.fastBase);
    gasPrices.slowAvaxPriceErc20 = new BigNumber(gasPrices.slowBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.standardAvaxPriceErc20 = new BigNumber(gasPrices.standardBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.fastAvaxPriceErc20 =new BigNumber(gasPrices.fastBase).dividedBy(new BigNumber(Math.pow(10, 9))).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.slowWeiBaseErc20 = new BigNumber(gasPrices.slowBase);
    gasPrices.standardWeiBaseErc20 = new BigNumber(gasPrices.standardBase);
    gasPrices.fastWeiBaseErc20 = new BigNumber(gasPrices.fastBase);

    return gasPrices;
  }
  
  // Ethereum methods
  async getEthNonce(address) {
    const network = await this.networkService.getActiveEthNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.getAddressUri}/${address}?details=basic`).toPromise();
    // tslint:disable-next-line
    return response['nonce'];
  }
  async sendEthRawTransaction(hexStr) {
    const network = await this.networkService.getActiveEthNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.sendRawTransactionUri}/${hexStr}`).toPromise();
    return response;
  }
  async signEthRawTransaction(fromAddress, tx, password): Promise<string> {

    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getKeychain(vault, password);
    Logger.info('pre-signing hex:', tx);
    const signedTx = keychain.signTransaction(fromAddress, tx, null);

    Logger.info('signedTx:', signedTx);

    return signedTx;
  }
  
  async getEthGasPrice() {
    const networkData = await this.networkService.getActiveEthNetwork();
    let data: any;

    if (typeof networkData.GAS_PRICE === 'object') {
      data = networkData.GAS_PRICE;
    } else {
      data = await this.http.get(networkData.GAS_PRICE).toPromise();
    }
    const gasPrices: any = {
      slowBase: Number(data.currentBaseFee),
      standardBase: Number(data.recommendedBaseFee),
      fastBase: (Number(data.recommendedBaseFee) * Number(data.fast)).toFixed(2)
    };

    // ETH
    gasPrices.slowGweiPrice = new BigNumber(gasPrices.slowBase);
    gasPrices.standardGweiPrice = new BigNumber(gasPrices.standardBase);
    gasPrices.fastGweiPrice = new BigNumber(gasPrices.fastBase);
    gasPrices.slowEthPrice = new BigNumber(gasPrices.slowBase).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.standardEthPrice = new BigNumber(gasPrices.standardBase).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.fastEthPrice = new BigNumber(gasPrices.fastBase).multipliedBy(GAS_LIMIT_SEND).dividedBy(1000000000);
    gasPrices.slowWeiBase = new BigNumber(gasPrices.slowBase).multipliedBy(1000000000);
    gasPrices.standardWeiBase = new BigNumber(gasPrices.standardBase).multipliedBy(1000000000);
    gasPrices.fastWeiBase = new BigNumber(gasPrices.fastBase).multipliedBy(1000000000);

    // ERC20
    gasPrices.slowGweiPriceErc20 = new BigNumber(gasPrices.slowBase);
    gasPrices.standardGweiPriceErc20 = new BigNumber(gasPrices.standardBase);
    gasPrices.fastGweiPriceErc20 = new BigNumber(gasPrices.fastBase);
    gasPrices.slowEthPriceErc20 = new BigNumber(gasPrices.slowBase).multipliedBy(GAS_LIMIT_ERC20_SEND).dividedBy(1000000000);
    gasPrices.standardEthPriceErc20 = new BigNumber(gasPrices.standardBase).multipliedBy(GAS_LIMIT_ERC20_SEND).dividedBy(1000000000);
    gasPrices.fastEthPriceErc20 = new BigNumber(gasPrices.fastBase).multipliedBy(GAS_LIMIT_ERC20_SEND).dividedBy(1000000000);
    gasPrices.slowWeiBaseErc20 = new BigNumber(gasPrices.slowBase).multipliedBy(1000000000);
    gasPrices.standardWeiBaseErc20 = new BigNumber(gasPrices.standardBase).multipliedBy(1000000000);
    gasPrices.fastWeiBaseErc20 = new BigNumber(gasPrices.fastBase).multipliedBy(1000000000);

    return gasPrices;
  }

  async getSignAndSendKit(coin: string, pin?: string) {
    let vault, keychain, mnemonic, HDSigner, wif;
    const activeSysNetwork = coin === 'BTC' ?
      await this.networkService.getActiveBtcNetwork() : await this.networkService.getActiveSysNetwork();
    const backendURL = activeSysNetwork.URL;
    const isTestnet = activeSysNetwork.network === 'testnet';
    const networks = (coin === 'BTC') ? sjs.utils.bitcoinNetworks : sjs.utils.syscoinNetworks;
    const SLIP44 = (coin === 'BTC') ? sjs.utils.bitcoinSLIP44 : sjs.utils.syscoinSLIP44;

    const network = networks[isTestnet ? 'testnet' : 'mainnet'];

    if (pin) {
      vault = await this.storage.get(WALLET_STORAGE_KEY);
      keychain = await getKeychain(vault, pin);
      mnemonic = keychain.keyrings[0].mnemonic;
      HDSigner = new sjs.utils.HDSigner(mnemonic, null, isTestnet, networks, SLIP44);
      try {
        wif = keychain.keyrings[0].nodes.find(node => coin === 'BTC' ? node.isBtc() : node.isSys()).keypairs[0].toWIF();
      } catch (err) {
        Logger.error('could not get WIF', err);
      }
    }
  
    const syscoinjs = new sjs.SyscoinJSLib(HDSigner || null, backendURL, network);

    return {
      hdSigner: HDSigner,
      syscoinjs,
      sjs,
      backendURL,
      wif
    };
  }

  async sendSysBtccoin(amount, fromAddress, toAddress, txtmemo, password, coin) {
    if (!fromAddress || !toAddress || !amount) {
      throw new Error('sendSysBtccoin: Missing required param');
    }
    const sys = await this.getSignAndSendKit(coin, password);

    let psbt = await this.buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txtmemo);
    psbt = await sys.syscoinjs.signAndSendWithWIF(psbt.psbt, sys.wif);

    if (!psbt) {
      Logger.error('Could not create transaction, not enough funds?');
      return;
    }

    return psbt.extractTransaction().getId();
  }

  buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txtmemo) {
    const feeRate = new sjs.utils.BN(10);
    const txOpts: any = { rbf: false };
    if (txtmemo) {
      const memo = Buffer.from(txtmemo);
      const memoHeader = Buffer.from([0xfe, 0xfe, 0xaf, 0xaf, 0xaf, 0xaf]);
      txOpts.memoHeader = memoHeader;
      txOpts.memo = memo;
    }
    // if SYS need change sent, set this address. null to let HDSigner find a new address for you
    const sysChangeAddress = fromAddress;
    const satoshiAmount = Math.round(100000000 * amount);
    const outputsArr = [
      { address: toAddress, value: new sjs.utils.BN(satoshiAmount) }
    ];
    return sys.syscoinjs.createTransaction(txOpts, sysChangeAddress, outputsArr, feeRate, sysChangeAddress);
  }

  async estimateFeeBasedOnAmount(amount, fromAddress, toAddress, txmemo = '', coin = 'BTC') {
    const sys = await this.getSignAndSendKit(coin);
    let psbt = await this.buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txmemo);

    psbt = psbt.psbt;

    return this.estimateFee(psbt.data.inputs.length, psbt.data.outputs.length, 10);
  }

  estimateFee(inputCount, outputCount, feeRate) {
    const weight = btcEstimator.calcTxSize({
      input_count: inputCount,
      p2pkh_output_count: outputCount,
      input_m: inputCount
    });
    return btcEstimator.formatFeeRange(weight.txVBytes * feeRate, 0.1);
  }


  async sendAsset(amount, fromAddress, toAddress, password, guid, txtmemo) {
    if (!fromAddress || !toAddress || !amount) {
      throw new Error('sendSysBtccoin: Missing required param');
    }

    const sys = await this.getSignAndSendKit('sys', password);

    const feeRate = new sjs.utils.BN(10);
    const txOpts: any = { rbf: false };
    if (txtmemo) {
      const memo = Buffer.from(txtmemo);
      const memoHeader = Buffer.from([0xfe, 0xfe, 0xaf, 0xaf, 0xaf, 0xaf])
      txOpts.memoHeader = memoHeader;
      txOpts.memo = memo;
    }
    const assetChangeAddress = fromAddress;
    const assetguid = guid;
    const satoshiAmount = Math.round(100000000 * amount);
    const assetMap = new Map([
      [assetguid, { changeAddress: assetChangeAddress, outputs: [{ value: new sjs.utils.BN(satoshiAmount), address: toAddress }] }]
    ]);
    let psbt = await sys.syscoinjs.assetAllocationSend(txOpts, assetMap, assetChangeAddress, feeRate, assetChangeAddress);
    psbt = await sys.syscoinjs.signAndSendWithWIF(psbt.psbt, sys.wif, psbt.assets);
    if (!psbt) {
      Logger.info('Could not create transaction, not enough funds?');
      return;
    }

    return psbt.extractTransaction().getId();
  }

  async calcAuxFees(assetGuid, amount) {
    const activeSysNetwork = await this.networkService.getActiveSysNetwork();
    const backendURL = activeSysNetwork.URL;
    const res = await sjs.utils.fetchBackendAsset(backendURL, assetGuid);
    let value = 100000000 * amount;
    let auxfee = 0;
    if (res.auxFeeDetails && res.auxFeeDetails.auxFees) {
      res.auxFeeDetails.auxFees[0].bound = 0;
      const feeEntry = res.auxFeeDetails.auxFees.filter(fee => fee.bound <= value);
      feeEntry.reverse();
      feeEntry.forEach((fe) => {
        if (fe.bound > value){
          auxfee += Number(((value/100000000 * fe.percent/100000)).toFixed(8));
        }else{
          let tierAmount = value - fe.bound;
          value = fe.bound;
          auxfee += Number(((tierAmount/100000000 * fe.percent/100000)).toFixed(8));
        }
      })
    }
    return auxfee;
  }
  
  async sendSolSplTransaction(amount, fromAddress, toAddress, password, guid, txtmemo) {   
    try {
      const vault = await this.storage.get(WALLET_STORAGE_KEY);
      const keychain = await getKeychain(vault, password);

      const seed: Buffer = await bip39.mnemonicToSeed(keychain.keyrings[0].mnemonic);
      const derivedSeed = ed25519.derivePath(hdPaths.solanaMainnet, seed.toString("hex")).key;
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed);
          
      // Connect to cluster
      const rpcUrl = await this.networkService.getActiveSolNetwork();
      const connection = new solanaWeb3.Connection(rpcUrl.URL, 'confirmed');
      console.log('Sending across cluster:', rpcUrl.URL);
      const fromWallet = keypair;
      const toPublicKey = new solanaWeb3.PublicKey(toAddress)
      
      // Construct my token class
      var myMint = new solanaWeb3.PublicKey(guid);
      var myToken = new splToken.Token(
        connection,
        myMint,
        splToken.TOKEN_PROGRAM_ID,
        fromWallet
      );
      // Create associated token accounts for my token if they don't exist yet
      var fromTokenAccount = await myToken.getOrCreateAssociatedAccountInfo(
        fromWallet.publicKey
      )
      var toTokenAccount = await myToken.getOrCreateAssociatedAccountInfo(
        toPublicKey
      )
      // Add token transfer instructions to transaction
      var transaction = new solanaWeb3.Transaction()
        .add(
          splToken.Token.createTransferInstruction(
            splToken.TOKEN_PROGRAM_ID,
            fromTokenAccount.address,
            toTokenAccount.address,
            fromWallet.publicKey,
            [],
            solanaWeb3.LAMPORTS_PER_SOL * amount,
          )
        );
      // Sign transaction, broadcast, and confirm
      var signature = await solanaWeb3.sendAndConfirmTransaction(
        connection,
        transaction,
        [fromWallet]
      );

      Logger.info('signedTx:', signature);

      return signature;
    } catch (error) {
      throw error;
    }
  }
  
  async sendSolTransaction(amount, fromAddress, toAddress, txtmemo, password, coin) {
    
    try {
      const vault = await this.storage.get(WALLET_STORAGE_KEY);
      const keychain = await getKeychain(vault, password);

      const seed: Buffer = await bip39.mnemonicToSeed(keychain.keyrings[0].mnemonic);
      const derivedSeed = ed25519.derivePath(hdPaths.solanaMainnet, seed.toString("hex")).key;
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed);
          
      // Connect to cluster
      const rpcUrl = await this.networkService.getActiveSolNetwork();
      const connection = new solanaWeb3.Connection(rpcUrl.URL, 'confirmed');
      console.log('Sending across cluster:', rpcUrl.URL);
      const fromWallet = keypair;
      const toPublicKey = new solanaWeb3.PublicKey(toAddress);
      
      // Creating transaction
      const transaction = new solanaWeb3.Transaction().add(
        solanaWeb3.SystemProgram.transfer({
          fromPubkey: fromWallet.publicKey,
          toPubkey: toPublicKey,
          lamports: solanaWeb3.LAMPORTS_PER_SOL * amount,
        }),
      );

      // Sign transaction, broadcast, and confirm
      const signature = await solanaWeb3.sendAndConfirmTransaction(
        connection,
        transaction,
        [fromWallet],
      );
      Logger.info('signedTx:', signature);

      return signature;
    } catch (error) {
      throw error;
    }
  }

}
