// this weirdness is because we are in the BROWSER
import { Logger } from '../../../services/logger.service';
import { HdKeyringOptions } from './';
import HdNode from './hd.node';

/**
 * HD Keyring to manage a child HD nodes
 * Creates a root HD node from bip39 seed
 * from which child nodes ('accounts') are derived
 */
export default class HdKeyring {

  public mnemonic: string;
  public nodes: HdNode[]  = [];

  static async createKeyring(opts: HdKeyringOptions) {
    const keyring = new HdKeyring();
    await keyring.deserialize(opts);

    return keyring;
  }

  static async restoreKeyring(opts: HdKeyringOptions) {
    const keyring = new HdKeyring();
    await keyring.deserialize(opts);

    return keyring;
  }

  constructor() {
  }

  /**
   * Returns a representation of the keyring suitable for serialization
   */
  serialize() {
    return {
      mnemonic: this.mnemonic,
      nodes: this.nodes.map(node => node.serialize())
    };
  }

  /**
   * Restores a keyring from a serialized object
   * @param serialized - the serialized keyring
   */
  async deserialize(serialized: HdKeyringOptions): Promise<void> {
    this.mnemonic = serialized.mnemonic;
    Logger.info('Mnemonic:', this.mnemonic);

    if (!serialized.nodes) {
      // Old wallet format, caught by wallet service ahead for migration.
      return;
    }
    let nodes: any = serialized.nodes
    await nodes.forEachAsync(async node => await this.addNode(node));
  }

  /**
   * Adds N number of accounts to the keyring
   * @param numberOfAccounts - the number of accounts to add
   */
  async addNode(node) {
    this.nodes.push(await HdNode.createNode(node, this));
  }

  getNodeFor(address: string): HdNode {
    for (const node of this.nodes) {
      if (node.containsAddress(address)) {
        return node;
      }
    }

    return null;
  }
}

