import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from './logger.service';
import { TokenManagerService } from './token-manager.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { NetworkService } from './network.service';

@Injectable()
export class TokenDefinitionService {

  static tokenDefinitions: any;
  static getTokenDefinition(keyringId) {
    return TokenDefinitionService.tokenDefinitions[keyringId] ?
      TokenDefinitionService.tokenDefinitions[keyringId] : TokenDefinitionService.tokenDefinitions['UNKNOWN'];
  }

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private networkService: NetworkService,
              private tokenManager: TokenManagerService) {
    this.mergeTokenDefinitions();
  }

  async getTokenDefinitions() {
    const network = await this.networkService.getActiveSysNetwork();
    const defs = await this.http.get(network.TOKEN_DEFINITIONS).toPromise();
    Logger.info('remote defs', defs);
    return defs;
  }

  async mergeTokenDefinitions() {
    const builtInDefinitions = await this.tokenManager.getTokenList();
    const remoteDefinitions = await this.getTokenDefinitions();
    const mergedDefinitons = { ...builtInDefinitions };


    Object.keys(builtInDefinitions).forEach(key => {
      // go through all the subobjects we have in the built ins and extend them with any info from the remote list
      if (remoteDefinitions[key]) {
        mergedDefinitons[key] = { ...builtInDefinitions[key], ...remoteDefinitions[key], baseChainSymbol: 'SYS' };
      }
    });

    // go through all the remote definitions and add any not already in builtins
    Object.keys(remoteDefinitions).forEach(key => {
      if (!builtInDefinitions[key]) {
        mergedDefinitons[key] = { ...remoteDefinitions[key], baseChainSymbol: 'SYS' };
      }
    });

    TokenDefinitionService.tokenDefinitions = mergedDefinitons;
    Logger.info('merged defs:', TokenDefinitionService.tokenDefinitions);
  }
}
