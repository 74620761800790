import { walletReducer } from './wallet.reducer';
import { setupReducer } from './setup.reducer';
import { userPreferencesReducer } from './userPreferences.reducer';
import { sendTokenReducer } from './sendToken.reducer';
import { connectionReducer } from './connection.reducer';
import { gasStationReducer } from './gasStation.reducer';
import { priceOracleReducer } from './priceOracle.reducer';
import { virtualDebitCardReducer} from './virtualDebitCard.reducer';
import { devicesListReducer } from './devicesList.reducer';
import { appSettingsReducer } from './appSettings.reducer';
import { bankAccountsReducer} from './bankAccounts.reducer';

export const rootReducer = {
  wallet: walletReducer,
  setup: setupReducer,
  userPreferences: userPreferencesReducer,
  sendToken: sendTokenReducer,
  connection: connectionReducer,
  gasStation: gasStationReducer,
  priceOracle: priceOracleReducer,
  virtualDebitCard: virtualDebitCardReducer,
  devicesList: devicesListReducer,
  appSettings: appSettingsReducer,
  bankAccount: bankAccountsReducer
};
