import { Action } from '@ngrx/store';
import { Credentials, UserPreferences } from '../../global';

export enum UserPreferencesActionTypes {
  SET_BASE_GWEI = '[userpref] Set base gwei',
  SET_BASE_GAS = '[userpref] Set base gas',
  SET_BASE_CURRENCY = '[userpref] Set base currency',
  SET_USER_DATA = '[userpref] Set user data from lode',
  LOAD_USER_PREFERENCES = '[userpref] Load user prefs',
  SET_USER_PREFERENCES = '[userpref] Set user prefs',
  UPDATE_JWT_TOKEN = '[userpref] Update jwt token',
  REMOVE_LODE_ACC = '[userpref] Remove LODE account',
  LOGIN_USING_STORED_CREDENTIALS = '[userpref] Login using stored credentials',
  SWITCH_NETWORK = '[userpref] Switch network'
}

export class SetBaseGweiAction implements Action {
  readonly type = UserPreferencesActionTypes.SET_BASE_GWEI;
  constructor(readonly payload: { baseGwei : number }) {}
}

export class SetBaseGasAction implements Action {
  readonly type = UserPreferencesActionTypes.SET_BASE_GAS;
  constructor(readonly payload: { baseGas: number }) {}
}

export class SetBaseCurrencyAction implements Action {
  readonly type = UserPreferencesActionTypes.SET_BASE_CURRENCY;
  constructor(readonly payload: { baseCurrency: string }) {}
}

export class SetUserDataAction implements Action {
  readonly type = UserPreferencesActionTypes.SET_USER_DATA;
  constructor(readonly payload: { pin: string, jwtToken: string, credentials: Credentials, lodeid: number }) {}
}

export class UpdateJwtAction implements Action {
  readonly type = UserPreferencesActionTypes.UPDATE_JWT_TOKEN;
  constructor(readonly payload: { jwtToken: string, lodeid: number }) {}
}

export class LoadUserPreferencesAction implements Action {
  readonly type = UserPreferencesActionTypes.LOAD_USER_PREFERENCES;
}

export class SetUserPreferencesAction implements Action {
  readonly type = UserPreferencesActionTypes.SET_USER_PREFERENCES;
  constructor(readonly payload: { preferences: UserPreferences }) {}
}

export class RemoveLodeAccount implements Action {
  readonly type = UserPreferencesActionTypes.REMOVE_LODE_ACC;
  constructor() {}
}

export class SwitchNetworkAction implements Action {
  readonly type = UserPreferencesActionTypes.SWITCH_NETWORK;
  constructor(readonly payload: { chain: string, networkId: number|string, network: string, pin: string }) {}
}
