import { Injectable } from '@angular/core';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  constructor() {}
  notTranslated(key: string) {
    Logger.warn('Not translated', key);
  }
}
