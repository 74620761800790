// this weirdness is because we are in the BROWSER
import { Logger } from '../../../services/logger.service';
import * as _ from 'lodash';
import { HdNodeOptions, hdPaths, TransactionOutput, TransactionOutputs } from './';
import networks from '../config/network.config';
import { Wallet, ethers } from 'ethers';
import HdKeyring from './hd.keyring';
import { ETHERSCAN_API_KEY } from '../../../constants';
import * as ed25519 from 'ed25519-hd-key';
import { environment } from '../../../../../environments/environment';

const bitcoin = (window as any).bitcoinjsLib;
const bip84 = (window as any).bip84;
const bip39 = (window as any).bip39;
// const solanaWeb3 = (window as any).solanaWeb3;
import * as solanaWeb3 from '@solana/web3.js';

/**
 * HD Keyring to manage a child HD nodes
 * Creates a root HD node from bip39 seed
 * from which child nodes ('accounts') are derived
 */
export default class HdNode {
  public keypairs: any[]  = [];
  public network: any; // used only for Bitcoin-variants
  public provider: any; // used only for Ethereum
  public hdPath: string;
  public rootNode: any;
  private masterNode: any;

  static async createNode(opts: HdNodeOptions, parentKeyring) {
    const node = new HdNode(parentKeyring);
    await node.deserialize(opts);

    return node;
  }

  // return the p2wpkh address for an HD node
  private accountToAddressMapper = (keypair: any): string => {
    if (this.isSys() || this.isBtc()) {
      return bitcoin.payments.p2wpkh({
        pubkey: keypair.publicKey,
        network: this.network
      }).address;
    } else if (this.isEth() || this.isSol()) {
      return keypair.address;
    }
  }

  constructor(public parentKeyring: HdKeyring) {
  }

  /**
   * Returns a representation of the keyring suitable for serialization
   */
  serialize() {
    return {
      numberOfAccounts: this.keypairs.length,
      hdPath: this.hdPath,
      network: this.network
    };
  }

  /**
   * We need to get the object reference for the networks module as opposed
   * to using the serialized network obj as bitcoinjs-lib will throw an error
   * due to an object reference equality check.
   */
  getNetwork(serialized) {
    if (this.isEth() ||  this.isSol()) {
      return null;
    }

    if (!serialized.network) {
      throw new Error('Empty network/provider')
    }

    return serialized.network;
  }

  /**
   * Restores a keyring from a serialized object
   * @param serialized - the serialized keyring
   */
  async deserialize(serialized: HdNodeOptions = {}): Promise<void> {
    this.keypairs = [];
    this.hdPath = serialized.hdPath;
    this.network = this.getNetwork(serialized);
    this.initFromMnemonic(serialized);

    if (serialized.numberOfAccounts) {
      this.addKeypair(serialized.numberOfAccounts);
    }
  }

  /**
   * Adds N number of accounts to the keyring
   * @param numberOfAccounts - the number of accounts to add
   */
  async addKeypair(numberOfAccounts = 1) {
    const oldLen = this.keypairs.length;
    const newAccounts = [];
    for (let i = oldLen; i < numberOfAccounts + oldLen; i++) {
      let child, childKeypair;
      if (this.isSys() || this.isBtc()) {
        child = new bip84.fromZPrv(this.rootNode, networks, undefined, this.isTestnetBech32(this.network.bech32));
        childKeypair = child.getKeypair(i, false);
      } else if (this.isEth()) {
        childKeypair = Wallet.fromMnemonic(this.parentKeyring.mnemonic, `${this.hdPath}/${i}`);
      } else if (this.isSol()) {
        const seed = bip39.mnemonicToSeedSync(this.parentKeyring.mnemonic);
        const derivedSeed = ed25519.derivePath(`${this.hdPath}`, seed.toString("hex")).key;
        childKeypair = solanaWeb3.Keypair.fromSeed(derivedSeed);
        childKeypair['address'] = childKeypair.publicKey.toString();
      }

      Logger.info('child', child, this.accountToAddressMapper(childKeypair));
      newAccounts.push(childKeypair);
      this.keypairs.push(childKeypair);
    }
    return newAccounts.map(this.accountToAddressMapper);
  }

  isTestnetBech32(bech32) {
    return ['tb', 'tsys'].indexOf(bech32) !== -1;
  }

  /**
   * Returns an array of addresses for this keyring
   */
  getAllAccountAddresses(): string[] {
    return this.keypairs.map(this.accountToAddressMapper);
  }

  /**
   * Signs a transaction with the private key associated with an address
   * @param address - address to sign transaction with
   * @param transaction - instance of bitcoin.Transaction
   * @param outputs - an array of TransactionOutput to determine how to map signing per-input
   *
   * returns a bitcoin.Transaction instance
   */
  signTransaction(address: string, transaction: any, outputs: TransactionOutputs) {
    const keypair = this.getAccountForAddress(address);
    if (this.isSys() || this.isBtc()) {
      Logger.info('signing keypair:', keypair, transaction.toHex(), outputs);

      const txBuilder = bitcoin.TransactionBuilder.fromTransaction(transaction);

      if (!keypair) {
        throw new Error('Address not found in keyring');
      }

      // sign transaction inputs with account
      const {ins} = txBuilder.__TX;
      txBuilder.network = keypair.network; // TODO: review!

      Logger.info('privkey:', this.keypairs[0].toWIF(), ins);

      ins.forEach((input, idx) => {
        const out: TransactionOutput = outputs[idx];
        if (out.scriptPubKey.addresses.length === 1 && out.scriptPubKey.addresses[0].indexOf('sys1') > -1) {
          const outValue = out.ValueSat;
          Logger.info('witness signing with value of ', outValue, 'from', out.value, ' / ', out.ValueSat);
          txBuilder.sign(idx, keypair, null, null, outValue);
        } else if (out.scriptPubKey.addresses.length > 1) {
          throw new Error('ScriptPubKey has too many addresses!' + JSON.stringify(out));
        } else {
          txBuilder.sign(idx, keypair);
        }
      });
      return txBuilder.build();
    } else if (this.isEth()) {
      // Ethereum Signing
      if (!keypair) {
        throw new Error('Address not found in keyring');
      }

      // tslint:disable-next-line
      const wallet = new ethers.Wallet(keypair['privateKey']);
      return wallet.signTransaction(transaction);
    }
  }


  containsAddress(address: string): boolean {
    return !!this.getAccountForAddress(address);
  }

  getAccountForAddress(address: string) {
    return this.keypairs.find((account: any) => {
      if (this.isSys()) {
        const a = this.accountToAddressMapper(account);
        return a === address;
      } else {
        // DO ETH STUFF!
        // TODO: Temporarily do the same as isSYS, since accountToAddressMappper looks like it resolves SYS/ETH already
        const a = this.accountToAddressMapper(account);
        return a.toLowerCase() === address.toLowerCase();
      }
    });
  }

  isEth(): boolean {
    return this.hdPath.indexOf('m/44\'/60\'') === 0 || this.hdPath.indexOf('m/44\'/1\'') === 0;
  }

  isSys(): boolean {
    return this.network.bech32 === 'sys' || this.network.bech32 === 'tsys';
  }

  isBtc(): boolean {
    return this.network.bech32 === 'bc' || this.network.bech32 === 'tb';
  }
  
  isSol(): boolean {
    return this.hdPath.indexOf('m/44\'/501\'') === 0;
  }
  
  isTestnet(): boolean {
    return !environment.production;
  }

  initFromMnemonic(opts: HdNodeOptions) {
    if (!this.isEth() && !this.isSol()) {
      this.masterNode = new bip84.fromSeed(
        this.parentKeyring.mnemonic, opts.network, this.getCoinType(this.hdPath), undefined, this.isTestnetBech32(this.network.bech32)
      );
      this.rootNode = this.masterNode.deriveAccount(0);
    } else {
      // we do not init any eth wallets here yet, that happens in addNewAccount

      if(this.isSol()) {
        // TODO: If we found other way we need to change it
        const rpcUrl = this.isTestnet() ? 'https://api.testnet.solana.com' : 'https://api.mainnet-beta.solana.com';
        let connection = new solanaWeb3.Connection(rpcUrl, 'confirmed');
        console.log('Connection to cluster established:', rpcUrl);
        this.provider = connection;
        this.network = {
          bech32: 'placeholder'
        };
      } else {
        // connect to eth network
        this.provider = new ethers.providers.EtherscanProvider(
          this.hdPath === hdPaths.ethereumMainnet ? 'homestead' : 'ropsten', ETHERSCAN_API_KEY
        );

        // Setting this as isSys() and isBtc() calls might break
        this.network = {
          bech32: 'placeholder'
        };
      }
    }
  }

  getCoinType(hdPath) {
    const indices = [];
    for (let i = 0; i < hdPath.length; i++) {
      if (hdPath[i] === '/') indices.push(i);
    }

    const coinType = this.hdPath.substr(indices[1] + 1, indices[2]-indices[1]-2);
    return coinType;
  }
}

