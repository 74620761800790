import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { UpdateJwtAction, RemoveLodeAccount } from '../actions/userPreferences.actions';
import { getJwtToken } from '../store/userPreferences';
import { EXTERNAL_APIS, AUTH_REASON, USER_PREFERENCES_STORAGE_KEY, TWO_FA_CANCELLED, TWO_FA_UNLINK_ACCOUNT } from '../constants';
import { StorageService } from '../services/storage.service';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { Logger } from '../services/logger.service';
import { PreventAuthOnResumeAction } from '../actions/wallet.actions';
import { HlAgxBalanceService} from './hl-agx-balance.service';
import { LodeBalanceService } from './lode-balance.service';
import { LanguageService } from './language.service';
import { TokenManagerService } from './token-manager.service';
import { environment } from 'src/environments/environment';
import BigNumber from 'bignumber.js';
import { create2faModal } from '../../components/two-fa/twoFaUtils';
import { timeout } from 'rxjs/operators';
import { WalletService } from './wallet.service';

import { take } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})

export class MembersPortalService {
  private baseApi = EXTERNAL_APIS.MEMBERS_PORTAL;
  private loginUrl = `${this.baseApi}/login`;
  private userInfoUrl = `${this.baseApi}/me`;
  private countriesUrl: string = `${this.baseApi}/countries`;
  private regionsUrl: string = `${this.countriesUrl}/:countryId/regions`;
  private userDevicesUrl: string = `${this.baseApi}/userDevices`;
  private deleteDeviceUrl: string = `${this.baseApi}/deleteDevice`;
  private generateGoogle2faUrl: string = `${this.baseApi}/generateGoogle2fa`;
  private enableVerify2FA: string = `${this.baseApi}/verifyAndEnableGoogle2fa`;
  private disable2FAUrl: string = `${this.baseApi}/disableGoogle2fa`;
  private listCardsUrl: string = `${this.baseApi}/virtualcard/get`;
  private updatePasswordUrl: string = `${this.baseApi}/resetauthpassword`;
  private getPriceQuoteUrl: string = `${this.baseApi}/token/{{coin}}/getpricequote`;
  private updateUserInfoUrl = `${this.baseApi}/users`;
  private getTosStatusUrl: string = `${this.baseApi}/get_tos_and_status`;
  private getAgreedToTosUrl: string = `${this.baseApi}/get_agreed_to_tos`;
  private updateAgreementsUrl: string = `${this.baseApi}/updateUserAgreement`;
  private updateUserLanguageUrl: string = `${this.baseApi}/updateUserLang`;
  private updateNewsletterListUrl: string = `${this.baseApi}/updateNewsletterList`;
  private updateAnnounceListUrl: string = `${this.baseApi}/updateAnnounceList`;
  private validatePhoneNumberUrl: string = `${this.baseApi}/validatePhoneNumber`;
  private registerUserUrl: string = `${this.baseApi}/register`;
  private phoneNumberSMSVerifyUrl: string = `${this.baseApi}/phoneNumberSMSVerify`;
  private updatePhoneVerificationUrl: string = `${this.baseApi}/updatePhoneVerification`;
  private updatePhoneNumberSMSSendUrl: string = `${this.baseApi}/updatePhoneNumberSMSSend`;
  private captureEmailUrl: string = `${this.baseApi}/emailRegister`;
  private publicInfoUrl: string = `${this.baseApi}/publicinfo`;
  private getFeatureFlagsUrl: string = `${this.baseApi}/GetFeatureFlagsStatusFromArray`;
  private payByDeliveryUrl: string = `${this.baseApi}/orders/deliver`
  private getUnifiedPriceHistoryUrl: string = `${this.baseApi}/order-history`;
  private deleteAccountUrl: string = `${this.baseApi}/deleteAccount`;

  private twoFactorInput = [{
    name: '2fa',
    type: 'number',
    min: 6
  }];
  private refreshInterval;
  private REFRESH_INTERVAL = 180000; // refresh token every 3 mins
  private modalInfo = '';

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private storage: StorageService,
              private nav: NavController,
              public alertController: AlertController,
              private translate: TranslateService,
              private toastController: ToastController,
              private modalController: ModalController,
              private agx: HlAgxBalanceService,
              private lode: LodeBalanceService,
              private languageService: LanguageService,
              private tokenManager: TokenManagerService,
              private walletService: WalletService) {
  }

  // Checks the validity of a jwt token passed in... or a jwt token from storage.
  async isJwtValid(jwt?: any) {
    if (!jwt) {
      let userPref;
      try {
        //ensure we have a userPref object
        userPref = await this.storage.get(USER_PREFERENCES_STORAGE_KEY);
        if (!userPref) {
          return false;
        } else {
          userPref = JSON.parse(userPref);
        }
      } catch(e) {
        userPref = false;
        Logger.error('MembersPortalService', 'isJwtValid', 'Error getting user preferences from storage', e);
      }
      if (!userPref || !userPref.jwtToken) {
        return false;
      }
      jwt = userPref.jwtToken;
    }
    const jwtDecode = require('jwt-decode');
    let decoded;
    try {
      decoded = jwtDecode(jwt);
    } catch (e) {
      return false;
    }
    return Date.now() <= decoded.exp * 1000;
  }

  async returnJwtIfValid() {
    const userPref = await this.storage.get(USER_PREFERENCES_STORAGE_KEY).then(val => JSON.parse(val));
    if (!userPref || !userPref.jwtToken) {
      return false;
    }
    const jwt = userPref.jwtToken;

    const jwtDecode = require('jwt-decode');
    let decoded;
    try {
      decoded = await jwtDecode(jwt);
      if (Date.now() <= decoded.exp * 1000) {
        return jwt;
      }
    } catch (e) {
      return false;
    }
  }

  async pushAlert(status, header?, subHeader?, message?, buttons?, inputs?, backdropDismiss = false) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: buttons || ['OK'],
      backdropDismiss,
      inputs
    });
    return await alert.present();
  }

  async login(u, p, otp?) {
    var res = await this.http.post(`${this.loginUrl}/`, {
      username: u,
      password: p,
      one_time_password: otp
    }).toPromise();
    return res;
  }

  setRefreshInterval(u, p) {
    this.refreshInterval = setInterval(async() => {
      const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
      if(jwt)
      {
        this.updateJwt(u, p);
      }
    }, this.REFRESH_INTERVAL)
  }

  clearRefreshInterval() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = null
  }

  twoFactorButtons = (username, password, firstTime = false, canClose = false) => {
    return [
      {
        text: 'Submit', handler: (event) => {
          return firstTime ? this.linkThenLogin(username, password, event['2fa']) : this.updateJwt(username, password, event['2fa']);
        }
      }];
  }

  async linkThenLogin(username, password, oneTimePassword?, prevent2faModal?) {
    // ensure password is of correct length
    if (!await this.validatePassword(password)) {
      return;
    }

    try {
      const resp: any = await this.login(username, password, oneTimePassword);
      if (resp.status === 1) {
        if (await this.isJwtValid(resp.data.access_token)) {
          console.log(['/auth', AUTH_REASON.LINK_LODE_ACCOUNT]);
          this.languageService.setLanguage(resp.data.lang);
          await this.nav.navigateForward(['/auth', AUTH_REASON.LINK_LODE_ACCOUNT],
            {
              skipLocationChange: true,
              queryParams: { jwtData: resp.data.access_token, username, password, lodeid: resp.data.lodeid }
            });
        }
      }
      if (resp.status === 2 || resp.error_code === '2fa_invalid') {
        if (prevent2faModal) {
          return '2fa_required';
        }
        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));

        try {
          await this.show2fa(username, password, false, true);
        } catch (err) {
          // User clicked on cancel. Do nothing...
        }
      }

      // We don't want to show the LODE citizen popup anymore
      try {
        const info = await this.walletService.getStorageEmail();
        info.never = true;
        await this.walletService.setStorageEmail(info);
      } catch(err) {
        Logger.error('Could not update lode citizen preferences');
      }
      if(resp.status === 1){
        return 'login_success';
      }
    } catch (e) {
      if (e.error && e.error.error === 'Invalid 2FA Code') {
        const { header, subheader, msg, toast_msg } = await this.translate.get('alerts.prompt_2fa').toPromise();

        const toast = await this.toastController.create({
          message: toast_msg,
          duration: 2000
        });
        toast.present();

        if (prevent2faModal) {
          return '2fa_required';
        }

        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));

        return await this.pushAlert(0,
          header, subheader, msg, this.twoFactorButtons(username, password, true), this.twoFactorInput);
      }
      if (e && e.status === 401) {
        const { header, subheader, msg } = await this.translate.get('alerts.invalid_input.password').toPromise();
        
        if (e.error.error_code) {
          const { header, subheader, msg} = await this.translate.get(`alerts.${e.error.error_code}`).toPromise();
          return this.pushAlert(0, header, subheader, msg);
        }

        this.pushAlert(0, e.error.error, header, subheader);
      }
    }
  }

  async updateJwt(username, password, oneTimePassword?) {
    try {
      const resp: any = await this.login(username, password, oneTimePassword);
      if (resp.status === 1) {
        this.languageService.setLanguage(resp.data.lang);
        if (await this.isJwtValid(resp.data.access_token)) {
          this.store.dispatch(new UpdateJwtAction({ jwtToken: resp.data.access_token, lodeid: resp.data.lodeid }));
        }
      }
      if (resp.status === 2) {
        try {
          if(document.getElementsByClassName('two-fa-modal').length == 0){  //To avoid calling 2FA popup again if it already exist.
            const result = await this.show2fa(username, password);

            if (result === TWO_FA_CANCELLED || result === TWO_FA_UNLINK_ACCOUNT) {
              return result;
            }
          }
        } catch (err) {
          throw err;
        }
      }
    } catch (e) {
      if (e.error && e.error.error === 'Invalid 2FA Code') {
        const { header, subheader, msg } = await this.translate.get('alerts.prompt_2fa').toPromise();

        return await this.pushAlert(0,
          header, subheader, msg, this.twoFactorButtons(username, password, false), this.twoFactorInput);
      }

      if (e && e.status === 401) {
        // If login api returns a 401... we remove jwt token and lodeid from store.
        // This means the user changed their login credentials through the portal.
        this.removeAccount()
        await this.store.dispatch(new UpdateJwtAction({ jwtToken: null, lodeid: null }));
        const { header, subheader, msg } = await this.translate.get('alerts.failed_credentials').toPromise();
        this.pushAlert(0,
          e.error.error || header,
          '',
          msg);
      }
    }
  }

  private async validatePassword(password) {
    if (!password || password.length < 6) {
      const { header, subheader, msg } = await this.translate.get('alerts.invalid_input.password').toPromise();
      this.pushAlert(0, header, subheader, msg);
      return false;
    }
    return true;
  }
  
  public async getTosAndStatus() {
    try {
      const jwt = await this.returnJwtIfValid();
      if (!jwt) {
        throw new Error('No LODE account linked');
      }
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      }); 
      return await this.http.post(this.getTosStatusUrl, {}, { headers } ).toPromise();
    } catch (err) {
      return [];
    }
  }

  public async getActPatriotText() {
    // Should be an endpoint call, not implemented yet so we hardcoded
    return [{
      name: 'patriot_act',
      agreements_id: 1
    }]
  }

  public async getAgreedToTos() {
    try {
      const jwt = await this.returnJwtIfValid();   
      if (!jwt) {
        throw new Error('No LODE account linked');
      }
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      return await this.http.post(this.getAgreedToTosUrl, {}, { headers } ).toPromise();
    } catch (err) {
     return [];
    }
  }
  
  public async updateAgreements(formId) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(`${this.updateAgreementsUrl}?token=${jwt}`, {form_id: formId, response: 1}).toPromise();
    return (res as any);
  }

  public async getUserInfo() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    let params = new HttpParams();
    params = params.append('token', jwt);
    const res = await this.http.get(this.userInfoUrl, { params }).toPromise();
    const { data } = res as any;

    // Country and region data comes as ids, so we need to fetch the real name
    if (data.country) {
      let countries;

      try {
        countries = await this.getCountries();
      } catch (err) {
        return data;
      }

      const country = countries.find(country => country.id === data.country);
      if (country) {
        data.country = country;

        if (data.prov) {
          let regions;

          try {
            regions = await this.getRegions(country.id);
          } catch (err) {
            return data;
          }

          const region = regions.find(region => region.id === data.prov);

          data.prov = region;
        }
      }
    }

    return data;
  }

  public async updateUserInfo(lodeUserData) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return await this.http.post(this.updateUserInfoUrl, lodeUserData, { headers } ).toPromise();
  }

  public async removeAccount() {
    const userPrefs = await this.storage.get(USER_PREFERENCES_STORAGE_KEY).then(val => JSON.parse(val));
    delete userPrefs.jwtToken;
    delete userPrefs.lodeid;
    delete userPrefs.credentials;
    await this.store.dispatch(new UpdateJwtAction({ jwtToken: null, lodeid: null }));
    await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
    this.store.dispatch(new RemoveLodeAccount());
    
    this.clearRefreshInterval()
  }

  public async getCountries() {
    const res = await this.http.get(this.countriesUrl).toPromise();
    return (res as any).data;
  }

  public async getRegions(countryId) {
    const res = await this.http.get(this.regionsUrl.replace(':countryId', countryId)).toPromise();
    return (res as any).data;
  }

  public async getUserDevices() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.get(this.userDevicesUrl + `?token=${jwt}`).toPromise();
    return (res as any).data;
  }

  public async deleteUserDevice(password,otp,device) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const verify = await this.http.post(this.enableVerify2FA + `?token=${jwt}`,{ current_password: password, one_time_password: otp}).toPromise();

    const res = await this.http.post(this.deleteDeviceUrl + `?token=${jwt}`, device).toPromise();
    return (res as any).data;
  }

  public async disable2FA(password,otp) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.post(this.disable2FAUrl + `?token=${jwt}`,{ current_password: password, one_time_password: otp}).toPromise();

    return (res as any).data;
  }

  public async enable2FA(password,otp) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.post(this.enableVerify2FA + `?token=${jwt}`,{ current_password: password, one_time_password: otp}).toPromise();

    return (res as any).data;
  }

  public async generateGoogle2fa() {
    
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.post(this.generateGoogle2faUrl + `?token=${jwt}`,{}).toPromise();

    return (res as any);
  }

  public async updatePassword(currenPassword, newPassword, confirmPassword) {

    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const res = await this.http.post(this.updatePasswordUrl + `?token=${jwt}`,{current_password:currenPassword,password: newPassword,password_confirmation:confirmPassword}).toPromise();

    return (res as any);

  }

  public async deleteAccount(password, code2fa?) {

    const requestParam = { 'password':password } 
    code2fa ? requestParam['one_time_password'] = code2fa : null;

    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.post(this.deleteAccountUrl + `?token=${jwt}`,requestParam).toPromise();

    return (res as any);

  }

  public async registerGetVerifyCode(newUser) {
    const code = await this.http.post(`${this.validatePhoneNumberUrl}`, {phone: newUser.phone, phone_country_code: newUser.phone_country_code}).toPromise();
    const user = await this.http.post(`${this.registerUserUrl}`, newUser).toPromise();
    return {code: (code as any), user: (user as any)};
  }
  
  public async captureEmail(newEmail) {
    const res = await this.http.post(`${this.captureEmailUrl}`, newEmail).toPromise();
    return (res as any);
  }

  public async registerUser(newUser) {
    const user = await this.http.post(`${this.registerUserUrl}`, newUser).toPromise();
    return { user: (user as any)};
  }

  public async resendCode(phone, phone_country_code) {
    const code = await this.http.post(`${this.validatePhoneNumberUrl}`, {phone, phone_country_code}).toPromise();
    return (code as any);
  }

  public async updatePhoneVerification(phoneVerification) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    const formData = new FormData();
    formData.append('phone_verification', phoneVerification);
    return await this.http.post(`${this.updatePhoneVerificationUrl}?token=${jwt}`, formData).toPromise();
  }

  public async updatePhoneNumberSMSSend(phone, phoneCountryCode) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return await this.http.post(
      this.updatePhoneNumberSMSSendUrl,
      {phone, phone_country_code: phoneCountryCode},
      { headers })
      .toPromise();
  }

  public async phoneNumberSMSVerify(code) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(`${this.phoneNumberSMSVerifyUrl}?token=${jwt}`, {code}).toPromise();
    return (res as any).success;
  }

  public async updateUserLanguage(lang) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateUserLanguageUrl}?token=${jwt}`, {lang}).toPromise();
    return (res as any).success;
  }

  public async updateNewsletterList(updateValue) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateNewsletterListUrl}?token=${jwt}`, {list_newsletter: updateValue}).toPromise();
    return (res as any).success;
  }

  public async updateAnnounceList(updateValue) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateAnnounceListUrl}?token=${jwt}`, {list_announce: updateValue}).toPromise();
    return (res as any).success;
  }


  show2fa(username, password, isRetry?: boolean, isLink?: boolean) {
    return new Promise(async (resolve, reject) => {
      const { toast_msg } = await this.translate.get('alerts.prompt_2fa').toPromise();
      let result;

      if (isRetry) {
        const toast = await this.toastController.create({
          message: toast_msg,
          duration: 2000
        });
        toast.present();
      }

      const modal = await create2faModal(this.modalController, await this.isJwtValid(), this.removeAccount.bind(this));
      this.clearRefreshInterval()
      const { data: otp } = await modal.onDidDismiss();

      if (otp === TWO_FA_CANCELLED || otp === TWO_FA_UNLINK_ACCOUNT) {
        return resolve(otp);
      }

      let res: any;

      try {
        res = await this.login(username, password, otp);

        if (await this.isJwtValid(res.data.access_token)) {
          this.languageService.setLanguage(res.data.lang);
          this.store.dispatch(new UpdateJwtAction({ jwtToken: res.data.access_token, lodeid: res.data.lodeid }));
          this.setRefreshInterval(username, password)
          if (isLink) {
            await this.nav.navigateForward(['/auth', AUTH_REASON.LINK_LODE_ACCOUNT], {
              skipLocationChange: true,
              queryParams: { jwtData: res.data.access_token, username, password, lodeid: res.data.lodeid }
            });
          }
          return resolve(true);
        }
      } catch (err) {
        result = await this.show2fa(username, password, true, isLink);
      }
      return resolve(result);
    });
  }

  async getHlTokensBalance() {
    let agxBalance;
    let lodeBalance;
    const tokens = [];
    const supportedAssetList = await this.tokenManager.getTokenList();

    tokens.push({ ...supportedAssetList['HL-LODE'], balance: 0, keyringId: 'HL-LODE' });
    tokens.push({ ...supportedAssetList['HL-AGX'], balance: 0, keyringId: 'HL-AGX' });

    try {
      const jwt = await this.returnJwtIfValid();
      agxBalance = await this.agx.getAgxBalance(jwt);
      lodeBalance = await this.lode.getLodeBalance(jwt);

      tokens[0].balance = new BigNumber(lodeBalance.balance).toFixed();
      tokens[1].balance = new BigNumber(agxBalance.balance).toFixed();
      tokens[1].pendingBalance = new BigNumber(agxBalance.pendingBalance).toFixed();
    } catch (err) {
      // no linked lode acc most likely
    }

    return tokens;
  }

  async getVirtualCards() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const cards = await this.http.get(`${this.listCardsUrl}?token=${jwt}`).toPromise();

    return cards;
  }

  async getWirePriceQuote(amount: number | string, promoCode: string = null, readOnly: string = '0', coin: string = 'agx', currency: string = 'usd') {
    let params = new HttpParams();

    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    // params = params.append('token', jwt);
    params = params.append('currency', currency);
    params = params.append('qty', amount.toString());
    params = params.append('readonly', readOnly);
    params = params.append('skip_auto_order', "1");
    params = params.append('paymentType', "5");

    if (promoCode) {
      params = params.append('promocode', promoCode);
    }

    return this.http.get(`${this.getPriceQuoteUrl.replace('{{coin}}', coin.toLowerCase())}`, { headers, params }).toPromise();
  }
  
  async getPriceQuote(amount: number | string, promoCode: string = null, readOnly: string = '0', coin: string = 'agx', currency: string = 'usd') {
    let params = new HttpParams();
    
    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    params = params.append('token', jwt);
    params = params.append('currency', currency);
    params = params.append('qty', amount.toString());
    params = params.append('readonly', readOnly);

    if (promoCode) {
      params = params.append('promocode', promoCode);
    }

    return this.http.get(`${this.getPriceQuoteUrl.replace('{{coin}}', coin.toLowerCase())}`, { params }).toPromise();
  }


  getPublicInfo() {
    return this.http.get(this.publicInfoUrl).pipe(timeout(5000)).toPromise();
  }
  
  async getFeatureFlags(flags) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    
    let formData = new FormData();
    formData.append('flags', JSON.stringify(flags));
    const res = await this.http.post(this.getFeatureFlagsUrl + `?token=${jwt}`,formData).toPromise();
    return (res as any);
  }
  
  async createBullionOrder(cart: any) {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    let formData = new FormData();

    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    params = params.append('token', jwt);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    formData.append('items', JSON.stringify(cart));

    try {
      const res = await this.http.post(this.payByDeliveryUrl, formData, { headers, params }).toPromise();
      return res;
    } catch (err) {
      throw err;
    }
  }


  
  async getPurchaseHistory() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res: any = await this.http.get(`${this.getUnifiedPriceHistoryUrl}?token=${jwt}`).toPromise();
    
    res.forEach(element => {
      element['status'] = 1; 
    });

    return res;

  }

  async setModalInfo(modaldata){ // To share "Learn More" modal data btw components
    this.modalInfo = modaldata;
  }
  async getModalInfo(){ // To share "Learn More" modal data btw components
    return this.modalInfo;
  }

}
