import { PreventAuthInfo, UnconfirmedTransaction, WalletKeychain } from '../../global';
import { createSelector } from '@ngrx/store';
import { getWalletState } from './appState';

export interface WalletState {
  readonly authenticated: boolean;
  readonly preventAuthOnResume: boolean;
  readonly preventAuthDuration?: number;
  readonly unauthenticatedTime?: number;
  readonly mnemonic?: string;
  readonly keychain?: WalletKeychain;
  readonly unconfirmedTxs: UnconfirmedTransaction[];
  readonly queryCount: number;
  readonly walletRedirectUri?: string;
}

export const getWalletRedirectUri = createSelector(
  getWalletState,
  (state: WalletState) => state.walletRedirectUri
);

export const getKeychain = createSelector(
    getWalletState,
    (state: WalletState) => state.keychain
);

export const getTokenKeyring = createSelector(
  getWalletState,
  getKeychain,
  (state, keychain, props: { keyringId: string }) => {
    return keychain[props.keyringId];
  }
);

export const getMnemonic = createSelector(
  getWalletState,
  (state: WalletState) => state.mnemonic
);

export const getAuthenticated = createSelector(
  getWalletState,
  (state: WalletState) => state.authenticated
);

export const getUnconfirmedTxs = createSelector(
  getWalletState,
  (state: WalletState) => state.unconfirmedTxs
);

export const getUnconfirmedTxZdagState = createSelector(
  getWalletState,
  getUnconfirmedTxs,
  (state: WalletState, txs: UnconfirmedTransaction[], props: { txid: string} ) => {
    const tx = txs.find(unconfirmedTx => unconfirmedTx.txid === props.txid);
    return {zdag_confirmed: tx.zdag_confirmed, zdag_error: tx.zdag_error};
  }
);

export const getLatestUnconfirmedTransaction = createSelector(
  getWalletState,
  getUnconfirmedTxs,
  (state: WalletState, txs: UnconfirmedTransaction[]) => txs[txs.length - 1]
);

export const getLastSendTxId = createSelector(
  getWalletState,
  getUnconfirmedTxs,
  getLatestUnconfirmedTransaction,
  (state: WalletState, txs: UnconfirmedTransaction[], tx: UnconfirmedTransaction) => tx.txid
);

export const getLastSendTokenInfo = createSelector(
  getWalletState,
  getUnconfirmedTxs,
  getLatestUnconfirmedTransaction,
  (state: WalletState, txs: UnconfirmedTransaction[], tx: UnconfirmedTransaction) => tx.tokenInfo
);

export const getQueryCount = createSelector(
  getWalletState,
  (state: WalletState) => state.queryCount
);

export const getPreventAuthInfo = createSelector(
  getWalletState,
  (state: WalletState): PreventAuthInfo => {
    return {
      preventAuthOnResume: state.preventAuthOnResume,
      preventAuthDuration: state.preventAuthDuration,
      unauthenticatedTime: state.unauthenticatedTime
    };
  }
);

export const getTokenBalances = createSelector(
  getWalletState,
  (state: WalletState) => state.keychain
);

export const getTokenKeyringPagedTransactions = createSelector(
  getWalletState,
  getKeychain,
  (state, keychain, props: { keyringId: string }) => {
    return keychain[props.keyringId].pagedTransactions;
  }
);

export const getUnauthenticatedTime = createSelector(
  getWalletState,
  (state) => { return state.unauthenticatedTime; }
);
