import { Action } from '@ngrx/store';
import { WalletState } from '../store/wallet';
import cloneDeep from 'lodash/cloneDeep';
import { Logger } from '../services/logger.service';
import { sortKeyrings } from '../utils';
import {
  GetTokenHistorySuccessAction,
  CreateKeychainSuccessAction,
  AddUnconfirmedTransactionAction,
  PreventAuthOnResumeAction,
  RemoveUnconfirmedTransactionsAction,
  SetMnemonicAction,
  WalletActionTypes,
  ZdagConfirmedTransactionAction,
  ZdagErrorTransactionAction,
  GetTokenBalancesSuccessAction,
  ClearTxHistory, GetTokenFiatValuesSuccessAction, GetFeePerAssetTxSuccessAction, SetWalletRedirectUriAction
} from '../actions/wallet.actions';
import { TokenKeyring, ZdagTransactionAction } from '../../global';
import { createKeyringId } from '../keyringUtils';


export const initialWalletState = {
  authenticated: false,
  unconfirmedTxs: [],
  queryCount: 0,
  preventAuthOnResume: true,
  newPin: ''
};

export function walletReducer(state: WalletState = initialWalletState, action: Action): WalletState {
  let payload;
  let updatedKeyrings;
  let currentKeychain;
  let keyringId;
  let updatedKeychain;
  // Logger.info('WALLET:', state);
  switch (action.type) {
    case WalletActionTypes.CREATE_KEYCHAIN_SUCCESS:
      payload = (action as CreateKeychainSuccessAction).payload;
      updatedKeyrings = payload.keyrings;
      currentKeychain = state.keychain ? state.keychain : {};

      // arbitrarily sort the keyring so SYS is last :/
      updatedKeyrings = updatedKeyrings.sort(sortKeyrings);
      updatedKeyrings.forEach(keyring => {
        currentKeychain[keyring.keyringId] = keyring;
      });

      Logger.info('WALLET: CREATE_KEYCHAIN_SUCCESS', updatedKeyrings);

      return { ...state, keychain: { ...currentKeychain } };

    case WalletActionTypes.GET_TOKEN_BALANCES_SUCCESS:
      payload = (action as GetTokenBalancesSuccessAction).payload;
      updatedKeyrings = payload.keyrings;
      currentKeychain = state.keychain ? state.keychain : {};

      // merge the updated values with the current values on a keyring-by-keyring basis to avoid trampling
      Object.values(currentKeychain).forEach((currentKeyring: TokenKeyring) => {
        const updatedKeyring: TokenKeyring = Object.values(updatedKeyrings).find((uKeyring: TokenKeyring) => currentKeyring.keyringId === uKeyring.keyringId) as TokenKeyring;
        if (updatedKeyring) {
          updatedKeyrings[updatedKeyring.keyringId] = {...currentKeyring, ...updatedKeyring};
        }
      });
      Logger.info('WALLET: GET_TOKEN_BALANCES_SUCCESS', updatedKeyrings);
      return {...state, keychain: {...currentKeychain, ...updatedKeyrings}, queryCount: state.queryCount + 1};

    case WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS:
      keyringId = (action as GetTokenHistorySuccessAction).payload.result.keyringId;
      const { transactions, page, totalPages, itemsOnPage, hash, lastHash } = (action as GetTokenHistorySuccessAction).payload.result;

      // find the affected keyring
      updatedKeychain = cloneDeep(state.keychain);

      const existingTransactions = updatedKeychain[keyringId].pagedTransactions ?
        updatedKeychain[keyringId].pagedTransactions.transactions : [];

      updatedKeychain[keyringId].pagedTransactions = {
        transactions: existingTransactions ? existingTransactions.concat(transactions) : transactions,
        itemsPerPage:  itemsOnPage,
        loadedPages: page,
        totalPages,
        firstPageHash: hash ? hash : 0,
        lastPageHash: lastHash ? lastHash : 0
      };

      if (page === 1 && state.keychain[keyringId].pagedTransactions) {
        if (state.keychain[keyringId].pagedTransactions.firstPageHash === hash) {
          // Dont update if first page hash === incoming txs hash
          return state;
        } else {
          // Tx unconfirmed -> confirmed. Reset the whole history
          updatedKeychain[keyringId].pagedTransactions = {
            transactions,
            itemsPerPage: itemsOnPage,
            loadedPages: 1,
            totalPages: -1,
            firstPageHash: hash,
            lastPageHash: lastHash
          };
          return {...state, keychain: {...updatedKeychain}, queryCount: state.queryCount + 1};
        }
      }

      if (
        state.keychain[keyringId].pagedTransactions &&
        state.keychain[keyringId].pagedTransactions.lastPageHash === lastHash
      ) {
        // Dont update if last page hash === incoming txs hash
        return state;
      }

      return {...state, keychain: {...updatedKeychain}, queryCount: state.queryCount + 1};

    case WalletActionTypes.SET_MNEMONIC:
      payload = (action as SetMnemonicAction).payload;
      return { ...state, mnemonic: payload.mnemonic };

    case WalletActionTypes.AUTHENTICATE:
      Logger.info('Wallet reducer process auth', state);
      return {...state, authenticated: true, preventAuthOnResume: false, unauthenticatedTime: null, preventAuthDuration: null};

    case WalletActionTypes.DEAUTHENTICATE:
      Logger.info('Wallet reducer process de-auth', state);
      if (state.preventAuthOnResume && state.authenticated) {
        Logger.info('Handling as prevent de-auth');
        return {...state, unauthenticatedTime: Date.now(), authenticated: false};
      } else {
        Logger.info('Handling as standard de-auth');
        return {...state, unauthenticatedTime: Date.now(), preventAuthOnResume: false, authenticated: false};
      }

    case WalletActionTypes.PREVENT_AUTH_ON_RESUME:
      payload = (action as PreventAuthOnResumeAction).payload;
      console.log('prevent auth on resume!', payload);
      return { ...state, preventAuthOnResume: payload.preventLogout, preventAuthDuration: payload.preventAuthDuration };

    case WalletActionTypes.RESET_WALLET:
      return initialWalletState;

    case WalletActionTypes.ADD_UNCONFIRMED_TX:
      payload = (action as AddUnconfirmedTransactionAction).payload;

      // make sure we don't already have an entry for it
      let txFound = false;
      state.unconfirmedTxs.forEach(tx => {
        if (tx.txid === payload.transaction.txid) {
          txFound = true;
        }
      });

      const addUnconfirmedTxs = !txFound ? [...state.unconfirmedTxs, payload.transaction] : state.unconfirmedTxs;
      return { ...state, unconfirmedTxs: addUnconfirmedTxs };

    case WalletActionTypes.SET_UNCONFIRMED_TX_CANCELLED:
      payload = (action as any).payload;
      const modifiedUnconfirmedTxs = state.unconfirmedTxs.map(tx => {
        let txCopy = {...tx};
        if (payload.transaction.find(txid => txid === txCopy.txid)) {
          txCopy.cancelled = true;
        }

        return txCopy;
      });

      return { ...state, unconfirmedTxs: modifiedUnconfirmedTxs };

    case WalletActionTypes.REMOVE_UNCONFIRMED_TX:
      payload = (action as RemoveUnconfirmedTransactionsAction).payload;
      const removeUnconfirmedTxs = state.unconfirmedTxs.filter(tx => {
        const foundTx = payload.transactionIds.find(txid => tx.txid === txid);
        return foundTx === undefined;
      });
      Logger.info('RemoveUnconf:', removeUnconfirmedTxs);
      return { ...state, unconfirmedTxs: removeUnconfirmedTxs };

    case WalletActionTypes.ZDAG_CONFIRMED_TX:
      payload = (action as ZdagConfirmedTransactionAction).payload;
      const updatedTxs = state.unconfirmedTxs.map(tx => {
        const newTx = { ...tx };
        if (newTx.txid === payload.txid) {
          newTx.tx.zdag_confirmed = payload.zdag_confirmed;
        }

        return newTx;
      });
      return { ...state, unconfirmedTxs: updatedTxs };

    case WalletActionTypes.ZDAG_ERROR_TX:
      payload = (action as ZdagErrorTransactionAction).payload;
      const txsToUpdate = ([...state.unconfirmedTxs]).map(tx => {
        const newTx = { ...tx };
        if (newTx.txid === payload.txid) {
          newTx.tx.zdag_error = true;
        }

        return newTx;
      });

      return { ...state, unconfirmedTxs: txsToUpdate };

    case WalletActionTypes.CLEAR_TX_HISTORY:
      payload = (action as ClearTxHistory).payload;
      updatedKeychain = { ...state.keychain };

      if (!payload) {
        Object.keys(updatedKeychain).forEach(keyringId => {
          updatedKeychain[keyringId].pagedTransactions = {
            transactions: [],
            itemsPerPage:  0,
            loadedPages: 0,
            totalPages: 0,
            firstPageHash: 0,
            lastPageHash: 0
          };
        });
      } else {
        updatedKeychain[payload.keyringId].pagedTransactions = {
          transactions: [],
          itemsPerPage:  0,
          loadedPages: 0,
          totalPages: 0,
          firstPageHash: 0,
          lastPageHash: 0
        };
      }
      
      return { ...state, keychain: updatedKeychain };

    case WalletActionTypes.GET_TOKEN_FIAT_VALUES_SUCCESS:
      payload = (action as GetTokenFiatValuesSuccessAction).payload;
      updatedKeychain = { ...state.keychain };

      Object.values(updatedKeychain).forEach((keyring: TokenKeyring) => {
        if (payload.fiatValues[keyring.keyringId]) {
          keyring.fiatValue = payload.fiatValues[keyring.keyringId].fiatValue || null;
          keyring.retailFiatValue = payload.fiatValues[keyring.keyringId].retailFiatValue || null;
          keyring.markup = payload.fiatValues[keyring.keyringId].markup || null;

          payload.fiatValues[keyring.keyringId].tx_fee ? keyring.feePerAssetTx = payload.fiatValues[keyring.keyringId].tx_fee : null;
        }
      });

      Logger.info('WALLET: REDUCER GET_TOKEN_FIAT_VALUES_SUCCESS', updatedKeychain);

      return { ...state, keychain: updatedKeychain };

    case WalletActionTypes.GET_FEE_PER_ASSET_TX_SUCCESS:
      const fee = (action as GetFeePerAssetTxSuccessAction).payload.fee;
      updatedKeychain = { ...state.keychain };
      if (updatedKeychain.SYS) {
        updatedKeychain.SYS.feePerAssetTx = fee;
      }

      return { ...state, keychain: updatedKeychain };
    
    case WalletActionTypes.SET_WALLET_REDIRECT_URI:
      payload = (action as SetWalletRedirectUriAction).payload;
      Logger.info('Wallet reducer set wallet redirect uri', payload);
      return { ...state, walletRedirectUri: payload };
    // case PriceOracleActionTypes.SET_PRICE_ORACLE_DATA:
    //   const oracleState: PriceOracleState = (action as SetPriceOracleDataAction).payload.data;
    //   updatedKeychain = { ...state.keychain };
    //   Object.values(updatedKeychain).forEach((keychain: any) => {
    //     if(keychain.keyringId === AGX_KEYRING_ID) {
    //       keychain.markup = oracleState.agxMarkup;
    //     } else if (keychain.keyringId === AUX_KEYRING_ID) {
    //       keychain.markup = oracleState.auxMarkup;
    //     } else {
    //       keychain.markup = 1;
    //     }
    //
    //     keychain.retailFiatValue = keychain.markup ?
    //       new BigNumber(keychain.fiatValue).multipliedBy(new BigNumber(keychain.markup)) : keychain.fiatValue;
    //   });
    //
    //   return { ...state, keychain: updatedKeychain };

    default:
      return state;
  }
}
