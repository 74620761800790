import { createSelector } from '@ngrx/store';
import { getConnectionState } from './appState';

export interface ConnectionState {
  readonly connected: boolean;
  readonly services: {
    sysBB: boolean;
    ethBB: boolean;
    btcBB: boolean;
    gasStation: boolean;
    vdcServer: boolean;
    memberPortal: boolean;
    websocketETH: boolean;
    websocketSYS: boolean;
    websocketSPT: boolean;
    websocketAVAX: boolean;
    websocketSOL: boolean;
    avaxSnowTrace: boolean;
    avaxRpc: boolean;
    solRpc: boolean;
  }
}

export const getConnected = createSelector(
  getConnectionState,
  (state: ConnectionState) => state.connected
);

export const getServiceStatus = createSelector(
  getConnectionState,
  (state: ConnectionState) => state.services
)
