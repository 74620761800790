import { Credentials, TokenDefinition, TokenInfo, TokenKeyring, TransactionSummary, WalletKeychain } from '../global';
import { arrayConcat, arraySplit, arrayToString } from './arrayutils';
import { Logger } from './services/logger.service';
import networks from './lib/pangolin/config/network.config';
import { TokenDefinitionService } from './services/token-definition.service';
import { ethers, utils } from 'ethers';
import { BigNumber } from 'bignumber.js';
import { AGX_KEYRING_ID, PREVENT_AUTH_ON_RESUME_TIMEOUT } from './constants';
import { supportedAssetList } from './lib/supported-assets/supported-assets';
import { MembersPortalService } from './services/members-portal.service';
import { BankAccountService } from './services/bank-account.service';
import { VirtualDebitCardService } from './services/virtual-debit-card.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { createKeyringId } from './keyringUtils';
import { Store } from '@ngrx/store';
import { AppState } from './store/appState';
import { PreventAuthOnResumeAction } from './actions/wallet.actions';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import parsePhoneNumber from 'libphonenumber-js';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { KycModalComponent } from '../components/kyc-modal/kyc-modal.component';
import * as solanaWeb3 from '@solana/web3.js';
import { ComplianceService } from './services/compliance.service';
const semver = require('semver')

const bitcoin = (window as any).bitcoinjsLib;
const CryptoJS = require('crypto-js');
const sjs = require('syscoinjs-lib')
export function createTokenKeyring(symbol, address, balance, baseChainSymbol, guid?, ethFilterIndex?, precision?): TokenKeyring {
  return {
    symbol,
    address,
    balance,
    baseChainSymbol,
    guid,
    ethFilterIndex,
    precision,
    keyringId: createKeyringId(baseChainSymbol, guid)
  };
}

export const calculateUnconfBalance = (balance, unconfBalance) => {
  const operation = unconfBalance ? unconfBalance[0] : '0';
  let totalBalance;

  switch(operation) {
    case '+':
      totalBalance = Number(balance) + Math.abs(Number(unconfBalance));
      break;
    case '-':
      totalBalance = Number(balance) - Math.abs(Number(unconfBalance));
      break;
    default:
      return balance;
  }

  return totalBalance.toString();
}

export const isBaseChainSymbol = (baseChainSymbol: string): boolean =>
  baseChainSymbol.split('-') ? baseChainSymbol.split('-').length === 1 : false;

export const buildChainArray = (_symbol, groupedTokens) => {
  let chainList = [];
  groupedTokens[_symbol].forEach(item => {
    if (item.showIconInMain === true) {
      chainList.push(item.keyringId);
    }
  });
  return chainList;
}

export const  groupByTokenSymbol = (objectArray) => {
  const objTokens = objectArray.reduce((r, a) => {
    r[a.symbol] = [...r[a.symbol] || [], a];
    return r;
  }, {});
  for (var key in objTokens) {
    var arr = objTokens[key];
    customSortAssets({
      data: arr,
      sortBy: ['SYS', 'HL', 'PT'],
      sortField: 'baseChainSymbol'
    });
  }
  return objTokens;
}
export const getTokenInfoArray = (keychain: WalletKeychain): TokenInfo[] => {
  const filteredList = [];
  Object.entries(keychain).forEach(([key, keyring]) => {
    if (typeof keyring === 'object') {
      const tokenDef = TokenDefinitionService.getTokenDefinition(keyring.keyringId);
      filteredList.push(Object.assign({ ...keyring }, tokenDef));
    }
  });
  return filteredList;
};
export const checkNumberLimit = (min: number, max: number): ValidatorFn => {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (c.value && (isNaN(c.value) || `${c.value}`.length < min || `${c.value}`.length > max)) {
      return { 'range': true };
    }
    return null;
  };
}
export const checkValidCardExpiry = (month: string, year: string) => {
  // Returns true if the check fails
  const d = new Date();
  const m = d.getMonth();
  const y = d.getFullYear();
  const lastTwoDigits = parseInt(`${y}`.substr(2));
  if (parseInt(month) < 1 || parseInt(month) > 12 || parseInt(year) < lastTwoDigits){
    // First check the input validity then check the expiry date
    return true;
  }else if (parseInt(year) === lastTwoDigits && parseInt(month) < (m+1)){
    // Check if same year then check the month
    return true
  }
  return false;
}
export const getTokenInfo = (keychain: WalletKeychain, keyringId: string): TokenInfo => {
  let result;
  Object.values(keychain).forEach(keyring => {
    if (keyring.keyringId === keyringId) {
      const tokenDef = TokenDefinitionService.getTokenDefinition(keyring.keyringId);
      result = Object.assign({ ...keyring }, tokenDef);
    }
  });

  return result;
};

export const getBitcoinTransactionHash = (hash) => {
  const hexStr = hash.reverse().toString('hex');
  hash.reverse(); // restore original
  return hexStr;
};

export const getVinAddress = (vin): string => {
  const funcs = ['p2sh', 'p2wpkh'];
  let vinAddress = null;
  funcs.forEach(func => {
    if (vinAddress) {
      return;
    }
    try {
      const result = bitcoin.payments[func]({
        witness: vin.witness,
        network: networks.testnet,
        input: vin.input
      });
      vinAddress = result.address;
    } catch (e) {
    }
  });
  return vinAddress;
};

export const isVinMine = (vin, isMinetester): string => {
  return isMinetester(getVinAddress(vin)) ? getVinAddress(vin) : null;
};

interface transactionAmountResult {
  presentIn: string[],
  inputs: any,
  outputs: any
}

export const getTransactionAmounts = (txin, isMinetester): transactionAmountResult => {
  const tx = txin;
  let mine = null;
  const result = {
    presentIn: [],
    inputs: {},
    outputs: {}
  };
  tx.vin.forEach(vin => {
    mine = isVinMine(vin, isMinetester);
    if (mine) {
      if (!result.presentIn.includes('input')) {
        result.presentIn.push('input');
      }
    }
  });

  txin.vout.forEach(vout => {
    if (vout.scriptPubKey.addresses) {
      vout.scriptPubKey.addresses.forEach(address => {
        const isAddressMine = isMinetester(address);
        if (isAddressMine) {
          if (!result.presentIn.includes('output')) {
            result.presentIn.push('output');
          }
        }
        if (!result.outputs[address]) {
          result.outputs[address] = 0;
        }
        result.outputs[address] += vout.value;
      });
    }
  });
  return result;
};

export const getTransactionAmountsByAddressAvax = (tx, walletAddress, isEth) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: ethers.BigNumber.from(tx.value), // total baseChain amount of transaction
    type: null, // send, receive, self
  };

  if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
    result.type = 'send';
  }
  
  if(tx.value === "0" ) {
    if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
      result.type = 'send';
    }
    result.totalValue = ethers.BigNumber.from( tx.gasPrice * tx.gasUsed) ;
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  return result;
}

export const getTransactionAmountsByAddressSol = (tx, walletAddress, isEth) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: tx.value  , // total baseChain amount of transaction
    type: null, // send, receive, self
  };

  if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
    result.type = 'send';
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  return result;
}

export const getTransactionAmountsByAddress = (tx, walletAddress, isEth?) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: 0, // total baseChain amount of transaction
    type: null, // send, receive, self
    vinAddresses: [], // Addresses involved in inputs
    voutAddresses: [], // Addresses involved in outputs
    amounts: {} // Final coin/token allocations
  };

  tx.vin
    .filter(vin => vin.addresses)
    .forEach(vin => {
      const vinAddress = vin.addresses[0]; // Input address

      // Gotta keep a track of the addresses involved in tx
      if (!result.vinAddresses.includes(vinAddress)) {
        result.vinAddresses.push(vinAddress);
      }

      if (vinAddress === walletAddress) {
        // If wallet address is found in vins, set tx type to "send"
        result.type = 'send';
      }
    });

  if (isEth && tx.tokenTransfers) {
    // Base implementation works for normal ETH txs, but doesn't take ERC20 into consideration
    // Check token transfers (ERC20) for tx type.
    tx.tokenTransfers
      .forEach(tt => {
        if (tt.from === walletAddress) {
          result.type = 'send';
        }
      })
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  tx.vout
    .filter(vout => vout.addresses)
    .forEach(vout => {
      const voutAddress = vout.addresses[0]; // Output address

      if (!voutAddress || voutAddress.indexOf('OP_RETURN') !== -1) {
        // case1: assetsend operations will not carry any of this data at all
        // case2: Do not include OP_RETURN outputs
        return;
      }

      // Gotta keep a track of the addresses involved in tx
      if (!result.voutAddresses.includes(voutAddress)) {
        result.voutAddresses.push(voutAddress);
      }

      if (voutAddress) {
        if (!result.amounts[voutAddress]) {
          // Create if doesn't exists in result.amounts
          result.amounts[voutAddress] = {
            outputs: [],
            times: 0,
            value: 0, // baseChain value (SYS outputs could be a mix between SYS and token values)
            tokenValue: {}, // token values
            address: voutAddress
          }
        }

        if (vout.assetInfo && !isEth) {
          // If this output contains token info, append it to the results
          const tokenGuid = vout.assetInfo.assetGuid;
          
          if (!result.amounts[voutAddress].tokenValue[tokenGuid]) {
            result.amounts[voutAddress].tokenValue[tokenGuid] = 0; // initialize if it does not exist.
          }

          result.amounts[voutAddress].tokenValue[tokenGuid] += Number(vout.assetInfo.value); // Add value to the total sum
        }

        // Add output value to result
        result.amounts[voutAddress].times++;
        result.amounts[voutAddress].value += Number(vout.value);
        result.amounts[voutAddress].outputs.push(vout);

        // Append to total value (in baseChain value)
        result.totalValue += Number(vout.value);
      }
    });

  if (isEth && tx.tokenTransfers) {
    // Base implementation works for normal ETH txs, but doesn't take ERC20 into consideration
    // Add ERC20 txs to tokenValue object
    tx.tokenTransfers
      .forEach(tt => {
        if (!result.amounts[tt.to]) {
          result.amounts[tt.to] = {
            tokenValue: {}
          };
        }

        result.amounts[tt.to].tokenValue[tt.token] = Number(tt.value);
      });
  }

  if (
    result.type === 'send'
    && result.amounts[walletAddress]
    && Object.keys(result.amounts).length === 1
  ) {
    // If wallet address was found on vins and more than once in vouts (or is spt tx sending to itself), set the tx type to "self"
    // Assuming vouts contains legit output and change output set to walletAddress
    result.type = 'self';
  }

  return result;
}


export const ethGetTransactionAmount = (tx) => {
  // Don't know normal eth tx format yet...
  return tx.tokenTransfers ? tx.tokenTransfers[0].value : tx.value;
};

export const ethGetTransactionType = (tx, address) => {
  if (tx.tokenTransfers) {
    // ERC20 tx
    if (tx.tokenTransfers[0].from === address) {
      return 'send';
    } else if (tx.tokenTransfers[0].to === address) {
      return 'receive';
    }
  } else {
    if (tx.vout.find(output => !!output.addresses.find(addr => addr === address))) {
      return 'receive';
    }
    if (tx.vin.find(input => !!input.addresses.find(addr => addr === address))) {
      return 'send';
    }
  }

  return 'unknown';
};

export const avaxGetTransactionType = (tx, address) => {
  if (tx.from === address) {
    return 'receive';
  } else {
    return 'send';
  }
};

export const getTransactionAddress = (txin, isMinetester) => {
  const tx = txin;
  let vaddress = null;
  tx.vin.forEach(vin => {
    if (vaddress) {
      return;
    }
    if (!isMinetester(getVinAddress(vin))) {
      vaddress = getVinAddress(vin);
    }
  });
  if (vaddress) {
    return vaddress;
  }
  txin.vout.forEach(vout => {
    if (vaddress) {
      return;
    }
    if (vout.scriptPubKey.addresses) {
      vout.scriptPubKey.addresses.forEach(address => {
        if (!isMinetester(address)) {
          vaddress = address;
        }
      });
    }
  });
  return vaddress;
};

const memoHeader ='fefeafafafaf'

export const getTransactionMemo = (txn): string => {
  try{
    let script = txn.vout.find(out => (
      // Find OP_RETURN output
      out.addresses[0].indexOf('OP_RETURN') !== -1
    )).addresses[0];
    const memo = sjs.utils.getMemoFromScript(script,memoHeader)
    // Decoding the transaction memo to text (hex string => Uint8Array => text)
    var memoArray = new Uint8Array(memo.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16)
    }))
    return (new TextDecoder("utf-8").decode(memoArray));

  } catch (err) {
    // No memo...
    return '';
  }
};

export const setTransactionMemo = (txn, memo: string): boolean => {
  let processed = false;

  if (!memo) {
    return false;
  }
  for (let key = 0; key < txn.outs.length; key++) {
    const out = txn.outs[key];
    const chunksIn = bitcoin.script.decompile(out.script);
    if (chunksIn[0] !== bitcoin.opcodes.OP_RETURN) {
      continue;
    }
    txn.outs.splice(key, 1);

    const updatedData = arrayConcat(Uint8Array,
      chunksIn[1],
      memoHeader,
      new Buffer(memo));
    txn.addOutput(bitcoin.script.compile([
      bitcoin.opcodes.OP_RETURN,
      new Buffer(updatedData)
    ]), 0);

    processed = true;
    break;
  }
  if (processed) {
    return processed;
  }
  return txn.addOutput(bitcoin.script.compile([
    bitcoin.opcodes.OP_RETURN,
    new Buffer(arrayConcat(Uint8Array, memoHeader, new Buffer(memo)))
  ]), 0);
};

export const mergeObjByKey = (obj: object, src: object): object => {
  Object.keys(src).forEach((key) => Object.assign(obj[key], src[key]));
  return obj;
};

export const showFormErrors = (form, formErrors, validationMessages) => {
  for (const field in formErrors) {
    // clear previous error message (if any)
    formErrors[field] = '';
    const control = form.get(field);
    // Using control.value instead of control.dirty because it might lead to errors when other fields change the value of the current control
    // causing the control to be populated but still being pristine.
    if (control && control.value && !control.valid) {
      const messages = validationMessages[field];
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key) && messages.hasOwnProperty(key)) {
          formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
};

export const getAuxFeeFromTx = (tx, auxFeePossibleAddress: Array<string>): number => {
  if (typeof tx === 'string') {
    tx = JSON.parse(tx);
  }

  if (!Array.isArray(auxFeePossibleAddress)) {
    return 0;
  }

  const auxFeeVout = tx.vout.find(vout => auxFeePossibleAddress.includes(vout.addresses[0]))

  return auxFeeVout ? Number(auxFeeVout.assetInfo.value) : 0;
};

export const showTransactionFee = (
  alertController, translate, amount: number, txFee: string, coin: string = supportedAssetList[AGX_KEYRING_ID].symbol, auxFee: number
) =>
  new Promise(async (resolve, reject) => {
    const gasSymbol = supportedAssetList['SYS'].baseChainSymbol,
          headerText = await translate.get('tokens.send.transaction_fees.header').toPromise(),
          sendAmountText = await translate.get('tokens.send.transaction_fees.send_amount').toPromise(),
          auxFeeText = await translate.get('tokens.send.transaction_fees.aux_fee').toPromise(),
          txFeeText = await translate.get('tokens.send.transaction_fees.gas').toPromise(),
          totalText = await translate.get('tokens.send.transaction_fees.total').toPromise(),
          confirmText = await translate.get('tokens.send.transaction_fees.confirm').toPromise();

    let totalAmount = (amount + auxFee).toFixed(6);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
      <p><strong>${sendAmountText}</strong>: ${amount} ${coin}</p>
      <p><strong>${auxFeeText}</strong>: ${auxFee} ${coin}</p>
      <p><strong>${txFeeText}</strong>: ~ ${txFee} ${gasSymbol}</p>
      <p><strong>${totalText}</strong>: ${totalAmount} ${coin} / ~${txFee} ${gasSymbol}</p>
      <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject()
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve()
        }
      ]
    });

    return await alert.present();
  });

  export const showTransactionCoinFee = (
    alertController: AlertController, translate: TranslateService, amount: number, txEth: number, txFee: number,coin: string, coinName: string, networkCoin: string=null
  ) =>
    new Promise(async (resolve, reject) => {
      const formattedTxFee = txFee.toFixed(6),
            formattedTxEth = txEth.toFixed(6),
            headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
            sendAmountText = await translate.get('tokens.send.transaction_details.send_amount', {coinName: coin ? coin : coinName}).toPromise(),
            gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
            gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
            confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();
  
      let totalAmount = amount.toFixed(7);
      const alert = await alertController.create({
        header: headerText,
        // tslint:disable-next-line
        message: `
        <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
        <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
        <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
        <p>${confirmText}</p>`,
        buttons: [
          {
            text: await translate.get('btn_cancel').toPromise(),
            role: 'cancel',
            handler: () => reject()
          },
          {
            text: await translate.get('btn_ok').toPromise(),
            handler: () => resolve()
          }
        ]
      });
      return await alert.present();
    });
    
    export const showTransactionCoinFeeAvax = (
      alertController: AlertController, translate: TranslateService, amount: number, txEth: number, txFee: number,coin: string, coinName: string, networkCoin: string=null, tofixedNumber: number = 6
    ) =>
      new Promise(async (resolve, reject) => {
        const formattedTxFee = txFee.toFixed(2),
              formattedTxEth = txEth.toFixed(tofixedNumber),
              headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
              sendAmountText = await translate.get('tokens.send.transaction_details.send_amount', {coinName: coin ? coin : coinName}).toPromise(),
              gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
              gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
              confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();
    
        let totalAmount = amount.toFixed(tofixedNumber);
        const alert = await alertController.create({
          header: headerText,
          // tslint:disable-next-line
          message: `
          <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
          <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
          <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
          <p>${confirmText}</p>`,
          buttons: [
            {
              text: await translate.get('btn_cancel').toPromise(),
              role: 'cancel',
              handler: () => reject()
            },
            {
              text: await translate.get('btn_ok').toPromise(),
              handler: () => resolve()
            }
          ]
        });
        return await alert.present();
      });

      export const showTransactionCoinFeeSol = (
        alertController: AlertController, translate: TranslateService, amount: number, txEth: number, txFee: number,coin: string, coinName: string, networkCoin: string=null, tofixedNumber: number = 6
      ) =>
        new Promise(async (resolve, reject) => {
          const formattedTxFee = txFee.toFixed(tofixedNumber),
                formattedTxEth = txEth.toFixed(tofixedNumber),
                headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
                sendAmountText = await translate.get('tokens.send.transaction_details.send_amount', {coinName: coin ? coin : coinName}).toPromise(),
                gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
                gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
                confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

          let totalAmount = amount.toFixed(tofixedNumber);
          const alert = await alertController.create({
            header: headerText,
            // tslint:disable-next-line
            message: `
            <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
            <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
            <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
            <p>${confirmText}</p>`,
            buttons: [
              {
                text: await translate.get('btn_cancel').toPromise(),
                role: 'cancel',
                handler: () => reject()
              },
              {
                text: await translate.get('btn_ok').toPromise(),
                handler: () => resolve()
              }
            ]
          });
          return await alert.present();
        });
        
export const showTransactionSysFee = (
  alertController: AlertController, translate: TranslateService, amount: number, netFee: string,coin: string, coinName: string, netDollarFee: string=""
) =>
  new Promise(async (resolve, reject) => {
    const headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
      sendAmountText = await translate.get('tokens.send.transaction_details.send_amount', {coinName: coin ? coin : coinName}).toPromise(),
      gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
      gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

    let totalAmount = amount.toFixed(7);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
        <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
        <p><strong>${gasFeeText}</strong>: ~ ${netFee} SAT</p>
        ${netDollarFee ? '<p><strong>' + gasFeePriceText + '</strong>: ~ $' + netDollarFee + '</p>' : ''}
        <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject()
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve()
        }
      ]
    });
    return await alert.present();
  });

export function encryptData(password: string, data: object): string {
  const str = JSON.stringify(data);
  const res = CryptoJS.AES.encrypt(str, password);
  return res.toString();
}


export async function decryptData(password: string, encryptedData: string): Promise<Credentials> {
  const res = await CryptoJS.AES.decrypt(encryptedData, password);
  return JSON.parse(res.toString(CryptoJS.enc.Utf8));
}

// note this can be removed after tx list fixes
export const getTransactionSummary = (sptTx, txHex, myAddress, bbTx): TransactionSummary => {
  // Logger.info('get tx summary', txJson, myAddress);
  const inAddresses = [];
  const outAddresses = [];
  const summary: TransactionSummary = {};
  const tx = bitcoin.Transaction.fromHex(txHex);

  // track some stuff we care about
  let addressCountInVins = 0;
  let addressCountInVouts = 0;

  if (!sptTx.systx) { // non-asset tx
    getInputAddressesFromVins(tx.ins).forEach(vaddress => {
      inAddresses.push(vaddress);
      if (vaddress === myAddress) {
        addressCountInVins++;
      }
    });

    sptTx.vout.forEach(vout => {
      if (vout.scriptPubKey.addresses) {
        vout.scriptPubKey.addresses.forEach(address => {
          outAddresses.push(address);
          if (address === myAddress) {
            addressCountInVouts++;
          }
        });
      }
    });

    // Logger.info('addr vin/vout', addressCountInVins, addressCountInVouts);

    if (addressCountInVouts === 0 && addressCountInVins > 0) {
      summary.type = 'send';
    } else if (addressCountInVins > 0) {
      summary.type = 'send';
    } else if (addressCountInVins === 0 && addressCountInVouts > 0) {
      summary.type = 'receive';
    }


    if (summary.type === 'receive') {
      summary.fromAddress = inAddresses[0]; // assumes all inputs come from same address!
      summary.toAddress = myAddress;
    } else {
      summary.fromAddress = myAddress;
      summary.toAddress = outAddresses.find(address => address !== myAddress);

      // if we are sending to self toAddress will be undefined because all outputs come back to us
      if (!summary.toAddress) {
        summary.toAddress = myAddress;
      }
    }
  } else { // asset tx
    // vout index is tricky here. We have little accuracy checking which vout is the one we look for
    // because auxfee could be index 0 if present
    // If not present, then the "correct" address is most likely on index 0.
    summary.toAddress = bbTx.vout[bbTx.length > 3 ? 1 : 0].addresses[0];
    summary.fromAddress = bbTx.vin[0].addresses[0];

    if (summary.fromAddress !== myAddress) {
      summary.type = 'receive';
    } else {
      summary.type = 'send';
    }
  }

  return summary;
};

function getInputAddressesFromVins(ins) {
  const result = [];
  ins.forEach((input) => {
    try {
      const p2sh = bitcoin.payments.p2sh({
        witness: input.witness,
        network: networks.testnet,
        input: input.script
      });

      // Logger.info('Decoded', input.script.toString(), 'to', p2sh.address);
      result.push(p2sh.address);
    } catch (e) {
      // Logger.info('Failed to decode', input.script.toString(), ' s p2sh');
      try {
        const p2wpkh = bitcoin.payments.p2wpkh({
          witness: input.witness,
          network: networks.testnet,
          input: input.script
        });

        // Logger.info('Decoded', input, 'to', p2wpkh.address);
        result.push(p2wpkh.address);
      } catch (e) {
        // console.error('Failed to decode', input.witness.toString(), 'as p2wpkh');
      }
    }
  });

  return result;
}

export const openLinkExternal = (linkUrl, iab: InAppBrowser, store?: Store<AppState>) => {
  if (store) {
    const action = new PreventAuthOnResumeAction({preventLogout: true, preventAuthDuration: PREVENT_AUTH_ON_RESUME_TIMEOUT});
    store.dispatch(action);
  }
  iab.create(linkUrl, '_system', 'location=yes');
  return false;
};

/**
 * Parses a block explorer Syscoin-cli raw error object into a translation key string and a params object if the key uses params
 * @param errStr raw error string from server, should be a JSON object
 * @param sysBalance balance of syscoin
 */
export const parseSyscoinError = (errStr, sysBalance: number): { key: string, params?: any } => {
  const err = JSON.parse(errStr);
  Logger.info('parse sys err', err);

  if (err.message.indexOf('ERRCODE: 5502') > -1) { // insufficient funds error
    return {
      key: 'tokens.send.errors.insufficient_funds',
      params: {
        amountShort: err.message.substr(err.message.indexOf('by:') + 4)
      }
    };
  }

  if (err.message.indexOf('ERRCODE: 9000') > -1) { // not enough outputs error
    if (sysBalance < 0.0002) {
      return {
        key: 'tokens.send.errors.not_enough_outputs_balance'
      };
    } else {
      return {
        key: 'tokens.send.errors.not_enough_outputs_unconfirmed_txs'
      };
    }
  }

  if (err.message.indexOf('dust (code 64)') > -1) { // dust error
    return {
      key: 'tokens.send.errors.dust'
    };
  }

  if (err.message.indexOf('ERRCODE: 1503') > -1) { // please wait error
    return {
      key: 'tokens.send.errors.please_wait'
    };
  }

  return null;
};

// tinygraphs `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`
// robohash
// adorable.io `https://api.adorable.io/avatars/${size}/${uniqueStr}.png`;
// dicebear gridy
// AGX:   return `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`;
// SYS:   return `https://avatars.dicebear.com/v2/bottts/${uniqueStr}.svg?options[colors][]=lightBlue`;
export const getAvatarUrl = (uniqueStr, size) => {
  return `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`;
};

/* Copy current text to clipboard */
export const copyToClipboard = (data: string): boolean => {
  const textarea = document.createElement('textarea');
  try {
    textarea.textContent = data;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    /* This particular function requires User Interaction, so it won't run as expected in consoles */
    /* However, we can still test the execution thru spy; check copyToClipboard test case */
    return document.execCommand('copy');  // Security exception may be thrown by some browsers.
  } catch (ex) {
    Logger.info('Copy to clipboard failed.', ex);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};


export const sortKeyrings = (a, b) => {
  if (a.symbol < b.symbol) {
    return -1;
  }
  if (a.symbol > b.symbol) {
    return 1;
  }
  return 0;
};

export const round = (num, digits) =>
  parseFloat(String(Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits))).toFixed(digits);

export function handleCopy(message, platform, clipboard, alertController, translate, clipboardService, popupMessage) {
  
  copy(message, platform, clipboard, alertController, translate,popupMessage);

  if(isSafari())
  {
    clipboardService.copy(message);
  }

  function isSafari()
  {
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1 && ua.indexOf('chrome') != -1 ) { 
        // Chrome
        return false;
    } else {
        // Safari
        return true;
    }
  }

  async function copy(message, platform, clipboard, alertController, translate, popupMessage) {
    const alert = await alertController.create({
      cssClass: 'light-font-alert',
      header: await translate.get('tokens.receive.alert_copied_to_clipboard').toPromise(),
      message: popupMessage?popupMessage:message
    });
  
    if (platform.is('cordova')) {
      await clipboard.copy(message);
      await alert.present();
    } else {
      copyToClipboard(message);
      await alert.present();
    } 
  }
}
export const matchAfterDecimal = (num, maxNumberAllowed) => {
  return String(num).match(new RegExp('\\.+\\d{' + (1 + maxNumberAllowed) + '}', 'g'));
};

export const matchBeforeDecimal = (num, maxNumberAllowed) => {
  return String(num).match(new RegExp('\\d{' + (1 + maxNumberAllowed) + '}', 'g'));
};

export const matchValidDecimal = (input) => {  // To check the validity of input amount
  return String(input).match(new RegExp('^([0-9]*)[.,]?([0-9]*)$', 'g'));
};

export const showAlertMessageScrollbar = (message) => {
  return `<div class="alert-scroll">${message}</div>`;
};

export const weiToNum = (ethNum) => {
  const ethNumString = ethNum.toString();
  const dotIndex = ethNumString.indexOf('.');
  const integerPart = ethNumString.slice(0, dotIndex === -1 ? ethNumString.length : dotIndex);
  return Number(ethers.utils.formatEther(ethers.BigNumber.from(integerPart)));
};


export const lamportsToNum = (solNum) => {
  return Number(solNum / solanaWeb3.LAMPORTS_PER_SOL);
};

export const getTokenBalance = (keyring: TokenKeyring) => keyring.balance;

export const satoshiToNum = (satNum) => {
  const num = new BigNumber(Number(satNum)).dividedBy(new BigNumber(100000000)).toNumber();
  return num;
};

export const setNumberToPrecision = (num: number | string, precision: number) => {
  return new BigNumber(Number(num)).dividedBy(new BigNumber(Math.pow(10, precision))).toNumber();
}

export const lookupTokenKeyring = (keyringId, tokenKeychain) => {
  return tokenKeychain.find(keyring => keyring.keyringId === keyringId);
};

export const hashCode = (s) => {
  return s.split('').reduce((a, b) => {
    a=((a << 5) - a) + b.charCodeAt(0);
    return a&a;
  }, 0);
};

export const formatMonth = (s: string) => {
  return s.length === 1 ? '0' + s : s;
}

/**
 * Parses a ethereum blockbook explorer raw error object into a translation key string and a params object if the key uses params
 * Corresponding error messages https://github.com/ethereum/go-ethereum/blob/master/core/tx_pool.go
 * @param errStr raw error string from server, should be a JSON object
 * @param ethBalacne balance of Ethereum
 */
export const parseEthereumError = (errStr, ethBalance: number): { key: string, params?: any } => {
  Logger.info('parseEthereumError', errStr);

  if (errStr.indexOf('already known') > -1) {
    return {
      key: 'tokens.send.errors.eth_already_known'
    };
  }
  if (errStr.indexOf('invalid sender') > -1) {
    return {
      key: 'tokens.send.errors.eth_invalid_sender'
    };
  }
  if (errStr.indexOf('nonce too low') > -1) {
    return {
      key: 'tokens.send.errors.eth_nonce_too_low'
    };
  }
  if (errStr.indexOf('transaction underpriced') > -1) {
    return {
      key: 'tokens.send.errors.eth_transaction_underpriced'
    };
  }
  if (errStr.indexOf('replacement transaction underpriced') > -1) {
    return {
      key: 'tokens.send.errors.eth_replacement_tx_underpriced'
    };
  }
  if (errStr.indexOf('insufficient funds for gas * price + value') > -1) {
    return {
      key: 'tokens.send.errors.eth_insufficient_funds'
    };
  }
  if (errStr.indexOf('intrinsic gas too low') > -1) {
    return {
      key: 'tokens.send.errors.eth_gas_too_low'
    };
  }
  if (errStr.indexOf('exceeds block gas limit') > -1) {
    return {
      key: 'tokens.send.errors.eth_exceeds_block_gas_limit'
    };
  }
  if (errStr.indexOf('negative value') > -1) {
    return {
      key: 'tokens.send.errors.eth_negative_value'
    };
  }
  if (errStr.indexOf('oversized data') > -1) {
    return {
      key: 'tokens.send.errors.eth_oversized_data'
    };
  }
  return null;
};

/**
 * Checks if an address is valid in the blockchain protocol specified
 * @param address address of either Syscoin or Ethereum blockchain
 * @param baseTokenSymbol base token symbol indicating the blockchain for address validity check (SYS or ETH)
 * @param tokenGuid token guid of SPT; 0 if not an SPT
 * @returns null for no error and key value pair with key being the error value type and value being true if error
 */
export const validateAddressByBlockchain = (address, baseTokenSymbol, tokenGuid = '0') => {
  switch (baseTokenSymbol) {
    case 'SYS':
      try {
        bitcoin.address.toOutputScript(address, networks.testnet);
      } catch (e) {
        return { invalid_address: true };
      }
      if (tokenGuid !== '0' && address.indexOf('sys') !== 0) {
        return { no_legacy_address: true };
      }
      break;
    case 'ETH':
      try {
        ethers.utils.getAddress(address);
      } catch (e) {
        return { invalid_address: true };
      }
      break;
    default: {
      return { address_required: true };
    }
  }
  return null;
};

// export const restrict = () => {
//   const e = event || window.event;  // get event object
//   const key = (e as any).keyCode || (e as any).which; // get key cross-browser
//   const validKeyCodes = [8, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190];
//   if (!validKeyCodes.includes(key)) { // if it is not a number ascii code
//     if (e.preventDefault) e.preventDefault(); // normal browsers
//     e.returnValue = false; // IE
//   } else {
//     // if the keycode is valid but its a '.', only allow 1!
//     if (this.amount.toString().indexOf('.') !== -1 && key === 190) {
//       if (e.preventDefault) e.preventDefault(); // normal browsers
//       e.returnValue = false; // IE
//     }
//   }
// };

export const getVirtualCardsAndBalances = async (membersPortalService: MembersPortalService, virtualCardService: VirtualDebitCardService) => {
  try {
    let cards: any = await membersPortalService.getVirtualCards();
    cards = await Promise.all(
      cards.map(async card => ({
        balance: await virtualCardService.checkCardBalance(card.card_number),
        ...card
      }))
    );
    cards = cards.filter(item => item.balance !== null);

    return cards;
  } catch (err) {
    return [];
  }
};

export const getBankAccountsAndBalances = async (bankAccountsService: BankAccountService) => {
  try {
    const isCustodialAccount = await bankAccountsService.getACHStatus();
    if (isCustodialAccount) {
      const list = await bankAccountsService.getBankAccounts();
      const balance = await bankAccountsService.getBankAccountsBalance();
      bankAccountsService.setServiceStatusInStore('bankAccount', true);

      return { list, balance };
    }else{
      // Throw exception to execute exception block
      throw "User does not have custodial account"
    }
  } catch {
    bankAccountsService.setServiceStatusInStore('bankAccount', false);
    return { list: [], balance: 0 }
  }
};

export const getPTBalances = async (bankAccountsService: BankAccountService) => {
  try {
    const agxBalance = await bankAccountsService.getAgxBalance();
    const auxBalance = await bankAccountsService.getAuxBalance();
    const lodeBalance = await bankAccountsService.getLodeBalance();

    agxBalance.balance = new BigNumber(agxBalance.balance).multipliedBy(100000000).toNumber();
    auxBalance.balance = new BigNumber(auxBalance.balance).multipliedBy(100000000).toNumber();
    lodeBalance.balance = new BigNumber(lodeBalance.balance).multipliedBy(100000000).toNumber();

    return {
      agxBalance,
      auxBalance,
      lodeBalance
    }
  } catch {
    return {
      agxBalance: 0,
      auxBalance: 0,
      lodeBalance: 0
    }
  }
}

export const getCoinNameBySymbol = (symbol: string) => {
  switch (symbol.toLowerCase()) {
    case 'sys':
      return 'syscoin';
    case 'eth':
      return 'ethereum';
    case 'btc':
      return 'bitcoin';
    default:
      return 'syscoin';
  }
};

export const getTokenFiatValue = (fiatRate, balance) => {
  return new BigNumber(fiatRate).multipliedBy(new BigNumber(balance)).toFixed(2);
};

export const getCurrentNetworkLabel = async (symbol, baseChainSymbol, networkManager, translateService) => {
  const ethNetwork = await networkManager.getActiveEthNetwork();
  const sysNetwork = await networkManager.getActiveSysNetwork();
  const btcNetwork = await networkManager.getActiveBtcNetwork();
  const avaxNetwork = await networkManager.getActiveAvaxNetwork();
  const solNetwork = await networkManager.getActiveSolNetwork();
  let blockchain = '';
  let network = '';

  if (baseChainSymbol === 'HL') {
    blockchain = 'hyperledger';
  } else if (baseChainSymbol === 'SYS') {
    if (sysNetwork.network === 'mainnet') {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
    } else {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
      network = sysNetwork.shortName || sysNetwork.name;
    }
  } else if (baseChainSymbol === 'ETH') {
    if (ethNetwork.network === 'mainnet') {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
    } else {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
      network = ethNetwork.shortName || ethNetwork.name;
    }
  } else if (baseChainSymbol === 'PT') {
    blockchain = 'Prime Trust';
    network = '';
  } else if (baseChainSymbol === 'BTC') {
    if (btcNetwork.network === 'mainnet') {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
    } else {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
      network = btcNetwork.shortName || btcNetwork.name;
    }
  } else if (baseChainSymbol === 'AVAX') {
    if (avaxNetwork.network === 'mainnet') {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
    } else {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
      network = avaxNetwork.shortName || avaxNetwork.name;
    }
  } else if (baseChainSymbol === 'SOL') {
    if (solNetwork.network === 'mainnet') {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
    } else {
      blockchain = symbol === baseChainSymbol ? '' : getCoinNameBySymbol(baseChainSymbol);
      network = solNetwork.shortName || solNetwork.name;
    }
  }

  const finalLabel = await translateService.get('assets.on_network', { blockchain, network }).toPromise();

  return blockchain || network ? finalLabel : '';
};

export const getCurrentNetworkIcon = async (symbol, baseChainSymbol) => {
  let blockchain = '';

  if (baseChainSymbol === 'HL') {
    blockchain = '../../assets/images/fabric.png';
  } else if (baseChainSymbol === 'SYS') {
    blockchain = '../../assets/images/syscoin.png';
  } else if (baseChainSymbol === 'ETH') {
    blockchain = '../../assets/images/ETH.svg';
  } else if (baseChainSymbol === 'PT') {
    blockchain = '../../assets/images/ETH.svg';
  } else if (baseChainSymbol === 'BTC') {
    blockchain = '../../assets/images/btc.png';
  } else if (baseChainSymbol === 'AVAX') {
    blockchain = '../../assets/images/coins/avax_token.png';
  } else if (baseChainSymbol === 'SOL') {
    blockchain = '../../assets/images/solana-logo.svg';
  } else {
    blockchain = '../../assets/images/unknown_coin.png';
  }

  return blockchain;
};

export const mapUrlToLabel = (path) => {
  switch (path) {
    case '/wallet/assets':
      return 'pages.assets';
    case '/dev/component-sheet':
      return 'Component Sheet';
    case '/wallet/assets/SYS/341906151':
    case '/wallet/assets/SYS/367794646':
      return 'tokens.titles.agx';
    case '/wallet/assets/SYS/439183631':
    case '/wallet/assets/SYS/1358717298':
      return 'tokens.titles.aux';
    case '/wallet/assets/ETH':
      return 'tokens.titles.eth';
    case '/wallet/assets/SYS':
      return 'tokens.titles.sys';
    case '/wallet/assets/BTC':
      return 'tokens.titles.btc';
    case '/wallet/assets/ETH/0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b':
    case '/wallet/assets/ETH/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48':
      return 'tokens.titles.usdc';
    case '/wallet/transaction-detail':
      return 'txn_detail.title';
    case '/wallet/card':
      return 'virtual_debit_card.title';
    case '/wallet/settings':
      return 'pages.settings';
    case '/gas-station':
      return 'pages.gas_station';
    case '/lode-acc':
      return 'lode_acc.title';
    default:
      return '';
  }
};

export const openKYC = async (loading: boolean, complianceService: ComplianceService, translate: TranslateService, toastController: ToastController, modalController: ModalController, platform: Platform) => {
  loading = true;
  try {
    const res = await complianceService.getKycStartInfo();
    loading = false;
    await startKYC(res, modalController);
  } catch(err) {
    loading = false;
    presentToast('settings.toast_msgs.err_hdr', 'settings.toast_msgs.kyc_err_msg', translate, toastController, platform);
  }
};

export const startKYC = async (configs, modalController: ModalController) => {
  const modal = await modalController.create({
    component: KycModalComponent,
    componentProps: {
      cssClass: 'auto-height',
      urlAPI: configs.environment,
      token: configs.token,
      flowName: configs.flowName
    }
  });
  await modal.present();
};

export const presentToast = async (hdr:string,msg:string,translate: TranslateService,toastController: ToastController,platform: Platform) => {
  const toast = await toastController.create({
    header: await translate.get(hdr).toPromise(),
    message: await translate.get(msg).toPromise(),
    position: getToastPosition(platform),
    duration: 4000
  });
  await toast.present();
}

export const getToastPosition = (platform: Platform): 'top' | 'bottom' => {
  return platform.is('desktop') ? 'top' : 'bottom';
};

export const createRemoveAccountAlert = async (alertController: AlertController, translate: TranslateService,
                                               handler: any, header_msg: string, message: string, cancel_btn_text: string, accept_btn_text: string) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get(header_msg).toPromise(),
    message: await translate.get(message).toPromise(),
    buttons: [{
      text: await translate.get(cancel_btn_text).toPromise(),
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: await translate.get(accept_btn_text).toPromise(),
      handler
    }]
  });

  await alert.present();
};

export const confirmResetWalletAlert = async (alertController: AlertController, translate: TranslateService,
                                              handler: any, resetWithPin: boolean = false) => {

    const alert = await alertController.create({
      header: await translate.get('settings.alert_title_reset_warning').toPromise(),
      message: resetWithPin === true ? await translate.get('settings.text_reset_warning').toPromise() : await translate.get('settings.text_reset_wallet_forget_pin').toPromise(),
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: await translate.get('settings.btn_confirm_reset').toPromise(),
          handler
        }
      ]
    });
    await alert.present();
};

export const confirmstartKycAlert = async (alertController: AlertController, translate: TranslateService,
  handler: any, resetWithPin: boolean = false) => {

  const alert = await alertController.create({
    header: await translate.get('bank_account.ach_kyc_needed_hdr').toPromise(),
    message: await translate.get('bank_account.ach_kyc_needed_msg').toPromise(),
    buttons: [
      {
        text:  await translate.get('btn_cancel').toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: await translate.get('settings.btn_confirm').toPromise(),
        handler
      }
    ]
  });
  await alert.present();
};


export const createDisable2FAAlert = async (alertController: AlertController, translate: TranslateService, handler: any) => {
    
  const alert = await alertController.create({
      cssClass: 'light-font-alert',
      header: await translate.get('settings.twofa_devices.alert_title').toPromise(),
      message: await translate.get('settings.twofa_devices.alert_description').toPromise(),
      inputs: [
        { name: 'password', placeholder: 'Password', type: 'password'},
        { name: 'twofacode', placeholder: '2FA Code'}
      ],
      buttons: [{
      text: await translate.get('settings.btn_cancel').toPromise(),
      role: 'cancel',
      cssClass: 'secondary'
      }, {
      text: await translate.get('settings.btn_confirm').toPromise(),
      handler
      }]
    });
    await alert.present();
  };

export const presentAlert = async (alertController: AlertController, translate: TranslateService,
  handler: any, title: string, message: string, btn_text: string) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get(title).toPromise(),
    message: await translate.get(message).toPromise(),
    buttons: [{
      text: await translate.get(btn_text).toPromise(),
      handler
    }]
  });

  await alert.present();
};

  export const accountCreatedAlert = async (alertController: AlertController, translate: TranslateService, handler: any) => {
    const alert = await alertController.create({
      cssClass: 'light-font-alert',
      header: await translate.get('lode_acc.account_created_title').toPromise(),
      message: await translate.get('lode_acc.account_created_description').toPromise(),
      buttons: [{
        text: await translate.get('lode_acc.remove_account.alert_accept').toPromise(),
        handler
      }]
    });

  await alert.present();
  };

export const accountUpdatedAlert = async (alertController: AlertController, translate: TranslateService,
                                          handler: any, emailUpdated: boolean) => {
  const description = emailUpdated ? 'account_email_updated_description' : 'account_updated_description';
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get('lode_acc.account_updated_title').toPromise(),
    message: await translate.get(`lode_acc.${description}`).toPromise(),
    buttons: [{
      text: await translate.get('lode_acc.remove_account.alert_accept').toPromise(),
      handler
    }]
  });

  await alert.present();
};

export const isEnterKey = (ev) => {
  if (ev.keyCode === 13) {
    return true;
  }

  return false;
};

export const getCountryCode = (phoneNum) => {
  const res: any = {};
  const phoneNumber = parsePhoneNumber(phoneNum.e164Number);

  res.phone = phoneNumber.formatNational();
  res.countrycode = phoneNumber.country;

  if (!res.phone || !res.countrycode) {
    throw ({error: {errors: { phone: true}}});
  }

  return res;
}

export const gramsToOz = (grams: string|number) => {
  return new BigNumber(grams).multipliedBy(0.035274).toNumber();
}

export const ozToGrams = (oz: string|number) => {
  return new BigNumber(oz).dividedBy(0.035274).toNumber();
}


interface ConversionRatesType {
  GOLD: number,
  TROY_OUNCES: number
}

export const ConversionRates: ConversionRatesType = {
  GOLD: 75,
  TROY_OUNCES: 31.1034768
};

export const calculateLode = (oz: number, qty: number, type: string, lodeRateInGrams: number) => {
  return ((oz * ConversionRates.TROY_OUNCES) / lodeRateInGrams) *  qty  * (type === 'gold'  ? ConversionRates.GOLD : 1);
}

// MW-849 - specific sort is required - by keyringId - Start
export const sortByObject = data => data.reduce((obj,item,index) => {
  return {
    ...obj,
    [item]:index
  }
}, {});

export const customSortAssets = ({data, sortBy, sortField}) => {
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    }
  }, {})
  return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
}
// MW-849 - specific sort is required - by keyringId - End

export const mergeSptAllocationsAndVouts = (allocations: Array<any>, vouts: Array<any>): Array<any> => {
  const allocationInfoArr = JSON.parse(JSON.stringify(allocations)); // perform a deep copy, don't want to modify original object.

  allocationInfoArr.forEach(alloc => {
    alloc.outputs.forEach(out => {
      out.vout = vouts[out.n];
    });
  });

  return allocationInfoArr;
}

export const convertAllocsToSatoshi = (allocs) => {
  const allocsClone = JSON.parse(JSON.stringify(allocs));
  allocsClone.forEach(alloc => {
    alloc.total = new BigNumber(alloc.total).multipliedBy(100000000).toNumber();
    alloc.outputs.forEach(output => {
      output.amount = new BigNumber(output.amount).multipliedBy(100000000).toNumber();
    });
  });
  return allocsClone;
}

export const compareAppVersion = (latest_version,current_version) => {
  return semver.gt(semver.coerce(latest_version),semver.coerce(current_version))
}

export const createBankAccountServiceUnavailableAlert = async (alertController: AlertController, translate: TranslateService) => {
  const alert = await alertController.create({
    cssClass: "light-font-alert",
    header: await translate.get('bank_account.service_unavailable').toPromise(),
    message: await translate.get('bank_account.try_again_later').toPromise(),
    buttons: [
      {
        text: await translate.get('bank_account.close').toPromise(),
        role: "cancel",
        cssClass: "secondary"
      }
    ]
  });

  await alert.present();
};

export const removeAuxFeeAddressFromArray = (address, auxFeePossibleAddresses) => address.filter(addr => !auxFeePossibleAddresses.includes(addr))

export const removeAllocationsFromAddress = (allocs, addresses = []) => {
  return allocs.filter(alloc => addresses.indexOf(alloc.address) === -1)
}

export const getSendTypeValueFromtxAmount = (txAmount, walletAddress, isBaseChain, tokenGuid?) => {
  const addresses = Object.keys(txAmount.amounts);

  return addresses
    .filter(addr => addr !== walletAddress)
    .map(addr => {
      if (isBaseChain) {
        return txAmount.amounts[addr]?.value;
      }

      return txAmount.amounts[addr]?.tokenValue[tokenGuid];
    })
    .reduce((prev, next) => Number(prev || 0) + Number(next || 0));
}

export const getReceiveTypeValueFromtxAmount = (txAmount, walletAddress, isBaseChain, tokenGuid?) => {
  if (isBaseChain) {
    return txAmount.amounts[walletAddress].value;
  }

  return txAmount.amounts[walletAddress]?.tokenValue[tokenGuid] || 0;
}
