import { ModalController } from '@ionic/angular';
import { TwoFaComponent } from './two-fa.component';

export const create2faModal = async (modalController: ModalController, isLodeLinked, removeAccountFn) => {
  const modal: HTMLIonModalElement = await modalController.create({
    component: TwoFaComponent,
    cssClass: 'two-fa-modal',
    componentProps: {
      showCancel: true,
      isLodeLinked,
      removeAccountFn
    }
  });
  await modal.present();

  return modal;
};
