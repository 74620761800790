import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { getJwtToken } from "../store/userPreferences";
import { EXTERNAL_APIS } from "../constants";
import { AlertController } from "@ionic/angular";

import { take } from "rxjs/operators";
import { Logger } from "./logger.service";

@Injectable({
  providedIn: "root",
})
export class ComplianceService {
  private baseApi = EXTERNAL_APIS.COMPLIANCE;
  private getKYCTokenUrl: string = `${this.baseApi}/kyc/start`;

  constructor(private http: HttpClient, private store: Store<AppState>, public alertController: AlertController) {}

  async getKycStartInfo(flowName?: string): Promise<any> {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const requestUrl = flowName ? `${this.getKYCTokenUrl}?flowName=${flowName}` : this.getKYCTokenUrl;
    return await this.http.get(requestUrl, { headers }).toPromise();
  }

  async getKycCipData(flowName?: string) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const res = await this.http.get(`${this.baseApi}/kyc/cip/${flowName}`, { headers }).toPromise();
    Logger.info("getKycAch", res);
    return res as any;
  }

  async getUsCitizen() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    // getting basic document set because it returns more user data
    const res = await this.http.get(`${this.baseApi}/kyc/cip/BasicDocumentSet`, { headers }).toPromise();
    // @ts-ignore
    return res?.info?.country?.toLowerCase() === "usa" || res?.info?.country?.toLowerCase() === "us";
  }

  async verifyIfKYCIsCompleted(flowName?: string): Promise<any> {
    let res: any;
    try {
      res = await this.getKycStartInfo(flowName);
    } catch (err) {
      Logger.error(err);
      throw err;
    }
    Logger.info("verifyIfKYCIsCompleted", res);
    if (!res?.missingLevel && res?.passingLevel) {
      return true;
    } else {
      return false;
    }
  }

  async createAccessToken() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const res: any = await this.http.get(`${this.baseApi}/kyc/create-access-token`, { headers }).toPromise();
    Logger.info("createAccessToken", res);

    return res;
  }

  async isAuthGroupAch() {
    const jwtDecode = require('jwt-decode');
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const decodedJwt = jwtDecode(jwt);
    return decodedJwt?.groups.includes("GROUP_ACH");
  }
}
