import { Injectable, Provider } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NetworkService } from './network.service';
import { RpcProvider } from './rpc-provider';
import { environment } from 'src/environments/environment';
import { TokenKeyring } from 'src/app/global';
import { BlockbookGetAddressResponse, BlockbookGetAddressTxsResponse, BlockbookTokenInfo } from './blockbook';
import { calculateUnconfBalance, createTokenKeyring } from '../utils';
import BigNumber from 'bignumber.js';
import { createKeyringId } from '../keyringUtils';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { TokenManagerService } from './token-manager.service';
import { ethers } from 'ethers';
import { ERC20_ABI_GSD_DAO } from '../constants';

export class JsonRpcBody {
  id:string;   
  method: string; 
  params: any[]
}

@Injectable({ providedIn : 'root' })
export class AvalancheService {

  constructor(
    protected http: HttpClient,
    private networkService: NetworkService,
    private rpcProvider: RpcProvider,
    private store: Store<AppState>,
    private tokenManager: TokenManagerService
  )  {}
  
  async getAvaxRpcUrl() {
    const network = await this.networkService.getActiveAvaxNetwork();
    return `${network.URL}`;
  }
  
  async getAvaxAddressApi() {
    const network = await this.networkService.getActiveAvaxNetwork();

    return `${network.API_URL}`;
  }
  
  async getSnowTraceStatus() {
    try {
      const url = await this.getAvaxAddressApi() + '/api?module=stats&action=AVAXsupply&apikey=' + environment.SNOWTRACE_API_KEY;
      const resp = await this.http.get(url).toPromise();
      return true;
    } catch (err: any) {
      return false;
    }
  }
  
  async checkRpcAvalancheService() {
    try {
      const url = await this.getAvaxRpcUrl() + '/ext/bc/C/rpc';
      const resp = await this.rpcProvider.rpc(url, 'eth_chainId', []).toPromise()
      return true;
    } catch (err: any) {
      return false;
    }
  }

  async getAvaxTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const network = await this.networkService.getActiveAvaxNetwork();
      const url = `${network.URL}/ext/bc/C/rpc`;
      const data = await this.rpcProvider.rpc(url, 'eth_getBalance', [address, 'latest']).toPromise()
      const response: BlockbookGetAddressResponse = {};
      response.balance = new BigNumber(data['result']).toString(),
      response.address = address;
      const avaxKeyring = createTokenKeyring('AVAX', address, response['balance'], 'AVAX');
      
      // Add ERC20 Tokens manually
      response.tokens = [];
      let addressErc20wallet = address.substring(2);
      const supportedAssetList = await this.tokenManager.getTokenList();
      const ercContractsList =  Object.keys(supportedAssetList).filter((keyringId: string) => keyringId.indexOf('AVAX-') !== -1);
      for (const erc20Contract of ercContractsList) {
        let contract = erc20Contract.replace('AVAX-', '');
        let erc20Obj = {
          balance: 0,
          contract,
          name:  supportedAssetList[erc20Contract].name,
          symbol: supportedAssetList[erc20Contract].symbol,
          type: 'ERC20'
        }
        const balanceErc20 = await this.rpcProvider.rpc(url, 'eth_call', [{
          "to": `${contract}`,
          "data": `0x70a08231000000000000000000000000${addressErc20wallet}`
        }, 'latest']).toPromise()
        erc20Obj['balance'] = balanceErc20 !== undefined ? new BigNumber(balanceErc20['result']).toNumber() : 0;
        response.tokens.push(erc20Obj)
      }

      const keyrings = this.deriveTokenKeyrings(response, 'AVAX');
      this.setServiceStatusInStore('avaxRpc', true);
      return  [ ...keyrings, avaxKeyring ];
    } catch(err) {
      this.setServiceStatusInStore('avaxRpc', false);
      return [{}];
    }
  }
  
  async getAvaxTokenHistory(address, page, itemsPerPage, ethFilterIndex, tokenContract) {

    let response: BlockbookGetAddressTxsResponse = {};
    let txs;
    let res;
    if(tokenContract !== null && tokenContract !== undefined) {
      res = await this.http
      .get(`${await this.getAvaxAddressApi()}/api?module=account&action=tokentx&contractaddress=${tokenContract}&address=${address}&page=${page}&offset=${itemsPerPage}&sort=desc&apikey=${environment.SNOWTRACE_API_KEY}`)
      .toPromise();
      txs = res['result'];
    } else {
      res = await this.http
      .get(`${await this.getAvaxAddressApi()}/api?module=account&action=txlist&address=${address}&startblock=1&endblock=99999999&page=${page}&offset=${itemsPerPage}&sort=desc&apikey=${environment.SNOWTRACE_API_KEY}`)
      .toPromise();
      txs = res['result'];
    }

    // Filter the non AVAX tokens
    response.transactions = txs;
    response.page = page;
    return { keyringId: createKeyringId('AVAX', tokenContract), ...response };
  }
  
  async getGsdDaoPrice() {
    try {
      const network = await this.networkService.getActiveAvaxNetwork();
      const url = `${network.URL}/ext/bc/C/rpc`;
      let avaxProvider = new ethers.providers.JsonRpcProvider(url);
      const avaxContract = new ethers.Contract(network.GSD_ORACLE_ADDRESS, ERC20_ABI_GSD_DAO, avaxProvider);
      let decimalFeed = await avaxContract.FEED_DECIMALS();
      let balance = await avaxContract.aggregate()
      let price = balance.currentPrice / 10**decimalFeed;
      return price;
    } catch (error) {
      return 0;
    }
  }
  
  deriveTokenKeyrings(response, baseChain: 'SYS' | 'ETH' | 'BTC' | 'AVAX'): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach(token => {
      if (token === null) {
        return;
      }

      let balance;
      balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);

      const keyring = createTokenKeyring(token.symbol, response.address, balance, baseChain, token.contract || token.assetGuid, undefined, token.decimals);
      keyrings.push(keyring);
    });

    return keyrings;
  }
  
  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }

}
