import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { Logger } from './logger.service';
import { Storage } from '@ionic/storage-angular';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public selected = '';
  // only languages we actually have files for should be in this object
  private supportedLanguages = [
    { text: 'English', value: 'en', img: 'assets/imgs/en.png' },
    { text: 'Français', value: 'fr', img: 'assets/imgs/fr.png' },
    { text: 'Español', value: 'es', img: 'assets/imgs/es.png' }
  ];

  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private plt: Platform
  ) { }

  async setInitialAppLanguage() {
    let language = '';
    try {
      language = await this.storage.get(LNG_KEY)
    } catch(err) {
      language = this.translate.getBrowserLang();
      Logger.info('browser language is:', language);
    };

    // see if we have a file for it
    let hasLanguageFile = (this.getLanguages().some(lang => lang.value === language )) ? true : false;

    if (hasLanguageFile) {
      this.translate.setDefaultLang(language);
      this.selected = language;
    } else { // fall back to english
      this.translate.setDefaultLang('en');
      this.selected = 'en';
    }

  }

  getLanguages() {
    return this.supportedLanguages;
  }

  getCurrentLanguage() {
    return this.supportedLanguages.filter(item => item.value === this.selected);
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);
  }
}
