import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  bankAccountActionTypes,
  GetBankAccountBalanceRequest,
  GetBankAccountBalanceError,
  GetBankAccountBalanceSuccess,
  GetBankAccountRequestAction,
  GetBankAccountErrorAction,
  GetBankAccountSuccessAction,
  GetBankAccountTransactionHistorySuccess,
  GetBankAccountTransactionHistoryError,
  GetBankAccountTransactionHistoryRequest
} from '../actions/bankAccount.actions';
import { MembersPortalService } from '../services/members-portal.service';
import { BankAccountService } from '../services/bank-account.service';
import { Logger } from '../services/logger.service';

@Injectable()
export class BankAccountsEffects {

   getBankAccounts$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountRequestAction>(bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let accs;

      try {
        accs = await this.bankAccount.getBankAccounts();
      } catch (err) {
        Logger.error(err);
        return this.store.dispatch(new GetBankAccountErrorAction());
      }

      return this.store.dispatch(new GetBankAccountSuccessAction(accs));
    })
  ), { dispatch: false });

   getBankAccountBalance$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountBalanceRequest>(bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let balance;

      try {
        balance = await this.bankAccount.getBankAccountsBalance();
      } catch (err) {
        Logger.error(err);
        return this.store.dispatch(new GetBankAccountBalanceError());
      }
      Logger.info('BankAccountEffects getBankAccountBalance$', balance);
      return this.store.dispatch(new GetBankAccountBalanceSuccess(balance));
    })
  ), { dispatch: false });

   getTransactionHistory$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountTransactionHistoryRequest>(bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let txs;

      try {
        txs = await this.bankAccount.getTransactionList();
        Logger.info('BankAccountEffects getTransactionHistory$ getTransactionList', txs);
      } catch (err) {
        Logger.error(`getTransactionList Error ${JSON.stringify(err)}`);
        return this.store.dispatch(new GetBankAccountTransactionHistoryError());
      }
      Logger.info('BankAccountEffects getTransactionHistory$ dispatching GetBankAccountTransactionHistorySuccess', txs);
      return this.store.dispatch(new GetBankAccountTransactionHistorySuccess(txs));
    })
  ), { dispatch: false });


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private members: MembersPortalService,
              private bankAccount: BankAccountService) {
  }

}
