import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE, ERC20_ABI, SYSTX } from '../constants';
import { createTokenKeyring, calculateUnconfBalance, weiToNum } from '../utils';
import {
  AssetAllocationAmount,
  RawTransactionVerbose,
  RawTransactionVerboseVin,
  RawTransactionVerboseVout,
  SysTx,
  TokenKeyring
} from '../../global';
import {
  BlockbookGetAddressResponse,
  BlockbookGetAddressTxsResponse,
  BlockbookGetAddressTxsResponseWithWalletData,
  BlockbookTokenInfo,
  BlockbookTokenTransfer,
  BlockbookTransaction,
  BlockbookVin,
  BlockbookVout
} from './blockbook';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { NetworkService } from './network.service';

import BigNumber from 'bignumber.js';
import { createKeyringId } from '../keyringUtils';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { RpcProvider } from './rpc-provider';

@Injectable({
  providedIn: 'root'
})
export class BlockBookService {

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private rpcProvider: RpcProvider,
              private networkService: NetworkService) {
  }

  async getEthAddressApi() {
    const network = await this.networkService.getActiveEthNetwork();

    return `${network.URL}/api/v2/address`;
  }

  async getSysAddressApi() {
    const network = await this.networkService.getActiveSysNetwork();

    return `${network.URL}/api/v2/address`;
  }

  async getBtcAddressApi() {
    const network = await this.networkService.getActiveBtcNetwork();

    return `${network.URL}/api/v2/address`;
  }

  async getEthTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const response: BlockbookGetAddressResponse = await this.http.get(`${await this.getEthAddressApi()}/${address}?details=tokenBalances`).toPromise();
      const ethKeyring = createTokenKeyring('ETH', response.address, calculateUnconfBalance(response.balance, response.unconfirmedBalance), 'ETH');

      if (!response.tokens) {
        // ETH address with no ERC20 balances wont include a tokens array.
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'ETH');
      this.setServiceStatusInStore('ethBB', true);
      return  [ ...keyrings, ethKeyring ];
    } catch(err) {
      this.setServiceStatusInStore('ethBB', false);
      return [{}];
    }
  }

  async getBtcBalance(address): Promise<TokenKeyring[]> {
    try {
      const response: BlockbookGetAddressResponse = await this.http.get(`${await this.getBtcAddressApi()}/${address}?details=tokenBalances`).toPromise();
      const btcKeyring = createTokenKeyring('BTC', response.address, calculateUnconfBalance(response.balance, response.unconfirmedBalance), 'BTC');

      if (!response.tokens) {
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'BTC');
      this.setServiceStatusInStore('btcBB', true);
      return  [ ...keyrings, btcKeyring ];
    } catch (err) {
      this.setServiceStatusInStore('btcBB', false);
      return [{}];
    }
    
  }

  async getEthTokenHistory(address, page, itemsPerPage, ethFilterIndex, tokenContract) {
    let filter = '';
    if (ethFilterIndex) {
      filter = `&filter=${ethFilterIndex}`;
    } else {
      filter = `&filter=0`;
    }
    const response = await this.http
      .get(`${await this.getEthAddressApi()}/${address}?details=txs&pageSize=${itemsPerPage}&page=${page}${filter}`)
      .toPromise();
    return { keyringId: createKeyringId('ETH', tokenContract), ...response };
  }

  async getSysTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const response: BlockbookGetAddressResponse = await this.http.get(`${await this.getSysAddressApi()}/${address}?details=tokenBalances`).toPromise();
      const sysKeyring = createTokenKeyring('SYS', response.address, calculateUnconfBalance(response.balance, response.unconfirmedBalance), 'SYS');

      if (!response.tokens) {
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'SYS');
      this.setServiceStatusInStore('sysBB', true);
      return [ ...keyrings, sysKeyring ];
    } catch (err) {
      this.setServiceStatusInStore('sysBB', false);
      return [{}];
    }
    
  }

  async getBtcHistory(address, page, itemsPerPage) {
    const response: BlockbookGetAddressTxsResponse = await this.http.get(`${await this.getBtcAddressApi()}/${address}?details=txslight&pageSize=${itemsPerPage}&page=${page}`).toPromise();

    if (!response.transactions) {
      response.transactions = [];
    }

    const result: BlockbookGetAddressTxsResponseWithWalletData = { ...response, keyringId: createKeyringId('BTC', '') };
    return result;
  }

  async getSysTokenHistory(address, page, itemsPerPage, assetGuid): Promise<BlockbookGetAddressTxsResponseWithWalletData> {
    let filter = '';
    if (assetGuid) {
      filter = `&contract=${assetGuid}`;
    }
    // else {
    //   filter = `&filter=0` // Only SYS txs
    // }
    const response: BlockbookGetAddressTxsResponse = await this.http.get(`${await this.getSysAddressApi()}/${address}?details=txslight&pageSize=${itemsPerPage}&page=${page}${filter}`).toPromise();

    if (!response.transactions) {
      response.transactions = [];
    }

    const result: BlockbookGetAddressTxsResponseWithWalletData = { ...response, keyringId: createKeyringId('SYS', assetGuid) };
    return result;
  }

  deriveTokenKeyrings(response, baseChain: 'SYS' | 'ETH' | 'BTC' | 'AVAX' | 'SOL'): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach(token => {
      if (token === null) {
        return;
      }

      let balance;
      balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);

      const keyring = createTokenKeyring(token.symbol, response.address, balance, baseChain, token.contract || token.assetGuid, undefined, token.decimals);
      keyrings.push(keyring);
    });

    return keyrings;
  }

  convertToCoreTransactionFormat(bbTx: BlockbookTransaction): RawTransactionVerbose {
    const tx: RawTransactionVerbose = {
      amount: Number(bbTx.value),
      blockhash: bbTx.blockHash,
      blocktime: bbTx.blockTime,
      confirmations: bbTx.confirmations,
      hash: null,
      hex: bbTx.hex,
      in_active_chain: null,
      locktime: null,
      size: null,
      time: bbTx.blockTime,
      txid: bbTx.txid,
      type: null,
      version: bbTx.version,
      vin: bbTx.vin.map(vin => this.convertToCoreVin(vin)),
      vout: bbTx.vout.map(vout => this.convertToCoreVout(vout)),
      vsize: null,
      weight: null,
      systx: bbTx.tokenType ? this.convertToCoreSysTx(bbTx.tokenTransfers[0], bbTx) : null,
      fee: Number(bbTx.fees)
    };

    return tx;
  }

  convertToCoreVin(bbVin: BlockbookVin): RawTransactionVerboseVin {
    return {
      ...bbVin,
      txid: bbVin.txid,
      vout: bbVin.vout,
      scriptSig: null,
      txinwitness: null,
      sequence: bbVin.sequence
    };
  }

  convertToCoreVout(bbVout: BlockbookVout): RawTransactionVerboseVout {
    return {
      value: new BigNumber(bbVout.value).toNumber(),
      n: bbVout.n,
      scriptPubKey: {
        asm: null,
        hex: bbVout.hex,
        reqSigs: null,
        addresses: bbVout.addresses
      },
      assetInfo: bbVout.assetInfo ? bbVout.assetInfo : null
    };
  }

  convertToCoreSysTx(bbTokenTransfer: BlockbookTokenTransfer, bbTx: BlockbookTransaction): SysTx {
    switch (bbTx.tokenType) {
      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_NEW:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_UPDATE:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_TRANSFER:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_SEND:
      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_ALLOCATION_SEND:
        return {
          txtype: bbTx.tokenType === BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_SEND ? SYSTX.ASSET_SEND : SYSTX.ASSET_ALLOCATION_SEND,
          asset_allocation: `${bbTokenTransfer.token}-${bbTokenTransfer.from}`,
          asset_guid: parseFloat(bbTokenTransfer.token),
          symbol: bbTokenTransfer.symbol,
          txid: bbTx.txid,
          height: bbTx.blockHeight,
          sender: bbTx.vin[0].addresses[0], //bbTokenTransfer.from,
          allocations: bbTx.vout.filter(vo => (vo.isAddress)).map(vo => this.convertToCoreAssetAllocation(vo)),
          total: Number(bbTokenTransfer.totalAmount),
          blockhash: bbTx.blockHash,
          aux_fee: bbTokenTransfer.fee
        };
    }

    return null;
  }

  convertToCoreAssetAllocation(vo: BlockbookVout): AssetAllocationAmount {
    return {
      address: vo.addresses[0],
      amount: vo.assetInfo ? Number(vo.assetInfo.value) : Number(vo.value) };
  }
  
  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
}
