import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KycstatusModalComponent } from './kycstatus-modal.component';
import { MaxDecimalsModule } from '../../angular-wallet-base/directives/max-decimal-digits/max-decimals.module';
import { NgxKjuaModule } from 'ngx-kjua';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [KycstatusModalComponent],
  exports: [
    KycstatusModalComponent
  ],
  imports: [BrowserModule, CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, MaxDecimalsModule, NgxKjuaModule]
})
export class KycModalModule {}
