import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import { Logger } from './services/logger.service';
import { catchError } from "rxjs/operators";

export class CustomTranslateLoader implements TranslateLoader {

  private s3Url = 'https://s3-us-west-2.amazonaws.com/dl.lode.one/mobile_tos/';

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const bundledSource = new TranslateHttpLoader(this.http, 'assets/i18n/', '.json').getTranslation(lang);
    const remoteSource = new TranslateHttpLoader(this.http, this.s3Url, '.json').getTranslation(lang);
    const remoteFallbackSource = new TranslateHttpLoader(this.http, this.s3Url, '.json').getTranslation('en');
    const remoteFlag = false;

    return forkJoin(
      bundledSource.pipe(catchError(e => of(e))),
      remoteSource.pipe(catchError(e => of(e))),
      remoteFallbackSource.pipe(catchError(e => of(e)))
    ).pipe(
      map(([bundled, remote, fallback]) => {
        if (!remote.error && remoteFlag) { // if we fetched remote TOS without error, merge data
          Logger.info(`Fetched remote TOS for ${lang}`);
          bundled = { ...bundled, setup: { ...(bundled as any).setup, ...remote}};
        } else if (!fallback.error && remoteFlag) {
          Logger.info(`Failed to fetch TOS for lang ${lang}, falling back to en TOS`);
          bundled = { ...bundled, setup: { ...(bundled as any).setup, ...fallback}};
        } else {
          Logger.info('Failed to fetch TOS!');
        }
        Logger.info('Merged lang:', bundled);
        return bundled;
      })
    );
  }
}
