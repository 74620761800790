import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { NetworkService } from './network.service';
import { GasStationService } from './gas-station.service';
import { VirtualDebitCardService } from './virtual-debit-card.service';
import { MembersPortalService } from './members-portal.service';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { WebSocketAddressNotifierService } from './WebSocketAddressNotifier.service';
import { GetTokenBalancesRequestAction } from '../actions/wallet.actions';
import { getTokenKeyring } from '../store/wallet';
import { getServiceStatus } from '../store/connection';
import { timeout } from 'rxjs/operators';
import { BankAccountService } from './bank-account.service';
import { getBankAccountsAndBalances } from '../utils';
import { environment } from '../../../environments/environment';
import { AvalancheService } from './avalanche.service';
import { SolanaService } from './solana.service';

interface StatusesAPIResponse {
  gasStation: boolean;
  vdcServer: boolean;
  lodeSysBB: boolean;
  bcfSysBB: boolean;
  btcBB: boolean;
  ethBB: boolean;
  sysBlockCount?: number;
  avaxSnowTrace: boolean;
  avaxRpc: boolean;
  solRpc: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceCheckerService {
  
  private servicesInterval;
  private CHECK_SERVICES_INTERVAL = 120000; // check services every 2 mins
  
  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private network: NetworkService,
              private gasStation: GasStationService,
              private vdcServer: VirtualDebitCardService,
              private membersPortal: MembersPortalService,
              private bankAccountService: BankAccountService,
              private avalancheService: AvalancheService,
              private solanaService: SolanaService,
              private wsService: WebSocketAddressNotifierService) {
    
  }
  
  // check services every X milliseconds
  setCheckServicesInterval() {
    if(!this.servicesInterval) {
      this.servicesInterval = setInterval(async () => {
        await this.updateAllServices(true);
      }, this.CHECK_SERVICES_INTERVAL)
    }
  }
  
  closeCheckServicesInterval() {
    if(this.servicesInterval) {
      clearInterval(this.servicesInterval);
    }
  }

  fetchStatuses(): Promise<Object> {
    return this.http.get(environment.EXTERNAL_APIS.STATUS_CHECK_API + '/status').toPromise();
  }

  async checkVdcService(): Promise<boolean> {
    try {
      const statuses = await this.fetchStatuses() as StatusesAPIResponse;

      return statuses.vdcServer;
    } catch(err) {
      return false;
    }
  }

  async checkGasStationService(): Promise<boolean> {
    try {
      const statuses = await this.fetchStatuses() as StatusesAPIResponse;

      return statuses.gasStation;
    } catch(err) {
      return false;
    }
  }
  
  async checkBankAccountService(): Promise<boolean> {
    // no need for trycatch, thats handled by getACHStatus()
    const status = this.bankAccountService.getACHStatus();

    return status;
  }

  async checkBBService(coin: 'sys'|'btc'|'eth'): Promise<boolean> {
    const parsedCoin = coin.toString().toLocaleLowerCase();
    let statuses = await this.fetchStatuses() as StatusesAPIResponse;

    switch(parsedCoin) {
      case 'sys':
        return statuses.lodeSysBB;
      case 'btc':
        return statuses.btcBB;
      case 'eth':
        return statuses.ethBB;
      default:
        return false;
    }
  }

  async checkMemberPortalService(): Promise<boolean> {
    try {
      await this.membersPortal.getPublicInfo();
      return true;
    } catch(err) {
      return false;
    }
  }
  
  async checkSnowTraceService(): Promise<boolean> {
    const status = this.avalancheService.getSnowTraceStatus();
    return status;
  }
  
  async checkRpcAvalancheService(): Promise<boolean> {
    const status = this.avalancheService.checkRpcAvalancheService();
    return status;
  }
  
  async checkRpcSolanaService(): Promise<boolean> {
    const status = this.solanaService.checkRpcSolanaService();
    return status;
  }
  
  async checkWebSocketServices(service: 'sys'|'eth'|'spt'|'avax'|'sol'): Promise<boolean> {
    return await this.wsService.getWebSocketStatus(service);
  }
  

  async updateService(service: 'sys'|'eth'|'btc'|'gas-station'|'vdc'|'memberPortal'|'websocketETH'|'websocketSYS'|'websocketSPT'|'websocketAVAX'|'websocketSOL'|'bank-account'|'snowTrace'|'avaxRpc'|'solRpc') {
    let parsedService;
    
    const services: any = {};
    let selected;

    switch(service.toLocaleLowerCase()) {
      case 'sys':
        parsedService = 'sysBB';
        selected = 'sys';
        break;
      case 'eth':
        parsedService = 'ethBB';
        selected = 'eth';
        break;
      case 'btc':
        parsedService = 'btcBB';
        selected = 'btc';
        break;
      default:
    }

    if (parsedService && parsedService.indexOf('BB') !== -1) {
      services[parsedService] = await this.checkBBService(selected.toLocaleLowerCase());
    }

    if (service === 'gas-station') {
      services.gasStation = await this.checkGasStationService();
    }

    if (service === 'vdc') {
      services.vdcServer = await this.checkVdcService();
    }
    
    if (service === 'memberPortal') {
      services.memberPortal = await this.checkMemberPortalService();
    }
    
    if (service === 'websocketETH') {
      services.websocketETH = await this.checkWebSocketServices('eth');
    }
    
    if (service === 'websocketSYS') {
      services.websocketSYS = await this.checkWebSocketServices('sys');
    }
    
    if (service === 'websocketSPT') {
      services.websocketSPT = await this.checkWebSocketServices('spt');
    }
    
    if (service === 'websocketAVAX') {
      services.websocketAVAX = await this.checkWebSocketServices('avax');
    }
    
    if (service === 'websocketSOL') {
      services.websocketSOL = await this.checkWebSocketServices('sol');
    }
    
    if (service === 'bank-account') {
      services.bankAccount = await this.checkBankAccountService();
    }
    
    if (service === 'snowTrace') {
      services.avaxSnowTrace = await this.checkSnowTraceService();
    }

    if (service === 'avaxRpc') {
      services.avaxRpc = await this.checkRpcAvalancheService();
    }
    
    if (service === 'solRpc') {
      services.solRpc = await this.checkRpcSolanaService();
    }
    this.store.dispatch(new SetServiceStatusAction(services));
  }
  
  
  async updateWebSocketServices() {
    const services: any = {};
    services.websocketETH = await this.checkWebSocketServices('eth');
    services.websocketSYS = await this.checkWebSocketServices('sys');
    services.websocketSPT = await this.checkWebSocketServices('spt');
    services.websocketAVAX = await this.checkWebSocketServices('avax');
    services.websocketSOL = await this.checkWebSocketServices('sol');
    this.store.dispatch(new SetServiceStatusAction(services));
    return services;
  }
  
  async updateAllServices(inInterval = false) {
    const services: any = {};
    const servicesOld = await this.store.select(getServiceStatus).first().toPromise();
    
    services.sysBB = await this.checkBBService('sys');
    services.ethBB = await this.checkBBService('eth');
    services.btcBB = await this.checkBBService('btc');
    services.gasStation = await this.checkGasStationService();
    services.vdcServer = await this.checkVdcService();
    services.memberPortal = await this.checkMemberPortalService();
    services.websocketETH = await this.checkWebSocketServices('eth');
    services.websocketSYS = await this.checkWebSocketServices('sys');
    services.websocketSPT = await this.checkWebSocketServices('spt');
    services.websocketAVAX = await this.checkWebSocketServices('avax');
    services.websocketSOL = await this.checkWebSocketServices('sol');
    services.bankAccount = await this.checkBankAccountService();
    services.avaxSnowTrace = await this.checkSnowTraceService();
    services.avaxRpc = await this.checkRpcAvalancheService();
    services.solRpc = await this.checkRpcSolanaService();

    this.store.dispatch(new SetServiceStatusAction(services));
    
    if(inInterval) {
      console.log('Loading services status');
      this.updateBalancesIfNotAvailableBefore(services, servicesOld);
    } 

    return services;
  }
  
  updateBalancesIfNotAvailableBefore(services, servicesOld) {
    Object.entries(services).forEach(async (entry) => {
      const [key, value] = entry;
      const service: string = this.parsedBBKey(key);
      if(service !== '') {
        if(servicesOld[key] !== value && value === true) {
          const info = await this.store.select(getTokenKeyring, { keyringId: service }).first().toPromise();
          console.log('updating balances: ' + key);
          this.store.dispatch(new GetTokenBalancesRequestAction({
            chains: [{
              symbol: service,
              address: info ? info.address : ''
            }]
          }))
        }
      }
    });
  }
  
  private parsedBBKey(key) {
    switch (key) {
      case 'sysBB':
        return 'SYS';
      case 'ethBB':
        return 'ETH';
      case 'btcBB':
        return 'BTC';
      case 'vdcServer':
        return 'debit-card';
      case 'avaxSnowTrace':
        return 'AVAX';
      case 'avaxRpc':
        return 'AVAX';
      case 'solRpc':
        return 'SOL';
      default:
        return '';
    }
  }

}
