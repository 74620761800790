import { Injectable } from '@angular/core';
import { ajax } from 'rxjs/ajax';
import { Logger } from './logger.service';
import { AGX_KEYRING_ID, AUX_KEYRING_ID, EXTERNAL_APIS, SYS_PER_ASSET_TX } from '../constants';
import { Store } from '@ngrx/store';
import { AppState, getPriceOracleState } from '../store/appState';
import { PriceOracleState } from '../store/priceOracle';
import { SetPriceOracleDataAction } from '../actions/priceOracle.actions';
import BigNumber from 'bignumber.js';
import { AvalancheService } from './avalanche.service';

@Injectable({
  providedIn: 'root'
})
export class PriceOracleService {
  private url = EXTERNAL_APIS.MEMBERS_PORTAL + '/publicinfo';
  private cacheTimeoutMinutes = 0.5; // minutes
  private priceOracleCache: PriceOracleState;
  private lastUpdateTime: number = 0; // track last update time outside of ngrx lifecycle
  private refreshCachePromise;
  private refreshCachePromiseResolved = false;

  constructor(protected store: Store<AppState>, private avalancheService: AvalancheService) {
    this.store.select(getPriceOracleState).subscribe(state => {
      this.priceOracleCache = state;
    });
  }

  public refreshCache(forceRefresh = false) {
    if (!this.refreshCachePromise || this.refreshCachePromiseResolved) {
      const timeoutMs = this.cacheTimeoutMinutes * 60 * 1000;
      if ((this.lastUpdateTime < (Date.now() - timeoutMs)) || forceRefresh) {
        Logger.info('Price oracle cache MISS');
        this.refreshCachePromiseResolved = false;
        this.lastUpdateTime = Date.now();
        this.refreshCachePromise = new Promise(async resolve => {
          const priceOracleResponse: any = await ajax.getJSON(this.url).toPromise();
          const oracleState: PriceOracleState = {
            agxMarkup: priceOracleResponse.data.agx_rate,
            auxMarkup: priceOracleResponse.data.aux_rate,
            agxRates: priceOracleResponse.data.oracle.agx,
            auxRates: priceOracleResponse.data.oracle.aux,
            silverRates: priceOracleResponse.data.oracle,
            lodeRate: priceOracleResponse.data.lode_rate,
            tx_fee: priceOracleResponse.data.tx_fee,
            lastUpdated: this.lastUpdateTime
          };
          this.priceOracleCache = oracleState;
          this.store.dispatch(new SetPriceOracleDataAction({data: oracleState}));
          resolve(oracleState);
          this.refreshCachePromiseResolved = true;
        });

        return this.refreshCachePromise;
      } else {
        Logger.info('Price oracle cache HIT');
      }
    } else {
      Logger.info('> MISS wait for existing promise');
      return this.refreshCachePromise;
    }
  }

  async getSpotPrice(asset: 'agx' | 'aux' | 'all', forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      if (asset === 'all') {
        return this.priceOracleCache;
      } else {
        return asset === 'agx' ? this.priceOracleCache.agxRates.quotes : this.priceOracleCache.auxRates.quotes;
      }
    } catch (e) {
      Logger.info(`Oracle query failed for asset ${asset}`);
    }
  }

  async getMarkup<O>(asset: 'agx' | 'aux' | 'all', forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      if (asset === 'all') {
        return {agx: this.priceOracleCache.agxMarkup, aux: this.priceOracleCache.auxMarkup};
      } else {
        return asset === 'agx' ? this.priceOracleCache.agxMarkup : this.priceOracleCache.auxMarkup;
      }
    } catch (e) {
      Logger.info(`${asset} markup price failed`);
    }
  }

  async getRetailFiatValue(asset: 'agx' | 'aux') {
    const spot: any = await this.getSpotPrice(asset, true);
    const markup: any = await this.getMarkup(asset, true);
    
    return new BigNumber(spot[0].lastPrice).multipliedBy(markup);
  }

  async getBuyBackPrice(baseCurrency: string,asset: 'agx' | 'aux') {
    let buyBackValue = 0;
    switch (asset) {
      case 'agx':
        try {
          const entry = this.priceOracleCache.agxRates.quotes.find(item => item.currency.toLocaleLowerCase() === baseCurrency.toLocaleLowerCase());
          buyBackValue = parseFloat(entry['buyBackPrice']);
        } catch (e) {
          Logger.info('Error querying oraclePriceService for buy back price of asset: ', asset);
        }
        break;
      case 'aux':
        try {
          const entry = this.priceOracleCache.auxRates.quotes.find(item => item.currency.toLocaleLowerCase() === baseCurrency.toLocaleLowerCase());
          buyBackValue = parseFloat(entry['buyBackPrice']);
        } catch (e) {
          Logger.info('Error querying oraclePriceService for buy back price of asset: ', asset);
        }
        break;
      default:
        break;
    }
    return buyBackValue;
  }

  async getSysFee(forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      return this.priceOracleCache.tx_fee;
    } catch (e) {
      Logger.info('fetch SYS TX fee failed. Falling back to constant: ', SYS_PER_ASSET_TX);
      return SYS_PER_ASSET_TX;
    }
  }

  async getValueInBaseCurrency(tokenSymbols, baseCurrencyId: string[], forceRefresh: boolean = false): Promise<any> {
    await this.refreshCache(forceRefresh);
    const response = {};
    const baseCurrency = baseCurrencyId.join(',').toLocaleLowerCase();

    await tokenSymbols.forEachAsync(async (keyringId) => {
      switch (keyringId) {
        case AGX_KEYRING_ID:
        case 'PT-AGX':
        case 'HL-AGX':
        case 'AVAX-0x648860804E3f2778b28B5760ED1D5d1afdA2C2aC':
        case 'AVAX-0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8':
        case 'SOL-Czh5EHJXZzHMiUeQwY1ZcJUpaf1yBpetdKswMx6NVAhT':
        case 'SOL-CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v':
          try {
            const entry = this.priceOracleCache.agxRates.quotes.find(item => item.currency.toLocaleLowerCase() === baseCurrency);
            response[keyringId] = parseFloat(entry.lastPrice);
          } catch (e) {
            Logger.info('Error querying oraclePriceService for ', keyringId);
          }
          break;

        case AUX_KEYRING_ID:
        case 'PT-AUX':
        case 'AVAX-0x37F52e650ac21354Aca33A21366986CDf03a3670':
        case 'AVAX-0x70616a337433a9207fb5c43067c6785aba671c0b':
        case 'SOL-3QjcGDn3nJbnt1KZvC3RRNCN3v6gQ14aykk8eCzoLc6X':
        case 'SOL-Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L':
          try {
            const entry = this.priceOracleCache.auxRates.quotes.find(item => item.currency.toLocaleLowerCase() === baseCurrency);
            response[keyringId] = parseFloat(entry.lastPrice);
          } catch (e) {
            Logger.info('Error querying oraclePriceService for ', keyringId);
          }
          break;

        case 'PT-LODE':
        case 'HL-LODE':
        case 'AVAX-0x9cCed5a8994DaeFc4b65429Fa92f84eDB50Ecc5c':
        case 'AVAX-0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1':
        case 'SOL-BKzgKX1xskUMj99rR24z2h775dgF9Eju7H9YmSsFrkDn':
        case 'SOL-H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR':
          const entry = this.priceOracleCache.silverRates.quotes.find(item => item.currency.toLocaleLowerCase() === baseCurrency);
          response[keyringId] = new BigNumber(this.priceOracleCache.lodeRate).multipliedBy(entry.lastPrice).toNumber();
          break;

        case 'AVAX-0xe0285fc6a60daa223219fb01d78b3baee133017c':
        case 'AVAX-0xcc871dbbc556ec9c3794152ae30178085b25fa26':
          const entryGsd = await this.avalancheService.getGsdDaoPrice();
          response[keyringId] = parseFloat(entryGsd.toString());
          break;

        default:
          break;
      }
    });

    return {
      baseCurrency,
      tx_fee: this.priceOracleCache.tx_fee,
      fiatValues: response
    };
  }
}
