import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { DB_NAME } from '../db_name';

import { rootReducer } from './angular-wallet-base/reducers/rootReducer';
import { EffectsModule } from '@ngrx/effects';
import { WalletEffects } from './angular-wallet-base/effects/wallet.effects';
import { WalletService } from './angular-wallet-base/services/wallet.service';
import { CryptoTransferService } from './angular-wallet-base/services/crypto-transfer.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CustomMissingTranslationHandler } from './missingTranslationHandler';
import { NotTranslatedService } from './angular-wallet-base/services/nottranslated.service';
import { WalletGuardService } from './angular-wallet-base/services/walletguard.service';
import { NetworkService } from './angular-wallet-base/services/network.service';
import { BankAccountService } from './angular-wallet-base/services/bank-account.service';
import 'arraync';
import { CoinGeckoService } from './angular-wallet-base/services/coingecko.service';
import { SubscriptionListenerComponent } from './angular-wallet-base/SubscriptionListenerComponent';
import { UserPreferencesEffects } from './angular-wallet-base/effects/userPreferences.effects';
import { SetupEffects } from './angular-wallet-base/effects/setup.effects';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { QRCodeModule } from 'angularx-qrcode';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SideMenuComponent } from './components/sidemenu/sidemenu.component';
import { HTTP } from '@ionic-native/http/ngx';
import { SendTokenEffects } from './angular-wallet-base/effects/sendToken.effects';
import { GasStationEffects } from './angular-wallet-base/effects/gasStation.effects';
import { VirtualDebitCardEffects} from './angular-wallet-base/effects/virtualDebitCard.effects';
import { DevicesListEffects } from './angular-wallet-base/effects/devicesList.effects';
import { BankAccountsEffects } from './angular-wallet-base/effects/bankAccount.effects';
import { TransactionSenderService } from './angular-wallet-base/services/transaction-sender.service';
import { WebSocketAddressNotifierService } from './angular-wallet-base/services/WebSocketAddressNotifier.service';
import { TokenManagerService } from './angular-wallet-base/services/token-manager.service';
import { BiometricAuthService } from './angular-wallet-base/services/biometric-auth.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { CustomTranslateLoader } from './angular-wallet-base/CustomTranslateLoader';
import { CodePush } from '@ionic-native/code-push/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NgxKjuaModule } from 'ngx-kjua';
import { TokenDefinitionService } from './angular-wallet-base/services/token-definition.service';
import { BlockbookTxNotifierConfigService, BlockbookTxNotifierService } from 'blockbook-tx-notifier';
import { SyscoinWebsocketConfigService, SyscoinWebsocketService } from 'syscoin-websocket';

import { TwoFaModule } from './components/two-fa/two-fa.component.module';
import { TwoFaComponent } from './components/two-fa/two-fa.component';
import { UpdateRequiredModalComponent } from './components/update-required-modal/update-required-modal.component';
import { UpdateRequiredModalModule } from './components/update-required-modal/update-required-modal.component.module';
import { PriceOracleService } from './angular-wallet-base/services/price-oracle.service';
import { CodePushModalComponent } from './components/codepush-modal/codepush-modal.component';
import { CodePushModalModule } from './components/codepush-modal/codepush-modal.component.module';
import { StagingModeComponent } from './components/staging-mode/staging-mode.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AppModeService } from './angular-wallet-base/services/app-mode.service';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AnalyticsTrackerService } from './angular-wallet-base/services/analytics-tracker.service';
import { MembersPortalService } from './angular-wallet-base/services/members-portal.service';
import { SettingsGuardService } from './angular-wallet-base/services/settingsguard.service';
import { CustomCurrencyPipeModule } from './angular-wallet-base/pipes/customcurrency/customcurrency.module';
import { ServiceCheckerService } from './angular-wallet-base/services/service-check.service';
import { StorageService } from './angular-wallet-base/services/storage.service';
import { NgxPlaidLinkModule } from "ngx-plaid-link";
import { PreviousRouteService } from './angular-wallet-base/services/previousroute.service';
import { SmallToCryptoPipe } from './angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { KycModalModule } from './components/kycstatus-modal/kycstatus-modal.module';
import { ComplianceService } from './angular-wallet-base/services/compliance.service';

@NgModule({
  declarations: [
    AppComponent,
    SubscriptionListenerComponent,
    SideMenuComponent,
    StagingModeComponent,
    TopbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPlaidLinkModule,
    KycModalModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    IonicStorageModule.forRoot({
      name: DB_NAME,
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
        deps: [NotTranslatedService]
      }
    }),
    EffectsModule.forRoot([
      WalletEffects,
      UserPreferencesEffects,
      SetupEffects,
      SendTokenEffects,
      GasStationEffects,
      VirtualDebitCardEffects,
      DevicesListEffects,
      BankAccountsEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NgxKjuaModule,
    TwoFaModule,
    UpdateRequiredModalModule,
    CodePushModalModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    CustomCurrencyPipeModule,
    NgxPlaidLinkModule
  ],
  providers: [
    File,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    PriceOracleService,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    WalletService,
    CryptoTransferService,
    BarcodeScanner,
    QRCodeModule,
    Clipboard,
    WalletGuardService,
    SettingsGuardService,
    CoinGeckoService,
    HTTP,
    CoinGeckoService,
    TransactionSenderService,
    WebSocketAddressNotifierService,
    ScreenOrientation,
    TokenDefinitionService,
    BiometricAuthService,
    FingerprintAIO,
    BlockbookTxNotifierService,
    BlockbookTxNotifierConfigService,
    SyscoinWebsocketService,
    SyscoinWebsocketConfigService,
    NetworkService,
    TokenManagerService,
    CodePush,
    InAppBrowser,
    AppModeService,
    AnalyticsTrackerService,
    MembersPortalService,
    ServiceCheckerService,
    BankAccountService,
    StorageService,
    PreviousRouteService,
    SmallToCryptoPipe,
    FileTransfer,
    FileOpener, 
    DocumentViewer,
    ComplianceService
  ],
  bootstrap: [AppComponent],
  entryComponents: [TwoFaComponent, UpdateRequiredModalComponent, CodePushModalComponent, TopbarComponent]
})
export class AppModule {}
