import { Action } from '@ngrx/store';
import { GasStationState } from '../store/gasStation';
import { SetSendTokenDataAction, GasStationActionTypes } from '../actions/gasStation.actions';


export const initialGasStationState = {
  address: '',
  assetGuid: 0,
  amount: 0
};

export function gasStationReducer(state: GasStationState = initialGasStationState, action: Action): GasStationState {
  let payload;
  switch (action.type) {
    case GasStationActionTypes.SET_GAS_STATION_SEND_DATA:
      payload = (action as SetSendTokenDataAction).payload;
      return { ...state, address: payload.address, amount: payload.amount, assetGuid: payload.assetGuid };

    default:
      return state;
  }
}
