import { Action } from '@ngrx/store';
import { AppSettingsActionTypes } from '../actions/appSettings.actions';
import { AppSettingsState } from '../store/appSettings';


export const initialAppState = {
  pageTitle: '',
  pageSecondaryTitle: '',
  useI18nOnTitle: true,
  forceUpgrade: false
};

export function appSettingsReducer(state: AppSettingsState = initialAppState, action: Action): AppSettingsState {
  switch (action.type) {
    case AppSettingsActionTypes.CHANGE_TITLE:
      return {  ...state, pageTitle: (action as any).payload };
    
    case AppSettingsActionTypes.CHANGE_SECONDARY_TITLE:
      return { ...state, pageSecondaryTitle: (action as any).payload };

    case AppSettingsActionTypes.USE_I18N_ON_TITLE:
      return { ...state, useI18nOnTitle: (action as any).payload };

    case AppSettingsActionTypes.FORCE_UPGRADE:
      return {  ...state, forceUpgrade: (action as any).payload };

    default:
      return state;
  }
};
