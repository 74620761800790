import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kyc-modal',
  templateUrl: './kycstatus-modal.component.html',
  styleUrls: ['./kycstatus-modal.component.scss'],
})

export class KycstatusModalComponent implements OnInit {
  @Input() amlStatus: boolean;
  @Input() cipStatus: boolean;
  @Input() idStatus: boolean;
  @Input() poaStatus: boolean;
  @Input() last_updated: any;
  constructor(
    private modal: ModalController,   
    private translate: TranslateService,
    private router: Router
  ) {

  }

  ngOnInit() {
  }


  async cancelKYC() {
    this.modal.dismiss();
  }

  goToUploadDocs() {
    let navigationExtras : NavigationExtras = {
      state: {
        tab : 'wallet' 
      }
    }
    this.router.navigate(['wallet/settings'], navigationExtras);
    this.modal.dismiss();
  }

}
