import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthSendTokensSuccessAction, SendTokenActionTypes, SendTokensSuccessAction } from '../actions/sendToken.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { TransactionSenderService } from '../services/transaction-sender.service';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, NavController } from '@ionic/angular';
import { parseSyscoinError, parseEthereumError } from '../utils';
import { TranslateService } from '@ngx-translate/core';
import { ethers } from 'ethers';
import { ERC20_ABI, GAS_LIMIT_SEND, GAS_LIMIT_ERC20_SEND, ERC20_ABI_AVALANCHE } from '../constants';
import { NetworkService } from '../services/network.service';
import { BankAccountService } from '../services/bank-account.service';
import { AppModeService } from '../services/app-mode.service';
import { GetTokenBalancesRequestAction } from '../actions/wallet.actions';

@Injectable()
export class SendTokenEffects {
   authSendTokens$ = createEffect(() => this.actions$.pipe(
    ofType<AuthSendTokensSuccessAction>(SendTokenActionTypes.AUTH_SEND_TOKENS_SUCCESS),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('effect');
      let baseChainSymbol;
      let guid;

      try {
        baseChainSymbol = store.sendToken.tokenInfo.baseChainSymbol;
        guid = store.sendToken.tokenInfo.guid;
      } catch (err) {
        baseChainSymbol = 'PT';
        guid = store.bankAccount.send.data.token;
      }
      
      switch (baseChainSymbol) {
        case 'SYS':
        case 'BTC':
          await this.sysBtcSendTransaction(action, store);
          break;
        case 'ETH':
          await this.ethereumSendTransaction(action, store);
          break;
        case 'AVAX':
          await this.avaxSendTransaction(action, store);
          break;
        case 'PT':
          await this.primeTrustSendTransaction(action, store);
          break;
        case 'SOL':
          await this.solSendTransaction(action, store);
        default:
          break;
      }

      if (baseChainSymbol === 'PT') {
        return this.nav.navigateForward(['/wallet/prime-trust/', guid],
          {queryParams: {action: 'closeSendForm'}});
      }

      this.appMode.isDesktopMode.subscribe(async (mode) => {
        if (!mode)
        {
          if (guid) {
            await this.nav.navigateForward(['/wallet/assets/', store.sendToken.tokenInfo.baseChainSymbol, store.sendToken.tokenInfo.guid],
              {queryParams: {action: 'closeSendForm'}});
          } else {
            await this.nav.navigateForward(['/wallet/assets/', store.sendToken.tokenInfo.baseChainSymbol],
              {queryParams: {action: 'closeSendForm'}});
          }
        }
      });
    })
  ), { dispatch: false });

  async sysBtcSendTransaction(action, store) {
    try {
      let unsignedTxObj, txid;
      store = (store as AppState);
      if (!store.sendToken.tokenInfo.guid) { // SEND SYSCOIN
        // tslint:disable-next-line
        //unsignedTxObj = await this.transactionSender.getUnsignedTransactionHex(store.sendToken.fromAddress, store.sendToken.toAddress, store.sendToken.amount);
        txid = await this.transactionSender.sendSysBtccoin(store.sendToken.amount, store.sendToken.fromAddress, store.sendToken.toAddress,store.sendToken.memo, action.payload.pin, store.sendToken.tokenInfo.baseChainSymbol);

      } else { // SEND ASSET
        // tslint:disable-next-line
        txid = await this.transactionSender.sendAsset(store.sendToken.amount,store.sendToken.fromAddress, store.sendToken.toAddress, action.payload.pin,store.sendToken.tokenInfo.guid,store.sendToken.memo);
      }
      //Logger.info('hexstr:', unsignedTxObj);
      // tslint:disable-next-line
      Logger.info('txid:', txid);

      this.store.dispatch(new SendTokensSuccessAction({txid}));
    } catch (e) {
      Logger.info('raw err', e);

      if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string') {
        const errMessage = JSON.parse(e.response.data.message)[0].error_msg;

        const alert = await this.alertController.create({
          header: 'Error',
          message: errMessage,
          buttons: ['OK']
        });
        return await alert.present();
      }

      if (e.message.indexOf('txVersion') !== -1) {
        const alert = await this.alertController.create({
          header: 'Error',
          // tslint:disable-next-line
          message: await this.translate.get('tokens.send.errors.txversion', {coin: store.sendToken.tokenInfo.symbol}).toPromise(),
          buttons: ['OK']
        });
        return await alert.present();
      }

      if (e.error && !e.error.error) {
        e.error = JSON.parse(e.error);
      }

      const errObj = parseSyscoinError(e.error.error, store.wallet.keychain['SYS'].balance);
      Logger.info('err', errObj);
      const alert = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errObj ? await this.translate.get(errObj.key, errObj.params).toPromise() : 'An error occured when trying to send tokens.',
        buttons: ['OK']
      });
      await alert.present();
    }

  }
  
  async solSendTransaction(action, store) {
    try {
      
      let txid;
      
      if (!store.sendToken.tokenInfo.guid) { // SEND SOLANA
        txid = await this.transactionSender.sendSolTransaction(store.sendToken.amount, store.sendToken.fromAddress, store.sendToken.toAddress,store.sendToken.memo, action.payload.pin, store.sendToken.tokenInfo.baseChainSymbol);
      } else { // SEND SPL
        txid = await this.transactionSender.sendSolSplTransaction(store.sendToken.amount,store.sendToken.fromAddress, store.sendToken.toAddress, action.payload.pin,store.sendToken.tokenInfo.guid,store.sendToken.memo);
      }
      
      Logger.info('txid:', txid);

      this.store.dispatch(new SendTokensSuccessAction({txid}));
    } catch (e) {
      Logger.info('raw err', e);

      if (e.error && !e.error.error) {
        e.error = JSON.parse(e.error);
      }
      
      // TODO check parserror SOL
      const errObj = parseEthereumError(e.error.error, store.wallet.keychain['SOL'].balance);
      Logger.info('err', errObj);
      const alert = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errObj ? await this.translate.get(errObj.key, errObj.params).toPromise() : 'An error occured when trying to send tokens.',
        buttons: ['OK']
      });
      await alert.present();
    }

  }


  async ethereumSendTransaction(action, store) {
    const network = await this.network.getActiveEthNetwork();
    try {
      store = (store as AppState);
      const nonce = +(await this.transactionSender.getEthNonce(store.sendToken.fromAddress));

      if (!store.sendToken.tokenInfo.guid) { // SEND ETHEREUM
        const tx = {
          chainId: network.chainId,
          gasLimit: GAS_LIMIT_SEND,
          gasPrice: ethers.BigNumber.from(store.sendToken.ethGasFee),
          nonce: ethers.utils.hexlify(nonce),
          to: store.sendToken.toAddress,
          value: ethers.utils.parseEther(store.sendToken.amount.toString())
        };

        const signed = await this.transactionSender.signEthRawTransaction(store.sendToken.fromAddress, tx, action.payload.pin);
        const txid = await this.transactionSender.sendEthRawTransaction(signed);
        Logger.info('signed tx sent; txid:', txid);
        this.store.dispatch(new SendTokensSuccessAction({txid}));
      } else {  // SEND ERC20
        const args = Object.values({
          _to: store.sendToken.toAddress,
          _value: ethers.utils.parseUnits(store.sendToken.amount.toString(), store.sendToken.tokenInfo.precision)
        })
        const iface = new ethers.utils.Interface(ERC20_ABI);
        Logger.info('sendToken Effect: iface', iface);
        const calldata = iface.encodeFunctionData('transfer', args);
        Logger.info('sendToken Effect: calldata', calldata);
        const tx = {
          chainId: network.chainId,
          gasLimit: GAS_LIMIT_ERC20_SEND,      // TODO: investigate if there's a way to know this limit
          gasPrice: ethers.BigNumber.from(store.sendToken.ethGasFee),
          nonce: ethers.utils.hexlify(nonce),
          to: store.sendToken.tokenInfo.guid,
          data: calldata
        };

        const signed = await this.transactionSender.signEthRawTransaction(store.sendToken.fromAddress, tx, action.payload.pin);
        const txid = await this.transactionSender.sendEthRawTransaction(signed);
        this.store.dispatch(new SendTokensSuccessAction({txid}));
      }
    } catch (e) {
      Logger.info('raw err', e);

      if (e.error && !e.error.error) {
        e.error = JSON.parse(e.error);
      }
      // TODO: improve parseEthereumError
      const errObj = parseEthereumError(e.error.error, store.wallet.keychain['ETH'].balance);
      Logger.info('err', errObj);
      const alert = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errObj ? await this.translate.get(errObj.key, errObj.params).toPromise() : 'An error occured when trying to send tokens.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }
  
  async avaxSendTransaction(action, store) {
    const network = await this.network.getActiveAvaxNetwork();
    try {
      store = (store as AppState);
      const nonce = (await this.transactionSender.getAvaxNonce(store.sendToken.fromAddress));
      var gasPrice = ethers.BigNumber.from(store.sendToken.ethGasFee)
      console.log("gas Price avax: ", gasPrice);
      if (!store.sendToken.tokenInfo.guid) { // SEND AVAX
        const tx = {
          chainId: network.chainId,
          from: store.sendToken.fromAddress,
          to: store.sendToken.toAddress,
          gasLimit: GAS_LIMIT_SEND,
          gasPrice: ethers.utils.hexlify(gasPrice),
          nonce: ethers.utils.hexlify(nonce),
          value: ethers.utils.parseEther(store.sendToken.amount.toString())
        }

        const signed = await this.transactionSender.signAvaxRawTransaction(store.sendToken.fromAddress, tx, action.payload.pin);
        const txid = await this.transactionSender.sendAvaxRawTransaction(signed);

        Logger.info('signed tx sent; txid:', txid);
        this.store.dispatch(new SendTokensSuccessAction({txid}));
      } else {  // SEND ERC20
        const args = Object.values({
          _to: store.sendToken.toAddress,
          _value: ethers.utils.parseUnits(store.sendToken.amount.toString(), store.sendToken.tokenInfo.precision)
        })
        // const gasPrice = (await this.transactionSender.getGasPrice());
        // console.log("gas Price avax: ", gasPrice);
        const iface = new ethers.utils.Interface(ERC20_ABI);
        Logger.info('sendToken Effect: iface', iface);
        const calldata = iface.encodeFunctionData('transfer', args);
        Logger.info('sendToken Effect: calldata', calldata);
        const tx = {
          chainId: network.chainId,
          gasLimit: GAS_LIMIT_ERC20_SEND,      // TODO: investigate if there's a way to know this limit
          gasPrice: gasPrice,
          nonce: ethers.utils.hexlify(nonce),
          to: store.sendToken.tokenInfo.guid,
          data: calldata
        };

        const signed = await this.transactionSender.signAvaxRawTransaction(store.sendToken.fromAddress, tx, action.payload.pin);
        const txid = await this.transactionSender.sendAvaxRawTransaction(signed);
        Logger.info('signed tx sent; txid:', txid);
        this.store.dispatch(new SendTokensSuccessAction({txid}));
      }
    } catch (e) {
      Logger.info('raw err', e);

      if (e.error && !e.error.message) {
        e.error = JSON.parse(e.error);
      }
      // TODO: improve parseEthereumError
      const errObj = parseEthereumError(e.error.message, store.wallet.keychain['AVAX'].balance);
      Logger.info('err', errObj);
      const alert = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errObj ? await this.translate.get(errObj.key, errObj.params).toPromise() : 'An error occured when trying to send tokens.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  async primeTrustSendTransaction(action, store) {
    const data = store.bankAccount.send.data;
    let success;

    try {
      success = await this.bankAccount.withdrawAssetToAddress(data.token, data.amount, data.address);
    } catch (err) {
      return Logger.error('Can not send PT transaction. Error: ', err);
    }

    this.store.dispatch(new SendTokensSuccessAction({ txid: 'PT tx' }));
  }

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private transactionSender: TransactionSenderService,
              private nav: NavController,
              private alertController: AlertController,
              private translate: TranslateService,
              private network: NetworkService,
              private appMode: AppModeService,
              private bankAccount: BankAccountService) {
  }

}
