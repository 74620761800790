import { Component, OnInit } from '@angular/core';
import { MembersPortalService } from '../../angular-wallet-base/services/members-portal.service';
import { Platform } from '@ionic/angular';
import { getAuthenticated } from '../../angular-wallet-base/store/wallet';
import { Store } from '@ngrx/store';
import { AppState, getUserPreferencesState } from '../../angular-wallet-base/store/appState';
import { getServiceStatus } from '../../angular-wallet-base/store/connection';
import { openLinkExternal } from '../../angular-wallet-base/utils';
import { environment } from '../../../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { MOBILE_MODE_MAX_WIDTH } from '../../angular-wallet-base/constants';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { ChangeFirstNameAction, UserDataActionTypes } from 'src/app/angular-wallet-base/actions/userData.actions';
import { AppModeService } from '../../angular-wallet-base/services/app-mode.service';

@Component({
  selector: 'sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SideMenuComponent extends SubscriptionListenerComponent implements OnInit {
  private isValidJwt;
  private loggedIn;
  public lodeid: string = '';
  private isLodeEnabled = environment.features.linkLode;
  private isGasStationEnabled = environment.features.gasStation;
  private isComponentSheetEnabled = environment.features.componentSheet;
  public lodeData = null;
  public serviceStatus = {};
  public isDesktopMode;

  public appLinks = [
    {
      id: 'agx_website',
      title: 'link_labels.agx_website',
      icon: 'open'
    },
    {
      id: 'how_to_use',
      title: 'link_labels.how_to_use',
      icon: 'open'
    },
    {
      id: 'faq',
      title: 'link_labels.faq',
      icon: 'open'
    },
    {
      id: 'terms_service',
      title: 'link_labels.terms_service',
      icon: 'open'
    },
    {
      id: 'privacy',
      title: 'link_labels.privacy',
      icon: 'open'
    },
    {
      id: 'contact',
      title: 'link_labels.contact',
      icon: 'open'
    }
  ];
  public appPages = [];

  constructor(private membersPortalService: MembersPortalService,
              protected store: Store<AppState>,
              private actions$: Actions,
              private inAppBrowser: InAppBrowser,
              private translate: TranslateService,
              private appMode: AppModeService,
              private platform: Platform) {
    super(store);
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    this.store.select(getAuthenticated).subscribe(loggedIn => {
      if (!loggedIn) {
        return;
      }
    });
    this.store.select(getServiceStatus).subscribe(statuses => {
      this.serviceStatus = statuses;
    })

    this.store.select(getUserPreferencesState).subscribe(this.handleLodeDataLoad.bind(this));
  }

  ngOnInit() {
    this.addShadowPartToMenu();
  }

  addShadowPartToMenu() {
    // ion-menu has som shadow DOM elements that we need to apply css to. There is no way to access to those elements without dirty tricks
    // in this Ionic version (v4 has shadow parts).
    const ionMenu = document.querySelector('ion-menu');

    if (ionMenu.shadowRoot.firstElementChild) {
      ionMenu.shadowRoot.firstElementChild.setAttribute('part', 'container');
    } else {
      setTimeout(() => this.addShadowPartToMenu(), 200);
    }
  }

  setSideMenu() {
    const appPages = [];

    if (this.isLodeEnabled && this.isValidJwt) {
      appPages.push({
        title: this.isValidJwt ? 'setup.linked_lode_account' : 'setup.link_lode_account',
        url: this.isValidJwt ? '/wallet/settings' : '/link-lode',
        queryParams: { tab: 'profile' },
        direction: 'forward',
        icon: this.isValidJwt ? 'checkmark-circle' : 'link',
        iconColor: this.isValidJwt ? 'secondary' : 'dark',
        lodeid: this.lodeid,
        type: 'lode-avatar'
      });
    }

    if (this.isGasStationEnabled) {
      appPages.push({
        title: 'pages.gas_station',
        url: '/gas-station',
        direction: 'forward',
        icon: 'water',
        type: 'menu-item',
        statusId: 'gasStation',
        page: 'gas'
      });
    }

    appPages.push(
    {
      title: 'pages.buy',
      url: '/wallet/buy-tokens/home',
      direction: 'forward',
      icon: 'cash',
      type: 'menu-item',
      page: 'buy'
    },
    {
      type: 'separator'
    }, {
      type: 'section-title',
      title: 'sidemenu.your_collection'
    }, {
      title: 'pages.assets',
      url: '/wallet/assets',
      direction: 'root',
      icon: 'wallet',
      type: 'menu-item',
      page: 'assets'
    });

    if(environment.features.debitCard) {
      appPages.push({
        title: 'pages.debit_cards',
        url: '/wallet/card',
        direction: 'root',
        icon: 'wallet',
        type: 'menu-item',
        statusId: 'vdcServer',
        page: 'vdc'
      });
    }

    if (this.isComponentSheetEnabled) {
      appPages.push({
        title: 'pages.component_sheet',
        url: '/dev/component-sheet',
        direction: 'forward',
        type: 'menu-item'
      });
    }

    appPages.push({
      type: 'separator'
    }, {
      type: 'section-title',
      title: 'sidemenu.navigation_group'
    }, {
      title: 'pages.settings',
      url: '/wallet/settings',
      direction: 'forward',
      icon: 'settings',
      type: 'menu-item',
      page: 'settings'
    });

    this.appPages = appPages;
  }

  async handleLodeDataLoad(userPreferences) {
    if (!userPreferences || !userPreferences.jwtToken) {
      this.isValidJwt = false;
      this.lodeid = '';
    } else {
      this.lodeid = userPreferences.lodeid;
      this.isValidJwt = await this.membersPortalService.isJwtValid(userPreferences.jwtToken);
    }
    this.getLodeData();
    this.subscriptions.add(this.actions$.pipe(
      ofType(UserDataActionTypes.CHANGE_FIRST_NAME),
      takeUntil(this.destroyed$))
      .subscribe(async (action: ChangeFirstNameAction ) => {
          if (action.payload) {
            this.lodeData.fname=action.payload;
          }
      }));
    await this.setSideMenu();
  }

  public async getLodeData() {
    try {
      this.lodeData = await this.membersPortalService.getUserInfo();
    } catch (err) {
      this.lodeData = null;
    }
  }

  public isIos() {
    return this.platform.is('ios');
  }

  public async openLinkExternal(id) {
    const url = await this.translate.get(`link_labels.links.${id}`).toPromise();
    openLinkExternal(url, this.inAppBrowser);
  }

  public generateAvatarUrl() {
    return 'https://avatar.oxro.io/avatar.svg?&background=687984&color=fff&name=' + (this.lodeData.fname ? this.lodeData.fname.replace(/\s/g, '+') : 'Lode');
  }

  public customDisableMenu(p) {
    var memberPortal = this.serviceStatus['memberPortal'];
    if(memberPortal === false){
      switch (p.page) {
        case 'buy': 
        case 'gas':
        case 'vdc':
          return true;
          break;
      }
    }
    
    return this.serviceStatus.hasOwnProperty(p.statusId) ? !this.serviceStatus[p.statusId] : false;
  }

}
