import HdKeyringController from './hd.keyring.controller';
import { Asset } from '../../../../global';
import { createTokenKeyring } from '../../../utils';
import { hdPaths, initWalletWithMnemonicOpts } from './index';
import syscoinNetworks from '../config/network.config';
import { Logger } from '../../../services/logger.service';
import { createKeyringId } from '../../../keyringUtils';
import { environment } from '../../../../../environments/environment';

const bip39 = (window as any).bip39;
const bitcoin = (window as any).bitcoinjsLib;

export const getKeychain = async (vault, password): Promise<HdKeyringController> => {
  const keychain = new HdKeyringController();
  keychain.vault = vault;
  await keychain.unlock(password);

  return keychain;
};

export const checkPassword = async (vault, password) => {
  const keychain = new HdKeyringController();
  keychain.vault = vault;
  return keychain.isPasswordValid(password);
};

export const generateMnemonic = async () => {
  const mnemonic = bip39.generateMnemonic();
  Logger.info('Generate mnemonic:', mnemonic);
  return mnemonic;
};

export const initWalletWithMnemonic = async (password, mnemonic, isTestnet: initWalletWithMnemonicOpts = {sys: !environment.production, eth: !environment.production, btc: !environment.production, avax: !environment.production, sol: !environment.production}) => {
  // setup wallet seed
  const keychain = new HdKeyringController();
  await keychain.createVault(password);
  const nodes = [
    {
      hdPath: isTestnet.sys ? hdPaths.syscoinTestnet : hdPaths.syscoinMainnet,
      numberOfAccounts: 1,
      network: isTestnet.sys ? syscoinNetworks.testnet : syscoinNetworks.mainnet
    }
  ];

  if (environment.features.eth) {
    nodes.push({
      hdPath: isTestnet.eth ? hdPaths.ethereumTestnetRopsten : hdPaths.ethereumMainnet,
      numberOfAccounts: 1,
      network: null
    })
  }

  if (environment.features.btc) {
    nodes.push({
      hdPath: isTestnet.btc ? hdPaths.bitcoinTestnet : hdPaths.bitcoinMainnet,
      numberOfAccounts: 1,
      network: isTestnet.btc ? bitcoin.networks.testnet : bitcoin.networks.bitcoin
    })
  }
  
  if (environment.features.avax) {
    nodes.push({
      hdPath: isTestnet.avax ? hdPaths.ethereumTestnetRopsten : hdPaths.ethereumMainnet,
      numberOfAccounts: 1,
      network: null
    })
  }
  
  if (environment.features.sol) {
    nodes.push({
      hdPath: isTestnet.sol ? hdPaths.solanaTestnet : hdPaths.solanaMainnet,
      numberOfAccounts: 1,
      network: null
    })
  }

  // default keyring is only SYS
  const defaultKeyring = {
    mnemonic,
    nodes
  };
  await keychain.addNewKeyring(defaultKeyring);

  return keychain;
};

export const deriveSyscoinAssetKeyringsFromExplorerResponse = (address, response, sysKeychain) => {
  let result = { SYS: sysKeychain };
  if (response.asset_balances) {
    Object.values(response.asset_balances).forEach((val: Asset) => {
      if (val.asset_guid) {
        let keychain = createTokenKeyring(val.symbol, address, parseFloat(val.allocation_balance.replace(',', '')), 'SYS', val.asset_guid);
        const syntheticSymbol = createKeyringId('SYS', val.asset_guid);
        result = {...result, [syntheticSymbol]: keychain};
      }
    });
  }

  return result;
};

