import { Action } from '@ngrx/store';

export enum UserDataActionTypes {
  CHANGE_FIRST_NAME = '[appState] Change first name',
}

export class ChangeFirstNameAction implements Action {
  readonly type = UserDataActionTypes.CHANGE_FIRST_NAME;
  constructor(readonly payload: string) {}
}

