import { Injectable, OnDestroy } from "@angular/core";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { NavigationEnd, Router } from "@angular/router";
import { skip, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Platform } from "@ionic/angular";
@Injectable()
export class AnalyticsTrackerService implements OnDestroy {
  private onDestroy = new Subject();

  constructor(private ga: GoogleAnalyticsService, private router: Router, private platform: Platform) {}

  startAnalytics(): void {
    try {
      if (!window.hasOwnProperty("cordova")) {
        this.trackRouterEvents();
      } else {
        setTimeout(() => {
          this.showNotificationTracking();
        }, 2000);
        
      }
    } catch {}
  }
  
  showNotificationTracking() {
    const idfaPlugin = ((window as any).cordova.plugins as any).idfa;
    if (idfaPlugin) {
      idfaPlugin
        .getInfo()
        .then((info) => {
          if (!info.trackingLimited) {
            return info.idfa || info.aaid;
          } else if (
            info.trackingPermission ===
            idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED &&
            this.platform.is('ios')
          ) {
            return idfaPlugin.requestPermission().then((result) => {
              if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                return idfaPlugin.getInfo().then((info) => {
                  return info.idfa || info.aaid;
                });
              }
            });
          }
        })
        .then((idfaOrAaid) => {
          if (idfaOrAaid) {
            this.trackRouterEvents();
          }
        });
    }
  }

  trackRouterEvents(): void {
    // fix to prevent duplicate page events in Angular Universal - https://github.com/maxandriani/ngx-google-analytics/issues/11
    this.router.events
      .pipe(skip(2), takeUntil(this.onDestroy))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // ga.pageView(event.urlAfterRedirects, undefined);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next(null);
  }
}
