import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  SetUserDeviceAction,
  RemoveUserDeviceAction,
  SetUserDevicesAction,
  GetUserDevicesSuccessAction,
  GetUserDevicesFailureAction,
  GetUserDevicesRequestAction,
  DevicesListActionTypes
} from '../actions/devicesList.actions';
import { MembersPortalService } from '../services/members-portal.service';


@Injectable()
export class DevicesListEffects {
  // @Effect({ dispatch: false }) removeDevice$ = this.actions$.pipe(
  //   ofType<RemoveUserDeviceAction>(DevicesListActionTypes.REMOVE_USER_DEVICE_DATA),
  //   withLatestFrom(this.store),
  //   tap(async ([action, store]) => {
  //     Logger.info('effect');
  //     Logger.info('Starting removing 2fa enabled device transaction');

  //     const virtualDebitCardData = store.virtualDebitCard.newCard;
  //     try {
  //       this.membersPortal.deleteUserDevice(action.payload.password,action.payload.otp,action.payload.device)
  //     } catch (e) {
  //       Logger.error('Something went wrong error', e);
  //       const alert = await this.alertController.create({
  //         header: await this.translate.get('virtual_debit_card.error_title').toPromise(),
  //         message: await this.translate.get('virtual_debit_card.error_description').toPromise(),
  //       });

  //       await alert.present();
  //     }

  //     if (virtualDebitCardData.operationType === 'create') {
  //       await this.nav.navigateForward(['/wallet/card/signup-complete']);
  //     } else {
  //       await this.nav.navigateBack(['/wallet/card']);
  //     }
  //   })
  // );

   getList$ = createEffect(() => this.actions$.pipe(
    ofType<GetUserDevicesRequestAction>(DevicesListActionTypes.GET_USER_DEVICES_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('effect');
      console.log('Effect get devices list')
      Logger.info('Getting list of user 2fa enabled devices transaction');
      let devices;

      try {
        devices = await this.membersPortal.getUserDevices();
      } catch (err) {
        Logger.error('Error while fetching virtual cards', err);
        return this.store.dispatch(new GetUserDevicesFailureAction());
      }

      this.store.dispatch(new GetUserDevicesSuccessAction({ devices }));
    })
  ), { dispatch: false });


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private nav: NavController,
              private alertController: AlertController,
              private translate: TranslateService,
              private membersPortal: MembersPortalService) {
  }

}
