import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class PreviousRouteService {

  public previousUrl: string;
  private currentUrl: string;
  public onUrlChange:Subject<string> = new Subject<string>();

  constructor(private router: Router) {
    this.currentUrl = PreviousRouteService.getUri();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = PreviousRouteService.getUri();
        this.onUrlChange.next(this.previousUrl);
      };
    });
  }
  private static getUri() {
    const url = window.location.href;
    return url.split(window.location.host).pop();
  }
}
