import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../../angular-wallet-base/services/network.service';
import { openLinkExternal } from '../../angular-wallet-base/utils';
import { EXTERNAL_APIS } from '../../angular-wallet-base/constants';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppState } from '../../angular-wallet-base/store/appState';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-staging-mode',
  templateUrl: './staging-mode.component.html',
  styleUrls: ['./staging-mode.component.scss'],
})
export class StagingModeComponent implements OnInit {

  public network;

  constructor(private alert: AlertController,
              private translate: TranslateService,
              private networkService: NetworkService,
              private browser: InAppBrowser,
              private store: Store<AppState>) { }

  ngOnInit() {}

  async showInfo() {
    this.network = await this.networkService.getActiveSysNetwork();
    console.log('NETWORK:', this.network);
    const alert = await this.alert.create({
      header: await this.translate.get('staging.title').toPromise(),
      message: await this.translate.get('staging.description', {
        MEMBER_PORTAL_HOME: environment.EXTERNAL_APIS.MEMBER_PORTAL_HOME,
        AGX_GUID: environment.AGX_GUID,
        AUX_GUID: environment.AUX_GUID,
        SYS_BLOCK_EXPLORER: this.network.URL
      }).toPromise(),
      buttons: [{
        text: await this.translate.get('staging.members_portal').toPromise(),
        handler: async () => {
          openLinkExternal(EXTERNAL_APIS.MEMBER_PORTAL_HOME, this.browser, this.store);
        }
      }, {
        text: await this.translate.get('staging.block_explorer').toPromise(),
        handler: async () => {
          const url = this.network.URL;
          openLinkExternal(url, this.browser, this.store);
        }
      }]});

    await alert.present();
  }

}
