import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({name: 'customcurrency'})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(): string {
    let result;
    const [value, currencyCode, digitsInfo] = Array.from(arguments);
    try{
      switch (currencyCode) {
        case 'BTC':
          result = this.currencyPipe.transform(value, currencyCode, '₿', digitsInfo);
          break;
        case 'ETH':
          result = this.currencyPipe.transform(value, currencyCode, '', digitsInfo);
          break;
        case 'LTC':
          result = this.currencyPipe.transform(value, currencyCode, '', digitsInfo);
          break;
        default:
          result = this.currencyPipe.transform(value, currencyCode, 'symbol-narrow', digitsInfo);
      }
    }catch (e) {
      //pass, ignore NaN values.
    }
    return result;
  }
}
