import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenManagerService } from './token-manager.service';
import { AGX_KEYRING_ID, AUX_KEYRING_ID } from '../constants';

@Injectable()
export class CoinGeckoService {

  private baseUrl: string = 'https://api.coingecko.com/api/v3';
  private ignoreTokens = [
    AGX_KEYRING_ID, AUX_KEYRING_ID, 
    'HL-LODE', 'HL-AGX', 
    'AVAX-0x37F52e650ac21354Aca33A21366986CDf03a3670', 'AVAX-0x648860804E3f2778b28B5760ED1D5d1afdA2C2aC', 'AVAX-0x9cCed5a8994DaeFc4b65429Fa92f84eDB50Ecc5c',
    'AVAX-0x70616a337433a9207fb5c43067c6785aba671c0b', 'AVAX-0x850a9a173d11Ea838B69D1C83C7a2d8fad7BebA8', 'AVAX-0xD8AF683bb02de36c9393932B1A1C68e1f12AEBa1',
    'AVAX-0xe0285fc6a60daa223219fb01d78b3baee133017c', 'AVAX-0xcc871dbbc556ec9c3794152ae30178085b25fa26',
    'SOL-Czh5EHJXZzHMiUeQwY1ZcJUpaf1yBpetdKswMx6NVAhT', 'SOL-3QjcGDn3nJbnt1KZvC3RRNCN3v6gQ14aykk8eCzoLc6X', 'SOL-BKzgKX1xskUMj99rR24z2h775dgF9Eju7H9YmSsFrkDn',
    'SOL-CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v', 'SOL-Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L', 'SOL-H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR'
  ];

  constructor(private http: HttpClient,
              private tokenManager: TokenManagerService) {
  }

  async getSupportedBaseCurrencies() {
    return await this.http.get(`${this.baseUrl}/simple/supported_vs_currencies`).toPromise();
  }

  async getValueInBaseCurrency(tokenSymbols, baseCurrencyId: string[]): Promise<any> {
    const response = {};
    const baseCurrency = baseCurrencyId.join(',').toLocaleLowerCase();
    const supportedAssetList = await this.tokenManager.getTokenList();

    await tokenSymbols.forEachAsync(async (keyringId) => {
      const currentToken = supportedAssetList[keyringId];

      if (this.ignoreTokens.find(token => token === keyringId)) {
        return;
      }
      if (!currentToken || !currentToken.coingeckoId) {
        return;
      }

      let params = new HttpParams();
      const coinName = currentToken.coingeckoId ;

      params = params.append('ids', coinName);
      params = params.append('vs_currencies', baseCurrency);
      let geckoResponse = {
        ...await this.http.get(`${this.baseUrl}/simple/price`,
          { params }).toPromise()
      };

      let fiatValue = geckoResponse[coinName][baseCurrency];

      response[keyringId] = fiatValue;
    });

    return {
      baseCurrency,
      fiatValues: response
    };
  }
}
