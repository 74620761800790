import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { io } from 'socket.io-client';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { getTokenKeyring } from '../store/wallet';
import { getJwtToken } from '../store/userPreferences';
import { EXTERNAL_APIS } from '../constants';
import { MembersPortalService } from './members-portal.service';
import { environment } from '../../../environments/environment';
import { getToastPosition } from '../utils';
import { timeout } from 'rxjs/operators';

import { take } from 'rxjs/operators'
export interface InitExchange {
  assetGuid: number;
  senderAddress: string;
  amount: number;
}

export interface ConvRateParams {
  amount: number;
  guid: number | string;
}
export interface ConvRateResponse {
  sys: string;
}

@Injectable({
  providedIn: 'root'
})
export class GasStationService {
  private GAS_STATION_API: string = EXTERNAL_APIS.GAS_STATION;
  private GAS_STATION_API_CREATE: string = this.GAS_STATION_API + '/order/create';
  private GAS_STATION_API_FULFILL: string = this.GAS_STATION_API + '/order/fulfill';
  private GAS_STATION_API_CONV_RATE: string = this.GAS_STATION_API + '/conversion_rate';
  private GAS_STATION_API_TX_CONV: string = this.GAS_STATION_API + '/tx_number_conversion';
  private GAS_STATION_HEALTH: string = this.GAS_STATION_API + '/health';
  private GAS_STATION_DUST: string = this.GAS_STATION_API + '/dust';
  private GAS_STATION_GET_ADDRESS: string = this.GAS_STATION_API + '/address';
  private socket;
  protected isConnected: boolean = false;

  constructor(private http: HttpClient,
              private toast: ToastController,
              private translate: TranslateService,
              private store: Store<AppState>,
              private membersPortal: MembersPortalService,
              private platform: Platform) {
    if (environment.features.gasStation) {
      this.socket = io(this.GAS_STATION_API);
      this.socket.on('connect', () => {
        this.isConnected = true;
      });
      this.socket.on('disconnect', () => {
        this.socket = null;
        this.isConnected = false;
      });
    }
  }

  public async initOrder(params: InitExchange) {
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    return this.http.post(this.GAS_STATION_API_CREATE, params, { headers }).toPromise();
  }

  public async fulfillOrder(txid: string) {
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    const res = await this.http.post(this.GAS_STATION_API_FULFILL, { txid }, { headers }).toPromise();
    const toast = await this.toast.create({
      header: await this.translate.get('gas_station.notifications.order_placed_title').toPromise(),
      message: await this.translate.get('gas_station.notifications.order_placed_message').toPromise(),
      position: getToastPosition(this.platform),
      duration: 4000
    });

    await toast.present();
    
    if (!this.socket) {
      return res;
    }

    this.socket.on((res as any).orderId, async (data) => {
      const successToast = await this.toast.create({
        header: await this.translate.get('gas_station.notifications.order_processed_title').toPromise(),
        message: await this.translate.get('gas_station.notifications.order_processed_message').toPromise(),
        position: getToastPosition(this.platform),
        duration: 4000
      });

      await successToast.present();
    });

    return res;
  }

  public async getConversionRate(obj: ConvRateParams) {
    let params = new HttpParams();
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    params = params.append('amount', obj.amount.toString());
    params = params.append('guid', obj.guid.toString());

    try {
      const rate = await this.http.get(this.GAS_STATION_API_CONV_RATE, { params, headers }).toPromise();
      return Number((rate as ConvRateResponse).sys);
    }catch (err) {
      return 0;
    }

  }

  public async getTxAmount(amount: number) {
    let params = new HttpParams();
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    params = params.append('amount', amount.toString());

    try {
      const rate = await this.http.get(this.GAS_STATION_API_TX_CONV, { params, headers }).toPromise();
      return Number((rate as ConvRateResponse).sys);
    } catch (err) {
      return 0;
    }
    
  }

  public isAvailable() {
    return this.isConnected;
  }

  public async getStatus() {
    let health;

    try {
      health = await this.http.get(this.GAS_STATION_HEALTH).pipe(timeout(5000)).toPromise();
    } catch (err) {
      return false;
    }

    return health;
  }

  public async isGasStationAvailable() {
    let health;

    try {
      health = await this.getStatus();
    } catch (err) {
      return false;
    }

    return health.wallet.balance && health.connection.rpc && health.connection.db && health.connection.socket;
  }

  public async claimDust() {
    const sysInfo: any = await this.store.select(getTokenKeyring, { keyringId: 'SYS' }).pipe(take(1)).toPromise();
    const address = sysInfo.address;
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    if (!jwt) {
      return new Error('JWT required for claiming dust');
    }

    return this.http.post(this.GAS_STATION_DUST, { address }, { headers }).toPromise();
  }

  public async getAddress() {
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });
    return this.http.get(this.GAS_STATION_GET_ADDRESS, { headers }).toPromise();
  }

}
