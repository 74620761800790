import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { getAuthenticated, getPreventAuthInfo, getQueryCount } from '../store/wallet';
import { AUTH_REASON } from '../constants';
import { Logger } from './logger.service';
import { PreventAuthInfo } from '../../global';
import { AuthenticateAction, SetWalletRedirectUriAction } from '../actions/wallet.actions';

@Injectable()
export class WalletGuardService implements CanActivate {

  private authenticated: boolean = false;
  private queryCount: number;
  private preventInfo: PreventAuthInfo;

  constructor(private nav: NavController, private store: Store<AppState>) {
    this.store.select(getAuthenticated).subscribe(authenticated => this.authenticated = authenticated);
    this.store.select(getQueryCount).subscribe(count => this.queryCount = count);
    this.store.select(getPreventAuthInfo).subscribe((preventInfo: PreventAuthInfo) => {
      this.preventInfo = preventInfo;
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    Logger.info('GUARD ROUTE', route, state, this.preventInfo, this.queryCount);
    const url = state.url;
    //if window href has query param oauth_state_id, then we are coming from oauth
    const oauthStateId = route.queryParams['oauth_state_id'];
    if(oauthStateId) {
      Logger.info('GUARD ROUTE OAUTH url', url);
      this.store.dispatch(new SetWalletRedirectUriAction(url));
    }
    if (!this.authenticated) {
      if (this.queryCount === 0) { // first time login
        Logger.info('guard first login');
        await this.nav.navigateForward(['/auth'], {skipLocationChange: true});
      } else if (!this.preventInfo.preventAuthOnResume || this.preventAuthOnResumeTimedOut()) { // relogin
        Logger.info('guard reauth', route.url.join(''));
        await this.nav.navigateForward(['/auth', AUTH_REASON.RE_AUTHENTICATE], {skipLocationChange: true, queryParams: { url }});
      }
      return false;
    }

    return true;
  }

  private preventAuthOnResumeTimedOut() {
    Logger.info('preventAuthOnResumeTimedOut check', this.preventInfo);
    if (this.preventInfo.unauthenticatedTime && this.preventInfo.preventAuthDuration > 0) {
      const preventAuthDurationMs = this.preventInfo.preventAuthDuration * 1000;
      Logger.info('unauth time:', this.preventInfo.unauthenticatedTime);
      Logger.info('unauth prevent auth duration:', this.preventInfo.preventAuthDuration);
      const unauthDuration = (Date.now() - this.preventInfo.unauthenticatedTime);
      Logger.info('unauth duration', unauthDuration);
      if (unauthDuration >= preventAuthDurationMs) {
        return true;
      } else {
        this.store.dispatch(new AuthenticateAction());
        return false;
      }
    }

    return false;
  }

}
